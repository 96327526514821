import React from 'react';
import { Box, Link, Typography, Stack, Button } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


const RequestDetails = ({ request }) => {
  const navigate = useNavigate();


  const isMyProfile = false


  // Calculate "x days ago" format for creation date
  const creationDate = moment(request.creationTime).fromNow();

  console.log('request in requestdetail after page creation', request)

  const handleWriteReview = () => {
    const feedbackProps = {
      requestId: request._id,
     
    };

    // Use navigate to go to the feedback form page and pass props
    navigate('/url/request/review', { state: feedbackProps });
    
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{m:2, overflow: 'hidden', }}>
      <Link href={request.url} target="_blank" rel="noopener noreferrer" sx={{ fontSize: 16, marginBottom: 1 }}>
        {request.urlRaw}
      </Link>
     
      <Box display="flex" alignItems="center" sx={{ fontSize: 14, color: 'text.secondary', my: 2 }}>
        {request.platform && (
          <Typography sx={{ mr: 2 }} variant="body2">
            {request.platform}
          </Typography>
        )}
        <Typography variant="body2">{creationDate}</Typography>
      </Box>
  
      <Box display="flex" alignItems="center" sx={{ fontSize: 18 }}>
        {request.company && (
          <Typography sx={{ mr: 2 }} variant="subtitle2">
            {request.company.Text}
          </Typography>
        )}
        {request.name && (
          <Typography variant="subtitle2" style={{ display: 'inline' }} width={'auto'}>
            {request.name.Text}
          </Typography>
        )}
      </Box>
      {!isMyProfile &&
              <Stack direction="row" spacing={2} justifyContent="flex-start" mt={2}>
                <Button variant="outlined" onClick={handleWriteReview}>
                  Write a Review
                </Button>
                <Button variant="outlined" >
                  Endorse
                </Button>
              </Stack>
            
            }
      
    </Box>
  );
};

export default RequestDetails;
