
import {thingsApi} from './things'

export const thingEndorseApi = thingsApi.injectEndpoints({
  endpoints: (builder) => ({
    
    thingEndorse: builder.mutation({
      
      query: ({payload}) => ({
        url: '/endorseskill',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useThingEndorseMutation } = thingEndorseApi