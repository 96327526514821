import React from 'react';
import { Paper, List, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import ReviewsIcon from '@mui/icons-material/Reviews';
import CompareIcon from '@mui/icons-material/Compare';
import PeopleIcon from '@mui/icons-material/People';


const BusinessLinks = ({ setActiveContent }) => {
  return (
    <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb:1, borderRadius: 2  }}>
      <List component="nav">
        <ListItemButton onClick={() => setActiveContent('kpis')}>
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText primary="Business KPIs" />
        </ListItemButton>
        <ListItemButton onClick={() => setActiveContent('sentiment')}>
          <ListItemIcon>
            <SentimentVerySatisfiedIcon />
          </ListItemIcon>
          <ListItemText primary="Sentiment Analysis" />
        </ListItemButton>
        <ListItemButton onClick={() => setActiveContent('reviews')}>
          <ListItemIcon>
            <ReviewsIcon />
          </ListItemIcon>
          <ListItemText primary="Review Analysis" />
        </ListItemButton>
        <ListItemButton onClick={() => setActiveContent('comparison')}>
          <ListItemIcon>
            <CompareIcon />
          </ListItemIcon>
          <ListItemText primary="Comparison & Benchmark" />
        </ListItemButton>
        <ListItemButton onClick={() => setActiveContent('engagement')}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Actionable Personal Insights" />
        </ListItemButton>
      </List>
    </Paper>
  );
};

export default BusinessLinks;

