import { createContext, useContext, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { handleWebSocketMessage } from './WebSocketHandlers'; 
import { useDispatch } from 'react-redux';
export const WebSocketContext = createContext();

// Constants
const RECONNECT_DELAY = 5000; // 5 seconds
const MAX_RETRIES = 5;
const HEARTBEAT_INTERVAL = 400000;

export const WebSocketProvider = ({ children, initialHandlers = {} }) => {
  const [socket, setSocket] = useState(null);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userId = useSelector((state) => state.auth.userId);

  const [handlers, setHandlers] = useState(initialHandlers);
  const [retries, setRetries] = useState(0);
  const disconnectTimeoutRef = useRef(null);
  const heartbeatIntervalRef = useRef(null);
  const isLoggedInRef = useRef(isLoggedIn);
  const userIdRef = useRef(userId);
  const dispatch = useDispatch();

  useEffect(() => {
    isLoggedInRef.current = isLoggedIn;
    userIdRef.current = userId;
    if (isLoggedIn && userId) {
      //console.log('inside useeffect - connect isLoggedin: ', isLoggedIn)
      //console.log('inside useeffect - connect userId: ', userId)
      connect();
    } else if (socket) {
      //console.log('inside useeffect disconnect isLoggedin: ', isLoggedIn)
      //console.log('inside useeffect disconnect userId: ', userId)

      disconnect();
    }
  }, [isLoggedIn, userId]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      
      handleWebSocketMessage(data.body, dispatch);
    };

    return () => {
      socket.onmessage = null;
    };
  }, [socket]);

  const startHeartbeat = (ws) => {
    if (heartbeatIntervalRef.current) {
      console.log('Heartbeat already started: heartbeatIntervalRef.current', heartbeatIntervalRef.current)
      return; // Don't start if it's already running
    }
    console.log('inside startHearbeat ws.readyState: ', ws.readyState)

    heartbeatIntervalRef.current = setInterval(() => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        console.log('hearbeat sent')
        ws.send(JSON.stringify({
            type: "HEART_BEAT",
            user_id: userId,
        }));
      }
    }, HEARTBEAT_INTERVAL);
  };

  const stopHeartbeat = () => {
    console.log('hearbeat stop')
    if (heartbeatIntervalRef.current) {
      clearInterval(heartbeatIntervalRef.current);
      heartbeatIntervalRef.current = null;
    }
  };

  const connect = () => {
    console.log('inside connecting socket...')
    if (retries >= MAX_RETRIES) {
      console.error("Max retries reached. Not reconnecting.");
      return;
    }

    const ws = new WebSocket("wss://f7s4a91mc4.execute-api.us-east-1.amazonaws.com/Prod");

    ws.onopen = () => {
      console.log('WebSocket opened,');
      setRetries(0);
      ws.send(JSON.stringify({ type: "REGISTER", user_id: userId }));
      startHeartbeat(ws);
    };

    ws.onclose = (event) => {
      console.log('WebSocket closed. Reason:', event.reason);
      stopHeartbeat();

      if (isLoggedInRef.current && userIdRef.current ) {
        console.log('inside onclose isLoggedInRef.current: ', isLoggedInRef.current)
        console.log('inside onclose userIdRef.current: ', userIdRef.current)
        setTimeout(() => {
          setRetries(prev => prev + 1);
          connect();
        }, RECONNECT_DELAY);
    }
    };

    setSocket(ws);
  };

  const disconnect = () => {
    stopHeartbeat();
    if (socket) {
      socket.close();
      setSocket(null);
    }
  };

  const registerHandler = useCallback((type, handler) => {
    setHandlers((prev) => ({
      ...prev,
      [type]: handler,
    }));
  }, []);

  const unregisterHandler = useCallback((type) => {
    setHandlers((prev) => {
      const newHandlers = { ...prev };
      delete newHandlers[type];
      return newHandlers;
    });
  }, []);

  const contextValue = useMemo(() => {
    return { socket, connect, disconnect, registerHandler, unregisterHandler };
  }, [socket, connect, disconnect, registerHandler, unregisterHandler]);

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
