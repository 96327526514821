import { useDropzone } from 'react-dropzone';




const FileUpload = ({ onFileChange }) => {
    const onDrop = (acceptedFiles) => {
      onFileChange(acceptedFiles[0]); // Send the first accepted file to the parent component
    };
  
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: {
        'image/png': ['.png'], 
        'image/jpeg': ['.jpg', '.jpeg'] 
      },
      maxFiles: 1, // Allow only one file to be uploaded
    });
  
    return (
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>Drag & drop an image file here, or click to select one</p>
      </div>
    );
  };
  
  const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    cursor: 'pointer',
  };
  
  export default FileUpload;
  