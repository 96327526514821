import React, { useState, useEffect } from 'react';
import UserPhoto from '../../Components/UserPhoto/UserPhoto';
import { Box, Button, Grid, Typography, Avatar, AvatarGroup, Modal, List, ListItem, ListItemAvatar, ListItemText, Divider } from '@mui/material';
import { Link  } from 'react-router-dom'; 

import { usePrefetch as useGetUserProfilePrefetch } from '../../redux/thunks/users/userprofile';

const FriendModal = ({ friends, open, onClose }) => {
  const handleCloseAndNavigate = () => {
    onClose(); // Close the modal
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', borderRadius: '8px', boxShadow: 24, p: 4 }}>
        <Typography variant="h6" gutterBottom>
          All Friends
        </Typography>
        <List sx={{ width: '100%', maxWidth: 360 }}>
          {friends.map((friend, index) => (
            <React.Fragment key={friend.userId}>
              <ListItem>
                <ListItemAvatar>
                  <Link to={`/profile/${friend.userId}`} onClick={handleCloseAndNavigate} >
                    <Avatar alt={friend.firstName} src={friend.profileImageUrl} />
                  </Link>
                </ListItemAvatar>
                <ListItemText primary={friend.displayName} />
              </ListItem>
              {index !== friends.length - 1 && <Divider />} {/* Add a divider if it's not the last item */}
            </React.Fragment>
          ))}
        </List>
        <Button onClick={onClose} variant="contained" color="primary">Close</Button>
      </Box>
    </Modal>
  );
};


const FirstDegreeFriends = ({ friends }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const friendsPerPage = 8;

  console.log('inside first degree friends: friends', friends)

   //remove duplicate ids: check server why dupicates todo: vikram
  const newFriendList = friends.filter((obj, index, self) => index === self.findIndex((o) => o.userId === obj.userId));
  console.log('inside first degree friends: newFriendList', newFriendList)

  const indexOfLastFriend = currentPage * friendsPerPage;
  const indexOfFirstFriend = indexOfLastFriend - friendsPerPage;
  const currentFriends = newFriendList.slice(indexOfFirstFriend, indexOfLastFriend);

  const totalPages = Math.ceil(friends.length / friendsPerPage);

 

  const prefetchGetUserProfile = useGetUserProfilePrefetch('getUserProfile')   


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (currentFriends.length > 0) {
      // Loop through currentFriends and prefetch their profiles
      currentFriends.forEach((friend) => {
        // Call the prefetch function for each user profile
        prefetchGetUserProfile(friend.userId);
      });
    }
  }, [currentFriends]);

  return (
    <Box>
      <Typography variant="subtitle2" sx={{ fontWeight: '800', pb: 2 }}>
        Friends
      </Typography>
      <Box border={0} p={1} borderRadius="borderRadius">
        <Grid container spacing={2}>

        <AvatarGroup max={4} >

          {newFriendList.map((friend) => {
            return (

              <Grid item key={friend.userId} xs={12} sm={6} md={6} lg={6}>
                <Link to={`/profile/${friend.userId}`} >
                  <Avatar alt="Remy Sharp" src={friend.profileImageUrl} />
                </Link>
              </Grid>             

            )              
              
            })}

        </AvatarGroup>
        
          
        </Grid>
        
        
        
      </Box>
      <Box sx={{  display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="text"
          color="primary"
          onClick={() => setModalOpen(true)}
          sx={{  pb:0}}
        >
          View All Friends
        </Button>

      </Box>
     
      
      <FriendModal friends={newFriendList} open={modalOpen} onClose={() => setModalOpen(false)} />
    </Box>
  );
};

export default FirstDegreeFriends;
