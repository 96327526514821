
import {spApi} from './sp'

export const editBusinessApi = spApi.injectEndpoints({
  endpoints: (builder) => ({
    
    editBusiness: builder.mutation({
      
      query: ({payload}) => ({
        url: '/editbusiness',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useEditBusinessMutation } = editBusinessApi