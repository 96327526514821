import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';

const SignIn = () => {
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  console.log('authStatus in signin.js', authStatus)

  useEffect(() => {
    if (authStatus === 'authenticated') {
      navigate('/home'); // Redirect to /home after successful login
    }
  }, [authStatus, navigate]);

  return (
    <Authenticator loginMechanisms={['email']} socialProviders={['facebook', 'google']} signUpAttributes={[

      'family_name',

      'given_name',
     
    ]}/>
  );
};

export default SignIn;
