
import {aiApi} from './ai'

export const generateAnswersApi = aiApi.injectEndpoints({
  endpoints: (builder) => ({
    
    generateAnswers: builder.mutation({
      
      query: ({payload}) => ({
        url: '/generateanswersfromreview',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useGenerateAnswersMutation } = generateAnswersApi