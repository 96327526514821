import React from 'react';
import { useSelector } from 'react-redux';
import { Paper, Typography, Avatar, Grid, Divider, Box } from '@mui/material'; // Import Material-UI components
import { generateColorFromInitials } from '../../Functions/Utilities'; // Import your utility function
import UserPhoto from '../../Components/UserPhoto/UserPhoto';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import Lists from './Lists/Lists'

import { useGetStatsGlobalQuery  } from '../../redux/thunks/stats/statsglobal';


const iconName = 'Notes'; // The name of the MUI icon
const heading ='Top Categories'


const TopCategories = ({categories}) => {
  const navigate = useNavigate();

  console.log('Top categories: ', categories)    

  const names = categories.map(category => ({
    businessId: category._id,
    businessName: category.name,    
  }));

  return (
    <Paper variant="outlined" sx={{ padding: 1, border: 'none' }}>
      <Lists heading={heading} names={names} iconName={iconName} rowSpacing={-2} iconSpacing={-3} isBusiness={false} />
       

    </Paper>
  );
};

export default TopCategories;
