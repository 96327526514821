
import {urlsApi} from './urls'

export const urlCreateApi = urlsApi.injectEndpoints({
  endpoints: (builder) => ({
    
    urlCreate: builder.mutation({
      
      query: ({payload}) => ({
        url: '/request/create',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useUrlCreateMutation } = urlCreateApi