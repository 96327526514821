import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@mui/material';

import { useGetPreSignedUrlUsrAvatarJpgQuery } from '../../redux/thunks/files/filespresignurlusravatar'; // Import your RTK query

import Dropzone from 'react-dropzone';
import imageCompression from 'browser-image-compression';


const AvatarUploadDialog = ({ open, onClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { data: presignedUrlData, isLoading } = useGetPreSignedUrlUsrAvatarJpgQuery();

  const handleFileDrop = async (acceptedFiles) => {
    // Assuming you want to upload only one file, you can access it from acceptedFiles[0]
    const file = acceptedFiles[0];
    
    // Configure image compression options
    const options = {
      maxSizeMB: 1, // Maximum file size in megabytes
      maxWidthOrHeight: 500, // Maximum width or height of the image
    };
    try {
      // Compress and resize the selected image
      const compressedFile = await imageCompression(file, options);
      setSelectedFile(compressedFile);
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile || !presignedUrlData) {
      return;
    }

    if(presignedUrlData) {
      const presignedUrl = presignedUrlData.url; // Extract the presigned URL
      const contentType = selectedFile.type; // e.g., 'image/jpeg', 'image/png', etc.

       // Set the Content-Type header in the fetch request
      const headers = {
        'Content-Type': contentType,
      };

      try {
        await fetch(presignedUrl, {
          method: 'PUT',
          body: selectedFile,
          headers
        });

        // Close the dialog upon successful upload
       onClose();


      } catch(error) {
        console.error('Error uploading image:', error);
    }
  }   
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Upload Avatar</DialogTitle>
      <DialogContent>
        <Dropzone onDrop={handleFileDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} style={{ cursor: 'pointer', minHeight: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '2px dashed #ccc', borderRadius: '4px' }}>
              <input {...getInputProps()} />
              {selectedFile ? (
                <>
                <img
                  src={URL.createObjectURL(selectedFile)} // Display the selected image
                  alt="Selected Image"
                  style={{ maxWidth: '100%', maxHeight: '200px', marginBottom: '10px' }}
                />
                <p>Selected File: {selectedFile.name}</p>
              </>
              ) : (
                <p>Drag & drop a file here, or click to select a file</p>
              )}
            </div>
          )}
        </Dropzone>
        {isLoading && <LinearProgress />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleUpload} color="primary" variant="contained">
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AvatarUploadDialog;
