
import {spApi} from './sp'


export const getBusinessStatsApi = spApi.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessStats: builder.query({
      query: ({businessId, authType, token}) => `/businessstats/${businessId}?authType=${authType}&&token=${token}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetBusinessStatsQuery, usePrefetch } = getBusinessStatsApi