import React from 'react';
import { Box, Container, Grid, Typography, Stack, Chip } from '@mui/material';
import FeaturesCard from '../cards/featuresCard';
// icons
import { BsFillRouterFill } from 'react-icons/bs';
import WeekendTwoToneIcon from '@mui/icons-material/WeekendTwoTone';
import { LuArmchair } from 'react-icons/lu';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

export default function Features() {
  return (
    <Box
      sx={{
        // bgcolor: '#FDF3F0',
        position: 'relative',
        py: { xs: 15, md: 10 },
        ':before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'url(/assets/start/feature.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          zIndex: 0,
        },
      }}>
      <Container>
        <Box
          sx={{
            position: 'relative',
            zIndex: 99,
          }}>
          <Stack
            mb={5}
            spacing={1.5}
            alignItems='center'
            justifyContent='center'>
            <Box>
              <Chip
                label='Features'
                sx={{
                  color: 'primary.main',
                  bgcolor: 'rgba(0, 85, 255, 0.12)',
                  fontSize: 13,
                  fontWeight: 600,
                  px: 1,
                }}
              />
            </Box>
            <Typography
              variant='h3'
              fontWeight={600}
              fontSize={28}>
              Why Choose us
            </Typography>
            <Typography
              variant='body2'
              fontSize={15}
              fontWeight={400}
              color='text.secondary'>
              Experience the Power of Connected Reviews
            </Typography>
          </Stack>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            spacing={2}>
            {data?.map((item, index) => (
              <Grid
                key={Math.random()}
                item
                xs={12}
                md={6}>
                <FeaturesCard item={item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

const data = [
  {
    id: 1,
    name: 'Authentic Reviews',
    detail:
      "All reviews come from your network, ensuring credibility and trust.",
    icon: <BsFillRouterFill size={24} />,
  },
  {
    id: 2,
    name: 'Digital Word of Mouth',
    detail:
      'Find businesses recommended by your friends and their friends, creating a digital word-of-mouth experience.',
    icon: <PeopleAltIcon size={24} />,
  },
  {
    id: 3,
    name: 'User-Friendly Interface',
    detail:
      'Our platform is designed for ease of use, making it simple to find and share reviews.',
    icon: <LuArmchair size={24} />,
  },
  {
    id: 4,
    name: 'Built with AI in Mind',
    detail:
      ' Revfer uses AI and machine learning to provide personalized recommendations and enhance the overall user experience.',
    icon: <WeekendTwoToneIcon size={24} />,
  },
];
