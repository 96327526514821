import React from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import {
  Share as ShareIcon,
  Business as BusinessIcon,
  PersonAdd as PersonAddIcon,
  Report as ReportIcon,
  PostAdd as PostAddIcon,
} from '@mui/icons-material';

const MoreMenu = ({ isThisMe, anchorEl, open, onClose, onShareClick, onSeeBusinessClick, onFollowClick, onReportClick }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {isThisMe &&
      <MenuItem onClick={onSeeBusinessClick}>
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <ListItemText primary="Add business page" />
      </MenuItem>    
      
      }

      {isThisMe &&
        <MenuItem onClick={onReportClick}>
        <ListItemIcon>
          <PostAddIcon />
        </ListItemIcon>
        <ListItemText primary="Post" />
        </MenuItem>
      
      }

      {!isThisMe &&
        <MenuItem onClick={onShareClick}>
        <ListItemIcon>
          <ShareIcon />
        </ListItemIcon>
        <ListItemText primary="Share this profile" />
        </MenuItem>
      
      }

      
      
     
      <Divider />
      {!isThisMe &&
        <MenuItem onClick={onReportClick}>
        <ListItemIcon>
          <ReportIcon />
        </ListItemIcon>
        <ListItemText primary="Report this profile" />
        </MenuItem>
      
      }
       <MenuItem onClick={onFollowClick}>
        <ListItemIcon>
          <PersonAddIcon />
        </ListItemIcon>
        <ListItemText primary="Back to my profile" />
      </MenuItem>
     
      
    </Menu>
  );
};

export default MoreMenu;
