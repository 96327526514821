import React from 'react';
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  Button,
  TextField,
  Link,
} from '@mui/material';
import { IoIosArrowRoundForward } from 'react-icons/io';


export default function Hero() {
  return (
    <Box
      sx={{
        position: 'relative',
        ':before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'url(/assets/business/hero.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: { xs: 'cover', md: 'contain' },
          backgroundPosition: 'top left',
          zIndex: 0,
        },
      }}>
      <Container>
        <Box
          sx={{
            position: 'relative',
            zIndex: 99,
          }}>
          <Grid
            container
            spacing={5}
            py={{ xs: 8, md: 15 }}
            alignItems='center'>
            <Grid
              item
              xs={12}
              lg={6}>
              <Typography
                variant='h2'
                fontSize={{ xs: 24, md: 48 }}
                textAlign={{ xs: 'center', md: 'left' }}
                fontWeight={600}
                sx={{
                  span: {
                    background:
                      'linear-gradient(to top, #A4EAC0 50%, transparent 50%)',
                  },
                }}>
                AI and Trusted Networks: The  <span>Future </span>  of Reviews 
                <br />
                
              </Typography>
              <Typography
                variant='body1'
                my={{ xs: 3, md: 5 }}
                fontSize={16}
                fontWeight={500}
                textAlign={{ xs: 'center', md: 'left' }}
                color='text.secondary'>
                At Revfer, we understand the power of word-of-mouth recommendations. Our platform connects you with customers through trusted reviews from their personal networks, helping you build a loyal customer base and enhance your business reputation.
              </Typography>
              <Box textAlign={{ xs: 'center', md: 'left' }}>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent={{ xs: 'center', md: 'left' }}
                  spacing={1}>
                 
                  <Button
                    variant='contained'
                    color='primary'
                    sx={{
                      mt: 5,
                      textTransform: 'math-auto',
                      mx: 'auto',
                      boxShadow: 'none',
                      display: 'flex',
                    }}
                    endIcon={<IoIosArrowRoundForward />}>
                    Signup
                  </Button>
                </Stack>
                <Typography
                  variant='body2'
                  fontSize={16}
                  mt={2}
                  fontWeight={500}
                  color='text.secondary'>
                  Already using Revfer?{' '}
                  <Link
                    underline='none'
                    sx={{ cursor: 'pointer' }}
                    color='common.black'>
                    Log In
                  </Link>
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}>
              <Box
                sx={{
                  position: 'relative',
                  ':before': {
                    content: '""',
                    position: 'absolute',
                    bottom: -50,
                    left: { xl: '25%' },
                    width: 128,
                    height: 128,
                    background: 'url(/assets/business/Dot.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'bottom left',
                    zIndex: -1,
                  },
                }}>
                <Box>
                  <Box
                    component='img'
                    src='/assets/images/SeekReview.jpg'
                    alt='hero'
                    sx={{
                      transform: { xl: 'translateX(30%)' },
                      height: '100%',
                      objectFit: 'contain',
                      width: '100%',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
