import React, {useState, useEffect} from 'react';
import UserPhoto from '../../Components/UserPhoto/UserPhoto';
import { Box, Typography, Button, Avatar, Divider, IconButton, Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import GroupsIcon from '@mui/icons-material/Groups';
import ChatIcon from '@mui/icons-material/Chat';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TrustChainDisplay from '../../Components/TrustChain/TrustChainDisplay';
import MoreMenu from '../../Components/Menus/MoreMenuSp';
import LinkIcon from '@mui/icons-material/Link';

import { useNavigate } from 'react-router-dom';

//todo: replace trust rating with something clickable and prompt for signin
const SpFacePlate = ({ business }) => {

  console.log('business in spfaceplate', business)

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);


  
  const isThisMe = false;
  const isSpSystem = business.spEmail==='admin@revfer.com'
  console.log('isThisMe', isThisMe)

  const handleNavigation2Chat = (id) => {
    // Define navigation logic for endorsed activity (e.g., go to profile page)
    navigate(`/chat`, { state: { otherUser:{otherUserId: sp.userId, displayName: sp.displayName, photoUrl: sp.profileImageUrl, lastMessage: '', msgTime: new Date()} } });
  };

  const handleNavigation2Profile = (id) => {
    const msg = {
      text: 'Please sign up or sign in to see the user profile'
    };
    navigate('/auth/signin', { state: msg });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleShareClick = () => {
    const msg = {
      text: 'Please sign up or sign in to share the user profile'
    };
    navigate('/auth/signin', { state: msg });
  };

  const handleSeeBusinessClick = (id) => {
    // Handle see business pages action
    navigate(`/provider/profile/${user.spId}`, { state: { businessId: 'default', businessName: 'default' } });
    handleClose();
  };

  const handleTrustChain = () => {
    const msg = {
      text: 'Please sign up or sign in to see the trust chain'
    };
    navigate('/auth/signin', { state: msg });
  };

  const handleFollowClick = () => {
    const msg = {
      text: 'Please sign up or sign in to see the user profile'
    };
    navigate('/auth/signin', { state: msg });
  };

  const handleReportClick = () => {
    const msg = {
      text: 'Please sign up or sign in to report the user profile'
    };
    navigate('/auth/signin', { state: msg });
  };



 
  return (
    <Box border={0} borderRadius="borderRadius" p={2} textAlign="center">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={1} >
        <UserPhoto photoUrl={business.spPhoto}  name={business.spDisplayName} size={50} />
      </Box>
      <Typography variant="h6" fontWeight="bold" sx={{cursor: 'pointer'}} onClick={() => handleNavigation2Profile(business.userId)}>
        {business.spDisplayName}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <Typography variant="body2" color="textSecondary" mr={1}>
          Trust Rating: 120
        </Typography>
        <IconButton onClick={handleTrustChain} color="primary">
        {/* You can use any Material-UI icon here */}
        <LinkIcon/>
      </IconButton> 
        
       
        
      </Box>
      <Box mt={4}>
        <Grid container justifyContent="center" spacing={2}>
          {!isThisMe &&
            <Grid item>
              <Avatar variant="contained" style={{ backgroundColor: '#0f75f2', color: 'white' }}>
                <ChatIcon sx={{ fontSize: 16 }} />
              </Avatar>
             </Grid>
          
          }
          
          
          <Grid item>
            <Avatar variant="contained" sx={{ backgroundColor: '#607D8B', color: 'white', cursor: 'pointer'   }} onClick={handleClick}>
              <MoreHorizIcon sx={{ fontSize: 16 }} />

            </Avatar>
            <MoreMenu
                isThisMe ={isThisMe}            
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                onShareClick={handleShareClick}
                onSeeBusinessClick={handleSeeBusinessClick}
                onFollowClick={handleFollowClick}
                onReportClick={handleReportClick}
              />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SpFacePlate;
