import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
//import {store} from '../store';
//import { setSignOut } from '../reducers/auth'
import { fetchAuthSession } from "aws-amplify/auth";


//const url = 'http://localhost:8080/https://js4ifvuoy3.execute-api.us-east-1.amazonaws.com/dev01'
//const url = 'https://js4ifvuoy3.execute-api.us-east-1.amazonaws.com/dev01'
//const url = process.env.REACT_APP_PROXY_PREFIX + 'https://js4ifvuoy3.execute-api.us-east-1.amazonaws.com/dev01'


const url = process.env.REACT_APP_PROXY_PREFIX + 'https://m825orpyo4.execute-api.us-east-1.amazonaws.com/dev01'


const baseQueryWithInterceptor = async  (args, api, extraOptions) => {  
  // A regular fetchBaseQuery using the config provided by `args`, and including the baseUrl and prepareHeaders logic
  const result = await fetchBaseQuery({
    ...args,
    baseUrl: url,
    prepareHeaders: async (headers, { getState }) => {
      // Add auth token if it exists
      const session = await fetchAuthSession(); 
      const token = session.tokens.idToken.toString();
      console.log('token', token)
      if (token) {
        headers.set('Authorization', `${token}`)
        headers.set("Content-Type", "application/json")
      }
      return headers;
    }
  })(args, api, extraOptions);

  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    // if the response status is 401, dispatch logout action and navigate to login screen
    //console.log('error in files api, result', result)
    //store.dispatch(setSignOut())
  }

  return result;
};

export const filesApi = createApi({
  reducerPath: 'filesApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: [],
  endpoints: () => ({}),
})
