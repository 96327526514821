
import {spApi} from './sp'

export const spCommentPostApi = spApi.injectEndpoints({
  endpoints: (builder) => ({
    
    spCommentPost: builder.mutation({
      invalidatesTags: ['spreviews'],
      query: ({payload}) => ({
        url: '/postspresponse',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useSpCommentPostMutation } = spCommentPostApi