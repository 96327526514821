
import {statsApi} from './stats'


export const getStatsGlobalApi = statsApi.injectEndpoints({
  endpoints: (builder) => ({
    
    getStatsGlobal: builder.query({
      query: () => `/global`
    }),
  }),
  overrideExisting: false,
})

export const { useGetStatsGlobalQuery, usePrefetch } = getStatsGlobalApi


