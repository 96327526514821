import React from 'react';
import {
  Card,
  CardContent,
  Avatar,
  Typography,
  Rating,
  LinearProgress,
  Box,
  Button
} from '@mui/material';
import UserPhoto from '../../Components/UserPhoto/UserPhoto';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { useAddTrustedUserMutation  } from '../../redux/thunks/users/useraddtrusteduser';

const CardItem = ({ item }) => {
  console.log('item in cartitem.js', item)

  const [addTrustedUser, { isLoading }] = useAddTrustedUserMutation();

  const handleConnectPress = async () => {
    try {
      const payload = { 'trustedUserId': item.id  }
      await addTrustedUser({payload, userId: item.id});
      //navigate to user profile
    } catch (error) {
      console.error('Failed to add trusted user:', error);
    }
  };


  return (
    <Card variant='outlined'
      sx={{
        width: '160px', // Fixed width for the card
        height: '160px', // Fixed height for the card
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start', // Align left
        justifyContent: 'flex-start', // Align top
        p: 0,
        m:2,
        overflow: 'hidden',
        borderRadius: 0,
        marginLeft: 0, // Set left margin to zero
        flexShrink: 0,
        flexBasis: 'auto'


      }}
    >
      <CardContent sx={{ flexGrow: 1}}>
        <UserPhoto photoUrl={item.profileImageUrl} name={item.businessName} size={45} />
        
        <Typography variant="subtitle2" >
          {item.displayName}
        </Typography>

        <Button 
          disabled={isLoading}
          size="small" 
          variant="outlined" 
          startIcon={<PersonAddOutlinedIcon />}
          onClick={handleConnectPress}
          sx={{
            
            borderRadius: '18px', // Make the pagination button circular
            
          }}
        
        >
        Connect
        </Button>
        
        
        
        
        
      </CardContent>
    </Card>
  );
};

export default CardItem;
