import React from 'react';
import { Box } from '@mui/material';
import Provider from './ProviderTypes/Provider';
import Restaurant from './ProviderTypes/Restaurant';
import Url from './ProviderTypes/Url';

const ResultsSection = ({ searchResults, type, cacheId }) => {
  //console.log('in results section, searchResults', searchResults)
  
  //console.log('in result section, newArray', newArray)

  return (
    <Box>
      {searchResults.map((result, index) => {
        
        return (
          <Box key={index} mb={2}>
            {type === 'provider' && <Provider result={result} cacheId={cacheId}/>}
            {type === 'restaurants' && <Restaurant result={result} />}
            {type === 'url' && <Url result={result} />}
            {/* Add more conditions for other types if needed */}
          </Box>
        );
      })}
    </Box>
  );
};

export default ResultsSection;
