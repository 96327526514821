import React, {useEffect, useState} from 'react';
import { Box, Paper, Avatar, Typography, Button } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { useThingAddMutation  } from '../../redux/thunks/things/thingadd';
import { useAddTrustedUserMutation  } from '../../redux/thunks/users/useraddtrusteduser';
import { useSelector } from 'react-redux';

import { useGetUserIncomingReqQuery } from '../../redux/thunks/users/userincomingrequests';
import { useGetUserOutgoingReqQuery } from '../../redux/thunks/users/useroutgoingrequests';
import { usePrefetch } from '../../redux/thunks/users/userprofile';







const UserCard = ({ user }) => {
  const navigate = useNavigate();
  //console.log('in user card: user: ', user)
  const [isPending, setIsPending] = useState(false);
  const thisUserId = useSelector((state) => state.auth.userId) 

  const firstDegreeFriends = useSelector((state) => state.auth.user.firstDegreeFriends) 
  //const pendingInTrustedUsers = useSelector((state) => state.auth.user.pendingInTrustedUsers) 
  //const pendingOutTrustedUsers = useSelector((state) => state.auth.user.pendingOutTrustedUsers)  
  
  const prefetchUserProfiles = usePrefetch('getUserProfile')
  const { data: incoming, isLoading: incomingLoading, isSuccess: incomingSuccess  } = useGetUserIncomingReqQuery(thisUserId);
  const { data: outgoing, isLoading: outgoingLoading, isSuccess: outgoingSuccess  } = useGetUserOutgoingReqQuery(thisUserId);

  const isConnected =  firstDegreeFriends.length > 0 &&  firstDegreeFriends.some((friend) => friend.userId.toString() === user._id.toString());
    
  const isMe = thisUserId === user._id

  
  const [addTrustedUser, { isLoading }] = useAddTrustedUserMutation();

  useEffect(() => {
    if (incomingSuccess && outgoingSuccess) {
      // Combine two arrays and create a new array with only unique values.
      const incomingIds = incoming.pendingInTrustedUsers.map(item => item.trustedUserId);
      const outgoingIds = outgoing.pendingOutTrustedUsers.map(item => item.trustedUserId);
      const isPendingIn =  incoming.pendingInTrustedUsers.length > 0 &&  incoming.pendingInTrustedUsers.some((friend) => friend.trustedUserId.toString() === user._id.toString());
      const isPendingOut =  outgoing.pendingOutTrustedUsers.length > 0 &&  outgoing.pendingOutTrustedUsers.some((friend) => friend.trustedUserId.toString() === user._id.toString());
      console.log('outgoing', outgoing)
      console.log('isPendingOut', isPendingOut)
      console.log('isPendingIn', isPendingIn)
      setIsPending(isPendingIn || isPendingOut)
      // Create a new array with only unique ids.
      const profileIds = [...new Set([...incomingIds, ...outgoingIds])];
      //profileIds.forEach(id => prefetch(id));
    }
  }, [incoming, outgoing]);
  //console.log('incoming', incoming)
  
  console.log('isPending', isPending)



  const handleConnectPress = async () => {
    try {
      const payload = { 'trustedUserId': user._id.toString(), requestType: 'new'  }
      await addTrustedUser({payload, userId: user._id.toString()});
      //navigate to user profile
    } catch (error) {
      console.error('Failed to add trusted user:', error);
    }
  };

  const handleNavigation2Profile = (id) => {
    // Define navigation logic for endorsed activity (e.g., go to profile page)
    navigate(`/profile/${id}`);
  };
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 2,
        border: '1px solid rgba(0, 0, 0, 0.1)',
      }}
      
    >
      <Avatar src={user.profileImageUrl} alt={user.displayName} sx={{ width: 64, height: 64, marginRight: 2 }} />
      <Typography variant="subtitle1" sx={{ flexGrow: 1, marginRight: 2, cursor: 'pointer' }} onClick={() => handleNavigation2Profile(user._id)}>
        {user.displayName}
      </Typography>
      {
      !isConnected && !isMe && !isPending &&
        <Button disabled={isLoading} variant="outlined" sx={{ borderRadius: '999px', border: '1px solid', padding: '8px 16px' }}  onClick={handleConnectPress}>
          {isLoading ? 'Connecting...' : 'Connect'}
          
        </Button>      
      }
      {
      isConnected &&
        <Button variant="outlined" sx={{ borderRadius: '999px', border: '1px solid', padding: '8px 16px' }}  onClick={handleConnectPress}>
          Message
        </Button>      
      }
      {
      isMe &&
        <Button variant="outlined" sx={{ borderRadius: '999px', border: '1px solid', padding: '8px 16px' }}>
          You
        </Button>      
      }
      {
      isPending &&
        <Button variant="outlined" sx={{ borderRadius: '999px', border: '1px solid', padding: '8px 16px' }}>
          Pending
        </Button>      
      }
      
      
    </Paper>
  );
};

export default UserCard;
