import React from 'react';
import { Box, Container, Grid, Typography, Stack, Chip } from '@mui/material';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export default function Stats() {
  return (
    <Box
      sx={{
        position: 'relative',
        py: { xs: 10, md: 15 },
        ':before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'url(/assets/business/feature.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          zIndex: 0,
        },
      }}>
      <Container>
        <Box
          sx={{
            position: 'relative',
            zIndex: 99,
          }}>
          <Stack
            mb={8}
            spacing={1.5}
            alignItems='center'
            justifyContent='center'>
            <Box>
              <Chip
                label='Stats'
                sx={{
                  color: 'primary.main',
                  bgcolor: 'rgba(0, 85, 255, 0.12)',
                  fontSize: 13,
                  fontWeight: 600,
                  px: 1,
                }}
              />
            </Box>
            <Typography
              variant='h3'
              fontWeight={600}
              fontSize={28}>
              Revfer In Numbers
            </Typography>
          </Stack>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            spacing={2}>
            {data?.map((item, index) => (
              <Grid
                key={Math.random()}
                item
                xs={12}
                sm={6}
                md={3}>
                <Stack
                  spacing={1}
                  alignItems='center'
                  justifyContent='center'>
                  <VisibilitySensor
                    partialVisibility
                    offset={{ bottom: 20 }}>
                    {({ isVisible }) => (
                      <Typography
                        variant='h2'
                        fontWeight={500}
                        fontSize={{ xs: 24, md: 32 }}>
                        {item.sign}
                        {isVisible ? <CountUp end={item.value} /> : item.value}
                        {item.symbol}
                      </Typography>
                    )}
                  </VisibilitySensor>
                  <Stack textAlign='center'>
                    <Typography
                      variant='body2'
                      fontSize={15}
                      fontWeight={500}>
                      {item.name}
                    </Typography>
                    <Typography
                      variant='body2'
                      fontSize={15}
                      fontWeight={400}>
                      {item.detail}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

const data = [
  {
    id: 1,
    name: 'Listed Businesses',
    detail: 'Join a growing community',
    value: 1,
    symbol: 'M+',
    
    
  },
  {
    id: 2,
    name: 'Active Users',
    value: 100000,    
    detail: 'growing community of users ',
  },
  {
    id: 3,
    name: 'User Satisfaction',
    value: 98,
    symbol: '%',
    detail: 'Our commitment to quality',
  },
  {
    id: 4,
    name: 'Users Preference',
    value: 89,
    symbol: '%',
    detail: 'Favor reviews from people they know',
  },
];
