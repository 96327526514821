import React from 'react';
import { useSelector } from 'react-redux';
import { Paper, Typography, Avatar, Grid, Divider, Box } from '@mui/material'; // Import Material-UI components
import { generateColorFromInitials } from '../../Functions/Utilities'; // Import your utility function
import UserPhoto from '../../Components/UserPhoto/UserPhoto';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import Lists from './Lists/Lists'


const iconName = 'Notes'; // The name of the MUI icon
const heading ='Similar Providers'


const MostReviewedBusinesses = ({ data, spId }) => { 
  const navigate = useNavigate();

  console.log('data in similar providers', data)
  console.log('spId in similar providers', spId)

  // Check if similarProfiles exists and is not empty
  if (!data || !data.similarProfiles || !data.similarProfiles.matchingBusinesses || data.similarProfiles.matchingBusinesses.length === 0) {
    return null; // Return null if similarProfiles is empty or does not exist
  }

  const names = data.similarProfiles.matchingBusinesses.map(business => ({
    businessId: business.businessId ? business.businessId : business._id,
    businessName: business.businessName,
    spId: business.spId,
    isSpSystem: business.spEmail === 'admin@revfer.com'
}));

console.log('names in similar providers', names)


  // Retrieve user data from the Redux store
  const user = useSelector((state) => state.auth.user);
  const userId = useSelector((state) => state.auth.userId);
  
  const handleNavigation2Profile = (id) => {
    // Define navigation logic for endorsed activity (e.g., go to profile page)
    navigate(`/profile/${id}`);
  };  

  const handleSeePremiumClick = () => {
    // Add your logic here to handle endorsements (including removal)
    navigate(`/pricing`);
  };

  return (
    <Paper variant="outlined" sx={{ padding: 1, border: 'none' }}>
      <Lists heading={heading} names={names} iconName={iconName} rowSpacing={-2} iconSpacing={-3} spId={spId} />
       

    </Paper>
  );
};

export default MostReviewedBusinesses;
