import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TextField from '@mui/material/TextField';

import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

import Box from '@mui/material/Box';

import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

import ChatUserList from './ChatUserList'; // Import the ChatUserList component
import ChatMessageDetail from './ChatMessageDetail';


import { useLazyGetUserChatHistoryQuery } from '../../redux/thunks/users/userchathistory';
import { useWebSocket } from '../../WebSockets/WebSocketProvider';
import {useSelector} from 'react-redux';
import { useNavigate, useParams, useLocation } from "react-router-dom";






const Chat = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log('in provider profile, location.state', location.state);
  const otherUser = location.state?.otherUser || '';

  console.log('in char otherUser ', otherUser)



  const [selectedUser, setSelectedUser] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [messageInput, setMessageInput] = useState(''); // State for the message input
  const userId = useSelector((state) => state.auth.userId)
  const displayName = useSelector((state) => state.auth.user.displayName)
  const profileImageUrl = useSelector((state) => state.auth.user.profileImageUrl)
  
  const [fetchChatHistory, { data: chatHistoryData, isLoading: chatHistoryLoading }] = useLazyGetUserChatHistoryQuery();
  const { socket } = useWebSocket();

  

  useEffect(() => {
    // Calculate initials for each user when userChatList changes
    if (chatHistoryData) {
        setChatHistory(chatHistoryData.messages)
    }
  }, [chatHistoryData]);

  useEffect(() => {
    // Function to handle incoming messages
    const handleIncomingMessage = (event) => {
      console.log('handleIncomingMessage=', event)
      const socketData = JSON.parse(event.data);

      console.log('handleIncomingMessage socketData=', socketData)
      if (socketData.body.type === "CHAT_MESSAGE" && socketData.body.subType === 'RECEIVE') {
        const newMessage = transformWebSocketDataForRendering(socketData.body);
        setChatHistory((prevChatHistory) => [...prevChatHistory, newMessage]);
      }
      else
      {
        console.log('WTF is going on');

      }
    };

    // Check if the socket is available and set up a listener
    if (socket) {
      socket.addEventListener('message', handleIncomingMessage);
    }

    // Cleanup: remove the event listener on component unmount
    return () => {
      if (socket) {
        socket.removeEventListener('message', handleIncomingMessage);
      }
    };
  }, [socket]);

  const transformWebSocketDataForRendering = (socketData) => {
    const { sender, text, displayName, profileImageUrl, msgId } = JSON.parse(socketData.data);
    return {
        msgId,  // Assuming you don't get a unique message ID from WebSocket, you can generate one
        text,
        msgTime: new Date(),  // Use the current date-time as the message creation time
        profileImageUrl,
        displayName,
        sender        
    };
  };

  
  const handleUserClick = async (user) => {
    setSelectedUser(user);
    console.log('in handleUserClick, user', user)
    await fetchChatHistory(user.otherUserId); 

    
  };  

  console.log('in Chat, chatHistory', chatHistory)
  console.log('in Chat, chatHistoryLoading', chatHistoryLoading)  

  const handleMessageChange = (e) => {
    setMessageInput(e.target.value); // Update message input state
  };

  const handleSendMessage = () => {
    // Handle sending the message here
    if (messageInput.trim() !== '') {
        const ephemeralNewMessage = {
            text: messageInput,
            displayName,
            profileImageUrl,
            msgId:(Math.floor(100000 + Math.random() * 900000)).toString(),
            msgTime: new Date()
    
        }
        setChatHistory((prevChatHistory) => [...prevChatHistory, ephemeralNewMessage]);
        if (!socket) {
            console.error('Socket is not available!');
            return;
        }

        // Also send this message via WebSockets to the server
        // Send this message via WebSockets to the server
        console.log('socket inside chatdetails onsend method', socket)
        if (socket) {

            const payload = {
                type: "CHAT_MESSAGE",
                subType: 'SEND',
                data: {
                    sender:userId,
                    to: selectedUser.otherUserId,
                    text: messageInput
                }
                };
                console.log('payload in chat detail screen', JSON.stringify(payload))
                socket.send(JSON.stringify(payload));
        }

      console.log('Message sent:', messageInput);
      setMessageInput(''); // Clear the message input
    }
  };

  if (chatHistoryLoading ) {
    // Show a loading spinner if either user data or activities data is loading
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress color="primary" />
      </Box>

    )
  }
  return (
    <Box maxWidth={1280} mx="auto" p={2}> 
   
      

      
      <Grid container sx={{ border: '0px solid #e0e0e0', p: 1, mb: 2 }}>
        <Grid item={true} xs={4} sx={{ border: '0px solid #e0e0e0', p: 1, bgcolor: 'white' }}>
          {/* First Column */}
          <Grid container direction="column" spacing={1}>
            <Grid item sx={{ border: 'none', p: 0,  }}>
              {/* Header */}
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h6">Messages</Typography>
                </Grid>
                <Grid item>
                  <MoreHorizIcon />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ border: 'none', p: 1 }}>
              {/* Search Box */}
              <Grid container alignItems="center">
                <Grid item xs>
                  <TextField
                    label="Search"
                    variant="outlined"
                    fullWidth
                    sx={{
                        backgroundColor: '#f5f5f5', // Change the background color as needed
                        height: '40px', // Adjust the height as needed
                        '& .MuiInputBase-input': {
                          padding: '10px', // Adjust the padding as needed
                        },
                      }}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            // Handle search icon click
                          }}
                        />
                      ),
                      endAdornment: (
                        <FilterListIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            // Handle filter icon click
                          }}
                        />
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ border: 'none', p: 1 }}>
              {/* List of Users */}
              <ChatUserList onUserClick={handleUserClick} otherUser={otherUser}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={6} sx={{ border: 'none', p: 1, bgcolor: 'white' }}>
        <Box
            sx={{
              maxHeight: '80vh',
              overflowY: 'auto',
              scrollbarWidth: 'none', // Hide scrollbar in Firefox
              msOverflowStyle: 'none', // Hide scrollbar in IE and Edge
              '&::-webkit-scrollbar': {
                width: '0.4em',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'transparent',
              },
              
            }}
          >
            {chatHistory && chatHistory.length > 0 ? (
                chatHistory.map((message) => (
                <ChatMessageDetail key={message.msgId} message={message} />
                ))
                 ) : (
                <Typography variant="body1">No messages to display.</Typography>
            )}
          </Box>
          {/* Message Input */}
          <Divider flexItem sx={{ flexGrow: 1, marginTop: 1, marginBottom: 1 }} />
          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={3}
            label="Type your message..."
            sx={{
                backgroundColor: '#f5f5f5', // Change the background color as needed
                height: '120px', // Adjust the height as needed
                '& .MuiInputBase-input': {
                  padding: '10px', // Adjust the padding as needed
                },
              }}
            value={messageInput}
            onChange={handleMessageChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage();
              }
            }}
          />
        </Grid>
        <Grid item={true} xs={2} sx={{ border: 'none', p: 1 }}>
          {/* Third Column */}
          {/* Content for the third column */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Chat;
