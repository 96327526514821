import React, { useState, useEffect } from 'react';
import ReviewedActivity from './ActivityTypes/ReviewedActivity';
import EndorsedActivity from './ActivityTypes/EndorsedActivity';
import FollowedBusiness from './ActivityTypes/FollowedBusiness';
import LikedDislikedActivity from './ActivityTypes/LikedDislikedActivity';




// use this query eventually to get a slice from user profile
import { useGetActivitiesQuery } from '../../redux/thunks/users/getuserbyemail';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, Pagination  } from '@mui/material';

const ActivityTypeActionMapper = (activity) => {
  if (activity.type === 'providers') {
    if (activity.action === 'reviewed') return 'providerReviewed';
    if (activity.action === 'endorsement') return 'providerendorsement';
    if (activity.action === 'followedBusiness') return 'providerFollowedBusiness';
    if (activity.action === 'likedReview') return 'providerLikedReview';
  }
};

const ActivitySection = ({activity}) => {
  const userId = useSelector((state) => state.auth.userId);

 
  
  const [page, setPage] = useState(1);
  const itemsPerPage = 10; // Set the number of items per page here

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  console.log('in activity section activity: ', activity)



  return (
    <Box sx={{ p: 0 }}>
    

      {/* Render activities if not loading */}
      {activity && (
        <Box>
          {activity.slice(startIndex, endIndex).map((activity) => (
            <div key={activity._id}>
              {/* Conditional rendering based on actionType */}
              {ActivityTypeActionMapper(activity) === 'providerReviewed' && (
                <ReviewedActivity activity={activity} />
              )}
              {ActivityTypeActionMapper(activity) === 'providerendorsement' && (
                <EndorsedActivity activity={activity} />
              )}
              {ActivityTypeActionMapper(activity) === 'providerFollowedBusiness' && (
                <FollowedBusiness activity={activity} />
              )}
              {ActivityTypeActionMapper(activity) === 'providerLikedReview' && (
                <LikedDislikedActivity activity={activity} />
              )}
            </div>
          ))}
        </Box>
      )}

      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        <Pagination
          count={Math.ceil(activity.length / itemsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
    </Box>
  );
};

export default ActivitySection;
