import React from 'react';

const ComingSoon = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', height: '10vh', marginTop: '-120px' }}>
      {/* Replace 'coming-soon.svg' with the path to your SVG image */}
      <img src="/assets/images/WebDeveloper.svg" alt="Coming Soon" width="1200" height="1200" />

      <p style={{ marginTop: '-100px', fontSize: '88px', fontWeight: 'bold' }}>Coming Soon</p>
    </div>
  );
};

export default ComingSoon;
