import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useEmailInviteMutation } from '../../redux/thunks/users/useremailinvite'; // Import your RTK Query mutation

const EmailInvite = () => {
  const [email, setEmail] = useState('');
  const [inviteUser, { isLoading, isError }] = useEmailInviteMutation();

  const handleSubmit = async () => {
    try {
      // Call the mutation function with the form data
      console.log('handle submit; email: ', email)
      const payload = {
        emails: [email]
      }
      console.log('handle submit; payload: ', payload)
      const response = await inviteUser(payload);
      // Handle success
      console.log('Invitation sent successfully:', response);
    } catch (error) {
      // Handle error
      console.error('Error sending invitation:', error);
    }
  };

  return (
    <Box>
      <Typography variant="body2" gutterBottom>
        Invite a User
      </Typography>
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? 'Sending...' : 'Invite'}
      </Button>
      {isError && (
        <Typography variant="body2" color="error">
          Error sending invitation. Please try again.
        </Typography>
      )}
    </Box>
  );
};

export default EmailInvite;
