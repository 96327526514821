import React from 'react';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import moment from 'moment';

const ChatMessageDetail = ({ message }) => {
  const formattedDate = moment(message.msgTime).format('MMM D, YYYY');
  const formattedTime = moment(message.msgTime).format('h:mm A');

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Divider flexItem sx={{ flexGrow: 1, marginTop: 1, marginBottom: 1 }} />
          <Typography variant="caption" color="textSecondary" sx={{ marginX: 1 }}>
            {formattedDate}
          </Typography>
          <Divider flexItem sx={{ flexGrow: 1, marginTop: 1, marginBottom: 1 }} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Avatar alt={message.displayName} src={message.profileImageUrl} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{message.displayName}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{message.text}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" color="textSecondary">
          {formattedTime}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ChatMessageDetail;
