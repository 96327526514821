import React from 'react';
// mui
import { Box, Container, Grid, Typography, Stack, Chip } from '@mui/material';
// components
import FeaturesCard from '../cards/featuresCard';
// icons
import GppGoodTwoToneIcon from '@mui/icons-material/GppGoodTwoTone';
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
import { PiPhonePlusDuotone } from 'react-icons/pi';
import SentimentSatisfiedTwoToneIcon from '@mui/icons-material/SentimentSatisfiedTwoTone';

export default function Features() {
  return (
    <Box
      sx={{
        position: 'relative',
        py: { xs: 15, md: 10 },
        ':before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'url(/assets/business/feature.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          zIndex: 0,
        },
      }}>
      <Container>
        <Box
          sx={{
            position: 'relative',
            zIndex: 99,
          }}>
          <Stack
            mb={5}
            spacing={1.5}
            alignItems='center'
            justifyContent='center'>
            <Box>
              <Chip
                label='Why Choose us'
                sx={{
                  color: 'primary.main',
                  bgcolor: 'rgba(0, 85, 255, 0.12)',
                  fontSize: 13,
                  fontWeight: 600,
                  px: 1,
                }}
              />
            </Box>
            <Typography
              variant='h3'
              fontWeight={600}
              textAlign={{ xs: 'center', md: 'left' }}
              fontSize={28}>
              Enhance Your Business with Revfer
            </Typography>
            <Typography
              variant='body2'
              fontSize={15}
              fontWeight={400}
              textAlign={{ xs: 'center', md: 'left' }}
              color='text.secondary'>
              Here are the few reasons why you should choose us
            </Typography>
          </Stack>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            spacing={2}>
            {data?.map((item, index) => (
              <Grid
                key={Math.random()}
                item
                xs={12}
                md={6}>
                <FeaturesCard item={item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

const data = [
  {
    id: 1,
    name: 'Authentic Reviews',
    detail:
      'Benefit from genuine reviews posted by customers within trusted networks, increasing credibility and trust',
    icon: <GppGoodTwoToneIcon size={24} />,
  },
  {
    id: 2,
    name: 'Targeted Exposure',
    detail:
      'Reach potential customers through connections, expanding your reach and attracting new business',
    icon: <CreditCardTwoToneIcon size={24} />,
  },
  {
    id: 3,
    name: 'AI-Powered Insights',
    detail:
      'Utilize AI-driven analytics to gain actionable insights into customer behavior and preferences',
    icon: <SentimentSatisfiedTwoToneIcon size={24} />,
  },
  {
    id: 4,
    name: 'Customer Engagement',
    detail:
      'Interact directly with customers, respond to feedback, and build strong relationships',
    icon: <PiPhonePlusDuotone size={24} />,
  },
];
