import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { Autocomplete, TextField, Typography, CircularProgress, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { useGetAllThingsQuery} from '../../redux/thunks/things/thingsget';



const SubCategoriesPage = () => {
  const userId = useSelector((state) => state.auth.userId);  
  const token = useSelector((state) => state.auth.token);  
  const spId = useSelector((state) => state.auth.user.spId);  
  const args = {
    spId: spId,
    authType: 'token',
    token: token
  }

  const { data: categoriesData, isLoading: iscategoriesDataLoading } = useGetAllThingsQuery(args);
  

 


  if (iscategoriesDataLoading ) {
    // Show a loading spinner if either user data or activities data is loading
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress color="primary" />
      </Box>

    )
  }

  //const allNames = [...new Set(categoriesData.data.map((item) => item.name))];


  return (
    <>
      <Typography variant="h6" gutterBottom>
        Select Sub Categories
      </Typography>
      <Field name="subCategories">
        {({ field, form }) => (
          <Autocomplete
            {...field}
            options={categoriesData.data}
            getOptionLabel={(option) => (option ? option.name || '' : '')}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Sub Categories"
                variant="outlined"
                fullWidth
                
              />
            )}
            // Enable multiple selections
            multiple
            onChange={(_, value) => form.setFieldValue('subCategories', value)}
          />
        )}
      </Field>
    </>
  );
};

export default SubCategoriesPage;
