
import {thingsApi} from './things'

export const thingFollowApi = thingsApi.injectEndpoints({
  endpoints: (builder) => ({
    
    thingFollow: builder.mutation({
      
      query: ({payload}) => ({
        url: '/follow',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useThingFollowMutation } = thingFollowApi