
import {userApi} from './user'


export const getActivitiesApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getActivities: builder.query({
      query: ({ userId, activityType}) => `/activity/${userId}?type=${activityType}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetActivitiesQuery, usePrefetch } = getActivitiesApi