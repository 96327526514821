import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { ParallaxProvider } from "react-scroll-parallax";
import { Provider } from 'react-redux';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports'; // Import your AWS Amplify configuration

//import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import App from './App';
import theme from './theme';
import store, { persistor } from './redux/store';
import { WebSocketProvider } from './WebSockets/WebSocketProvider'; 
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Authenticator } from '@aws-amplify/ui-react'; // Import Authenticator

import { PersistGate } from 'redux-persist/integration/react';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

Amplify.configure(awsconfig); // Configure Amplify with your settings

//const AppWithAuth = withAuthenticator(App, { includeGreetings: true }); // Wrap your App component with authentication




root.render(
  <Provider store={store}> 
   <PersistGate loading={null} persistor={persistor}> 
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      
      <ParallaxProvider>
        <BrowserRouter>
        <WebSocketProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>      

        <Authenticator.Provider>

        <App />

        </Authenticator.Provider>
         
        
          
        </LocalizationProvider>

        </WebSocketProvider>
          
        </BrowserRouter>
      </ParallaxProvider>
    </ThemeProvider>
    </PersistGate>
  </Provider>
);
