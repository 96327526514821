import React, {useEffect, useMemo, useState} from 'react';
import { Box, Grid, Paper, Chip, CircularProgress } from '@mui/material';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import * as Utils from '../../Functions/Utilities'



import { useGetSpProfileQuery  } from '../../redux/thunks/sp/getspprofile';
import {useGetBusinessByIdQuery} from '../../redux/thunks/unauth/getbusinessbyid'
import { usePrefetch as useGetSpReviewPrefetch } from '../../redux/thunks/sp/spreviewget';
import { usePrefetch as useUserTrustChainPrefetch } from '../../redux/thunks/users/usertrustchainsget';
import { useGetUserProfileQuery  } from '../../redux/thunks/users/userprofile';


import SpFacePlate from './SpFacePlate'; 
import UserEndorsements from './UserEndorsements'; 
import CommonFromNetwork from './CommonFromNetwork'; 

import SkillFacePlate from './SkillFacePlate'; 
import ShowReviews from './ShowReviews'; 
import SkillStats from './SkillStats'; 
import SimilarProviders from './SimilarProviders'; 

import SearchComponent from "../../Components/SearchComponent/SearchComponent";
import { useSpFindMutation  } from '../../redux/thunks/sp/spfind';

import { useGetUserCacheDataQuery  } from '../../redux/thunks/users/usercachedata';
import { useAuthenticator } from '@aws-amplify/ui-react';






const ProviderProfile = () => {
  const location = useLocation();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  //console.log('in provider profile, location.state', location.state);  

 
  const { businessId, title } = useParams();
  console.log('in business unauth businessId', businessId);  
  console.log('in business unauth title', title);  

  const [profileData, setProfileData] = useState(''); 
 

  const navigate = useNavigate();
   
  const token = useSelector((state) => state.auth.token) 
  const [triggerSearch, { data: searchResults, isLoading: isSearchLoading }] = useSpFindMutation(); 
  //const thisSpId = useSelector((state) => state.auth.user.spId)

  const args = {
    businessId: businessId,
    authType: 'none',
    token: token,
    title: title
  }
  const { data: profile, error: profileError, isLoading: profileLoading } = useGetBusinessByIdQuery(args);
  //const { data: reviews, error: reviewError, isLoading: reviewLoading } = useGetSpReviewQuery(args);
  const prefetchGetSpReview = useGetSpReviewPrefetch('getSpReview')
  console.log('wow profile', profile)

  console.log('profileLoading', profileLoading)

  useEffect(() => {
    if (authStatus === 'authenticated' && profile) {

      navigate(`/provider/profile/${profile.spId}`, { state: { businessId: profile.businessId, businessName: profile.businessName, skill: profile } });
     
    }
  }, [authStatus, profile, navigate]);


   
 

  const isLoading = profileLoading;
  const error = profileError ;

  

 



  if (isLoading ) {
    // Show a loading spinner if either user data or activities data is loading
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress color="primary" />
      </Box>

    )
  }

  console.log('profile', profile)


  return (
    <Box maxWidth={1280} mx="auto" p={2}>
      {/* First Full-Width Row with Border */}
      <Paper variant="outlined" style={{ marginBottom: '0px', padding: '0px', border: 'none' }}>
        
      </Paper>


      {/* Three Columns: 25%, 50%, 25% */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          {/* First Column (25%) */}
          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
            {profile && <SpFacePlate business={profile} />}
          </Paper>

          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
            { <SimilarProviders data={profile }/>}
          </Paper>

        

        

          
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* Second Column (50%) */}          

          

          
          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>            
              <SkillFacePlate skill={profile }   />                      
          </Paper>
          

         

          <Paper variant="outlined" sx={{  border: 'none', bgcolor: '#F8F9F9' }}>
            <ShowReviews  skill={profile}/>
          </Paper>

        </Grid>
        <Grid item xs={12} sm={3}>
          {/* Third Column (25%) */}
          
          <Paper variant="outlined" style={{ padding: '20px' }}>         
              <SkillStats skill={profile } />              
          </Paper>         
          
     
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProviderProfile;
