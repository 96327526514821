
import {thingsApi} from './things'

export const getAllThingsApi = thingsApi.injectEndpoints({
  endpoints: (builder) => ({
    
    getAllThings: builder.query({
      
      query: ({spId, authType, token}) => `/getallskills?authType=${authType}&&token=${token}&&spId=${spId}`,
    }),

  }),
  overrideExisting: false,
})

export const { useGetAllThingsQuery, usePrefetch } = getAllThingsApi