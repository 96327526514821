
import {aiApi} from './ai'

export const generateTextApi = aiApi.injectEndpoints({
  endpoints: (builder) => ({
    
    generateText: builder.mutation({
      
      query: ({payload}) => ({
        url: '/generatetext',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useGenerateTextMutation } = generateTextApi