// components

import Hero from './hero';
import About from './about';
import Features from './features';
import Flexible from './flexible';
import Testimonial from './testimonial';




const StartPage = () => {

  return (
    <>
      
      
        <Hero />
        <About />
        <Features />
        
        <Testimonial />
     
    </>
  );
}
  
  


export default StartPage;
