import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { fetchAuthSession } from "aws-amplify/auth";

//const url = 'http://localhost:8080/https://devapi.revfer.com/devcore/test/assets'
//const url = 'https://devapi.revfer.com/devcore/test/assets'

const url = process.env.REACT_APP_PROXY_PREFIX + 'https://devapi.revfer.com/devcore/assets'
console.log('url', url)

export const iconsApi = createApi({
  reducerPath: 'iconsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: async (headers, { getState }) => {
        //const token = getState().auth.token
        //console.log('token', token)

        const session = await fetchAuthSession(); 
        const token = session.tokens.idToken.toString();
        console.log('my token in icons base api', token)
    
        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set('Authorization', `${token}`)
          headers.set("Content-Type", "appliation/json")
        }
        console.log('headers', headers)
    
        return headers
      },
  }),
  tagTypes: ['icon'],
  endpoints: () => ({}),
})


