import React, {useState, useEffect} from 'react';
import UserPhoto from '../../Components/UserPhoto/UserPhoto';
import { Box, Typography, Button, Avatar, Divider, IconButton, Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import GroupsIcon from '@mui/icons-material/Groups';
import ChatIcon from '@mui/icons-material/Chat';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TrustChainDisplay from '../../Components/TrustChain/TrustChainDisplay';
import MoreMenu from '../../Components/Menus/MoreMenuSp';

import { useNavigate } from 'react-router-dom';


const SpFacePlate = ({ sp, skill }) => {

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [business, setBusiness] = useState(skill);

  console.log('skill', skill)

  useEffect(() => {   
    if(skill) {
      setBusiness(skill)
    }   
    
  }, [skill]);



  const thisSpId = useSelector((state) => state.auth.user.spId)
  const isThisMe = thisSpId===sp._id;
  const isSpSystem = sp.email==='admin@revfer.com'
  //console.log('isThisMe', isThisMe)

  

  const handleNavigation2Chat = (id) => {
    // Define navigation logic for endorsed activity (e.g., go to profile page)
    navigate(`/chat`, { state: { otherUser:{otherUserId: sp.userId, displayName: sp.displayName, photoUrl: sp.profileImageUrl, lastMessage: '', msgTime: new Date()} } });
  };

  const handleNavigation2Profile = (id) => {
    // Define navigation logic for endorsed activity (e.g., go to profile page)
    navigate(`/profile/${id}`);
  };

  const handleNavigation2AdminPage = (id) => {
   
    navigate(`/business/admin/${id}`,{ state: { businessId: skill.businessId } });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleShareClick = () => {
    // Handle share action
    handleClose();
  };

  const handleSeeBusinessClick = (id) => {
    // Handle see business pages action
    navigate(`/provider/profile/${user.spId}`, { state: { businessId: 'default', businessName: 'default' } });
    handleClose();
  };

  const handleFollowClick = () => {
    // Handle follow action
    handleClose();
  };

  const handleReportClick = () => {
    // Handle report action
    handleClose();
  };



 
  return (
    <Box border={0} borderRadius="borderRadius" p={2} textAlign="center">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={1} >
        <UserPhoto photoUrl={sp.profileImageUrl}  name={sp.displayName} size={50} />
      </Box>
      <Typography variant="h6" fontWeight="bold" sx={{cursor: 'pointer'}} onClick={() => handleNavigation2Profile(sp.userId)}>
        {sp.displayName}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        {isThisMe &&
          <Typography variant="body2" color="textSecondary" mr={1} onClick={() => handleNavigation2AdminPage(skill.businessId)}>
           See Admin Page
        </Typography>

        }
        
        {!isThisMe && !isSpSystem &&
        <TrustChainDisplay userId={sp.userId} displayName={sp.displayName} spinner={false}/>
        }
        
       
        
      </Box>
      <Box mt={4}>
        <Grid container justifyContent="center" spacing={2}>
          {!isThisMe &&
            <Grid item>
              <Avatar variant="contained" style={{ backgroundColor: '#0f75f2', color: 'white' }}>
                <ChatIcon sx={{ fontSize: 16 }} />
              </Avatar>
             </Grid>
          
          }
          
          
          <Grid item>
            <Avatar variant="contained" sx={{ backgroundColor: '#607D8B', color: 'white', cursor: 'pointer'   }} onClick={handleClick}>
              <MoreHorizIcon sx={{ fontSize: 16 }} />

            </Avatar>
            <MoreMenu
                isThisMe ={isThisMe}            
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                onShareClick={handleShareClick}
                onSeeBusinessClick={handleSeeBusinessClick}
                onFollowClick={handleFollowClick}
                onReportClick={handleReportClick}
              />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SpFacePlate;
