import React from 'react';
import { Paper, Typography, Avatar, Box } from '@mui/material';

const BusinessInfo = () => {
  return (
    <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb:1, borderRadius: 2  }}>
      <Box display="flex" alignItems="center">
        <Avatar sx={{ mr: 2 }}>B</Avatar>
        <Box>
          <Typography variant="h6">Business Name</Typography>
          <Typography variant="body2" color="textSecondary">Category</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default BusinessInfo;
