import React from 'react';
import { Field } from 'formik';
import { Typography, TextField, Paper } from '@mui/material';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import './AddressPage.css'; // Import your custom CSS file

const AddressPage = () => {
  const handleSelect = async (address) => {
    try {
      //const results = await geocodeByAddress(address);
      //const latLng = await getLatLng(results[0]);
      // You can use the latLng or address data as needed
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Address (Optional)
      </Typography>
      <Field name="address">
        {({ field, form }) => (
          <PlacesAutocomplete
            value={field.value}
            onChange={(value) => form.setFieldValue('address', value)}
            onSelect={(value) => {
              form.setFieldValue('address', value);
              handleSelect(value);
            }}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="dropdown-container">
                <TextField
                  {...getInputProps({
                    label: 'Address',
                    variant: 'outlined',
                    fullWidth: true,
                    margin: 'normal',
                  })}
                />
                {suggestions.length > 0 && (
                  <Paper className="dropdown-suggestions">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        {...getSuggestionItemProps(suggestion, {
                          className: 'suggestion-item',
                        })}
                      >
                        {suggestion.description}
                      </div>
                    ))}
                  </Paper>
                )}
              </div>
            )}
          </PlacesAutocomplete>
        )}
      </Field>
    </div>
  );
};

export default AddressPage;
