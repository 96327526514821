import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


const Url = ({ result }) => {
  const navigate = useNavigate();
  // Calculate "x days ago" format for creation date
  const creationDate = moment(result.creationTime).fromNow();

  const handleNavigation2details = (result) => {
    // Define navigation logic for endorsed activity (e.g., go to profile page)
    console.log('result in url search handle navigation', result)
    navigate(`/url/request/${result._id}`);
  };

  //console.log('result', result)
  
  return (
    <Box display="flex" 
         flexDirection="column" 
         alignItems="flex-start" 
         sx={{border: '1px solid rgba(0, 0, 0, 0.1)', 
          p: 2, 
          my: 2, 
          overflow: 'hidden', 
          cursor: 'pointer'  }}
          onClick={() => handleNavigation2details(result)}

          >
      <Link href={result.urlRaw} target="_blank" rel="noopener noreferrer" sx={{ fontSize: 16, marginBottom: 1, wordWrap: 'break-word' }}>
        {result.urlRaw}
      </Link>
  
      <Box display="flex" alignItems="center" sx={{ fontSize: 14, color: 'text.secondary', my: 2 }}>
        {result.platform && (
          <Typography sx={{ mr: 2 }} variant="body2">
            {result.platform}
          </Typography>
        )}
        <Typography variant="body2">{creationDate}</Typography>
      </Box>
  
      <Box display="flex" alignItems="center" sx={{ fontSize: 18 }}>
        {result.company && (
          <Typography sx={{ mr: 2 }} variant="subtitle2">
            {result.company.Text}
          </Typography>
        )}
        {result.name && (
          <Typography variant="subtitle2" style={{ display: 'inline' }} width={'auto'}>
            {result.name.Text}
          </Typography>
        )}
      </Box>
    </Box>
  );
  
};

export default Url;
