import React from 'react';
import { Chart } from 'react-google-charts';

const CircularProgressChart = ({ percentage, size = 100 }) => {
 
  const options = {
    pieHole: 0.7,
    pieSliceText: 'none',
    legend: 'none',
    backgroundColor: 'transparent',
    pieStartAngle: 270,
    tooltip: { trigger: 'none' },
    enableInteractivity: false,
    slices: {
      0: { color: '#4caf50' },
      1: { color: '#e6e6e6' }
    },
    chartArea: {
      width: size,
      height: size
    },
  };

  const data = [
    ['Task', 'Percentage'],
    ['Completed', percentage],
    ['Remaining', 100 - percentage],
  ];

  return (
    <div style={{ position: 'relative', width: size, height: size }}>
      <Chart
        chartType="PieChart"
        width={`${size}px`}
        height={`${size}px`}
        data={data}
        options={options}
      />
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: `${size / 5}px`,
        fontWeight: 'bold',
        color: '#4caf50',
      }}>
        {`${percentage} %`}
      </div>
    </div>
  );
};

export default CircularProgressChart;
