import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { fetchAuthSession } from "aws-amplify/auth";

//import store from '../../store';
//import { setSignOut } from '../../slices/auth'

//we need to figure out how to signout

//const url = 'http://localhost:8080/https://devapi.revfer.com/devcore/test/user'
//const url = 'https://devapi.revfer.com/devcore/test/user'

const url = process.env.REACT_APP_PROXY_PREFIX + 'https://devapi.revfer.com/devcore/user'
console.log('url', url)


const baseQueryWithInterceptor = async  (args, api, extraOptions) => {  
  // A regular fetchBaseQuery using the config provided by `args`, and including the baseUrl and prepareHeaders logic
  const result = await fetchBaseQuery({
    ...args,
    baseUrl: url,
    cacheTime: 900000, // Set cache timeout to 15 minutes
    prepareHeaders: async (headers, { getState }) => {
      // Add auth token if it exists
      //const token = getState().auth.token;
      const session = await fetchAuthSession(); 
      const token = session.tokens.idToken.toString();
      //console.log('my token in user base api', token)
      if (token) {
        headers.set('Authorization', `${token}`)
        headers.set("Content-Type", "application/json")
      }
      return headers;
    }
  })(args, api, extraOptions);

  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    // if the response status is 401, dispatch logout action and navigate to login screen
    //store.dispatch(setSignOut())
  }

  return result;
};

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['User', 'IncomingReq', 'OutgoingReq', 'Follow', 'ProfileUpdate', 'Endorsements'],
  endpoints: () => ({}),
})
