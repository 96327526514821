import React, { useState } from 'react';
// mui
import {
  Box,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
  Button,
  Divider,
  Grid,
} from '@mui/material';
// components
import PricingCard from './PricingCard';
// data
import data from './config.json';

export default function Pricing() {
  const [subscription, setSubscription] = useState('12 Months');

  const currentPlan = data.data.find(
    (plan) => plan.subscription === subscription
  );
  const handleSubscriptionChange = (newSubscription) => {
    setSubscription(newSubscription);
  };
  console.log(currentPlan);

  return (
    <Box
      sx={{
        position: 'relative',
        ':before': {
          content: "''",
          position: 'absolute',
          zIndex: 9,
          background: 'url(before.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: 235,
          width: 235,
          top: 0,
          right: 0,
        },
        ':after': {
          content: "''",
          position: 'absolute',
          zIndex: 9,
          background: 'url(after.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: 235,
          width: 235,
          bottom: 0,
          left: 0,
        },
      }}>
      <Container maxWidth='xl'>
        <Card
          sx={{
            my: 8,
            position: 'relative',
            zIndex: 999,
            borderRadius: '10px',
            boxShadow: 'none',
          }}>
          <CardContent
            sx={{
              px: { xs: 2, md: 4.5 },
              py: 4,
            }}>
            <Stack
              mb={5}
              spacing={2}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent='space-between'
              alignItems='center'>
              <Typography
                variant='h1'
                fontSize={{ xs: 24, md: 36 }}
                fontWeight={600}>
                Get Started Now,
                <br /> Pick a Plan Later
              </Typography>
              <Stack
                direction='row'
                alignItems='center'
                spacing={3}>
                {['12 Months', '6 Months', '3 Months'].map((period) => (
                  <Button
                    key={period}
                    variant={subscription === period ? 'contained' : 'inherit'}
                    color='primary'
                    sx={{
                      textTransform: 'capitalize',
                      bgcolor:
                        subscription === period ? 'primary.main' : '#eeefff',
                      color:
                        subscription === period ? '#eeefff' : 'primary.main',
                    }}
                    onClick={() => handleSubscriptionChange(period)}>
                    {period}
                  </Button>
                ))}
              </Stack>
            </Stack>
            <Divider />
            <Box mt={6}>
              <Grid
                container
                spacing={{ xs: 4, md: 2 }}>
                {currentPlan &&
                  currentPlan.plan.map((plan, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      md={6}
                      lg={4}>
                      <PricingCard plan={plan} />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}