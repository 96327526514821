import React, { useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Box,
  Stack
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SmsIcon from '@mui/icons-material/Sms';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SearchComponent from "../SearchComponent/SearchComponent";
import { useAuthenticator } from '@aws-amplify/ui-react'


import { useSelector, useDispatch } from 'react-redux';

import { signOut } from '../../redux/slices/auth'



const Header = ({onSearch}) => {
  const { authStatus, user, signOut: authSignOut } = useAuthenticator(context => [context.authStatus, context.user]);
  

  const {isLoggedIn, loading} = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    if (authStatus === 'unauthenticated') {
      //todo: create a landing page link here
      //navigate(`/auth/signin`);
      
    }
  }, [authStatus, navigate]);


  const onLogOut = () => {
    console.log('in signout: isLoggedIn')
    authSignOut()
    console.log('authStatus in logout', authStatus)
    dispatch(signOut())
    navigate(`/auth/signout`);
    
    
    
    
    
  }

 

  //console.log('in header:', isLoggedIn)

  const loggedInHeader = (
    <Box>
      <Box display="flex">



        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mr:5 }}>
          <IconButton component={Link} to="/home" color="inherit" style={{ textDecoration: 'none', color: 'black' }}>
            <HomeIcon />
          </IconButton>
          <Typography variant="caption" align="center" color='black' sx={{ marginTop: '-5px' }}>Home</Typography>
        </Box>
        
       
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',  mr:5 }}>
          <IconButton component={Link} to="/mynetwork" color="inherit" style={{ textDecoration: 'none', color: 'black' }}>
            <PeopleIcon />
          </IconButton>
          <Typography variant="caption" align="center" color='black' sx={{ marginTop: '-5px' }}>My Network</Typography>
        </Box>

        
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',  mr:5 }}>
          <IconButton component={Link} to="/chat" color="inherit" style={{ textDecoration: 'none', color: 'black' }}>
            <SmsIcon />
          </IconButton>
          <Typography variant="caption" align="center" color='black' sx={{ marginTop: '-5px' }}>Chat</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',  mr:5 }}>
          <IconButton component={Link} to="/notifications" color="inherit" style={{ textDecoration: 'none', color: 'black' }}>
            <NotificationsActiveIcon />
          </IconButton>
          <Typography variant="caption" align="center" color='black' sx={{ marginTop: '-5px' }}>Notifications</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',  mr:5 }}>
          <IconButton component={Link} to="/landing/business" color="inherit" style={{ textDecoration: 'none', color: 'black' }}>
            <BusinessIcon />
          </IconButton>
          <Typography variant="caption" align="center" color='black' sx={{ marginTop: '-5px' }}>For Business</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',  mr:5 }}>
          <IconButton color="inherit" onClick={() => onLogOut()} style={{ textDecoration: 'none', color: 'black' }}>
             <ExitToAppIcon />
          </IconButton>
          <Typography variant="caption" align="center" color='black'>Sign Out</Typography>
        </Box>
       
      </Box>
      
      </Box>
  );

  const loggedOutHeader = (
    <>
     <Stack direction="row" spacing={2}>

     <Button  component={Link} to="/auth/signin" style={{ textDecoration: 'none', color: 'black' }}>
        For Business
      </Button>

      <Button variant="outlined" component={Link} to="/auth/signin" style={{ textDecoration: 'none', color: 'blue' }}>
        Sign In
      </Button>
      {/* Add a SignUp button */}
     
      <Button variant="contained" disableElevation component={Link} to="/signup" style={{ textDecoration: 'none', color: 'white' }}>
        Join
      </Button>
      </Stack>
      
    </>
  );

  if(loading) {
    return ('')
  }



  return (
    <React.Fragment>
      <AppBar position="fixed" sx={{ backgroundColor: 'white', boxShadow: 0, mb:5 }}>
        <Toolbar   >
          <Link to="/" style={{ flexGrow: 1 }}>
            <img
              src="/assets/revfer/skyblue.svg" 
              alt="Revfer Logo"
              style={{ width: '100px', height: '50px' }} // Adjust the dimensions as needed
            />
          </Link>
          {authStatus === 'authenticated' ? loggedInHeader : loggedOutHeader}
        </Toolbar>
      </AppBar>
     
    </React.Fragment>
  );
};

export default Header;
