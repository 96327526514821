import React from 'react';
import { Container, Box, Link, IconButton, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#F8F9F9', // White background color
        
        padding: '20px 0', // Padding for spacing
        textAlign: 'center',
      }}
    >
      <Container sx={{ maxWidth: '100%', overflowX: 'auto' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <Link
            href="/about"
            color="inherit"
            sx={{
              mr: 1,
              mt: 1,
              fontSize: '0.875rem',             
              lineHeight: 1, // Remove extra line height
            }}
            style={{ textDecoration: 'none'}}
          >
            About
          </Link>
          <Link
            href="/help-center"
            color="inherit"
            sx={{
              mr: 1,
              mt: 1,
              fontSize: '0.875rem',
              paddingX: 0, // Remove horizontal padding
              lineHeight: 1, // Remove extra line height
            }}
            style={{ textDecoration: 'none'}}
          >
            Help Center
          </Link>
          <Link
            href="/privacy"
            color="inherit"
            sx={{
              mr: 1,
              mt: 1,
              fontSize: '0.875rem',
              paddingX: 0, // Remove horizontal padding
              lineHeight: 1, // Remove extra line height
            }}
            style={{ textDecoration: 'none'}}
          >
            Privacy
          </Link>
          <Link
            href="/legal"
            color="inherit"
            sx={{
              mr: 1,
              mt: 1,
              fontSize: '0.875rem',
              paddingX: 0, // Remove horizontal padding
              lineHeight: 1, // Remove extra line height
            }}
            style={{ textDecoration: 'none'}}
          >
            Legal
          </Link>
          <Link
            href="/blogs"
            color="inherit"
            sx={{
              mr: 1,
              mt: 1,
              fontSize: '0.875rem',
              paddingX: 0, // Remove horizontal padding
              lineHeight: 1, // Remove extra line height
            }}
            style={{ textDecoration: 'none'}}
          >
            Blogs
          </Link>

          <Link
            href="/apps"
            color="inherit"
            sx={{
              mr: 1,
              mt: 1,
              fontSize: '0.875rem',
              paddingX: 0, // Remove horizontal padding
              lineHeight: 1, // Remove extra line height
            }}
            style={{ textDecoration: 'none'}}
          >
            Apps
          </Link>
          <Link
            href="/social"
            color="inherit"
            sx={{
              mr: 1,
              mt: 1,
              fontSize: '0.875rem',
              paddingX: 0, // Remove horizontal padding
              lineHeight: 1, // Remove extra line height
            }}
            style={{ textDecoration: 'none'}}
          >
            Social
          </Link>
        </Box>
        
      
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <img
              src="/assets/revfer/skyblue.svg" 
              alt="Revfer Logo"
              style={{ width: '30px', height: '15px' }} // Adjust the dimensions as needed
            />
          <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
             Revfer &copy; 2023
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
