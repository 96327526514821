// components

import Hero from './hero';
import About from './about';
import Features from './features';
import IntegrateSDK from './sdk';
import Stats from './stats';
import Reading from './reading';



const StartBusinessPage = () => {

  return (
    <>
      
      
        <Hero />
        <About />
        <Features />
        <IntegrateSDK />
        <Stats />
        
     
    </>
  );
}
  
  


export default StartBusinessPage;
