import React, { useState } from 'react';
import { Box, Button, Grid, Typography, Paper } from '@mui/material';
import UserPhoto from '../../Components/UserPhoto/UserPhoto'; // Import UserPhoto component
import { Link } from 'react-router-dom'; // Import Link from React Router
import * as Utils from '../../Functions/Utilities'
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import TextCard from "../../Components/ByteSize/TextCards/TextCard";


const CommonFromNetwork = ({ skill }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const friendsPerPage = 8;
  console.log('CommonFromNetwork skill', skill)

  


  const myCurrentNetworkList = useSelector((state) => state.auth.user.myCurrentNetworkList);
  const commonFromMyNetwork = Utils.getUsersFromMyNetworkForBusinesses(myCurrentNetworkList, skill);

  //console.log('commonFromMyNetwork', commonFromMyNetwork)  


  // Calculate the indexes of the friends to display on the current page
  const indexOfLastFriend = currentPage * friendsPerPage;
  const indexOfFirstFriend = indexOfLastFriend - friendsPerPage;
  //const currentFriends = skill.endorsements.slice(indexOfFirstFriend, indexOfLastFriend);
  const isEmpty = !commonFromMyNetwork.length

  //const totalPages = Math.ceil(skill.endorsements.length / friendsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if(isEmpty) {
    return (
      <Box border={0} borderRadius="borderRadius" p={0} >
        <Typography variant="subtitle2" sx={{ fontWeight: '800' }}>
        </Typography>
        <TextCard type={'BuildNetwork'} />

      </Box>

    )
  }

  return (
     <Paper variant="outlined" sx={{ padding: 1, border: 'none'}} >
      <Typography variant="subtitle2" sx={{ fontWeight: '800' }}>
      Network Affiliation
      </Typography>
      <Box border={0} p={4} borderRadius="borderRadius">
        <Grid container spacing={2}>

        <AvatarGroup total={commonFromMyNetwork.length}>

        {commonFromMyNetwork.map((friend) => {
            
            return (
              <Grid item key={friend.userId} xs={12} sm={6} md={6} lg={6}>
                <Link to={`/profile/${friend.id}`} >
                  <Avatar alt="Remy Sharp" src={friend.profileImageUrl} />
                </Link>
              </Grid>
            );
          })}
       
        </AvatarGroup>

        </Grid>
        
      </Box>
    </Paper>
  );
};

export default CommonFromNetwork;
