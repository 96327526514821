import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, Stack, ClickAwayListener } from '@mui/material';
import { useReportBusinessMutation } from '../../redux/thunks/sp/reportbusiness';

const ReportBusiness = ({ isOpen, onClose, businessId }) => {
  const [reason, setReason] = useState('');
  const [selectedReason, setSelectedReason] = useState('');
  const [customReasonOpen, setCustomReasonOpen] = useState(false);
  const [postReport, { isLoading }] = useReportBusinessMutation();

  const token = useSelector((state) => state.auth.token);



  const reasons = [
    'Inappropriate Content',
    'Spam',
    'Fraud',
    'Other (Please specify)',
  ];

  const handleReport = async () => {
    console.log('Testing the reporting, businessId:', businessId);
    //console.log('Testing the reporting, selectedReason:', selectedReason);

    let selectedValue;

    if (customReasonOpen) {
        selectedValue = reason;
    } else {
        selectedValue = selectedReason;
    }
    console.log('Selected Value:', selectedValue);

    // Implement the logic to submit the report
    // You can access the 'reason' and 'selectedReason' states here
    // Close the dialog when done
    let auth = {
        token: token,
        authType: 'token',
      };
    const payload = {
        auth,
        text: selectedValue,
        businessId
      };

    await postReport({ payload });
    onClose();
  };

  const handleCancel = () => {
    // Close the dialog when the "Cancel" button is clicked
    onClose();
  };

  const handleReasonOptionClick = (reasonOption) => {
    setSelectedReason(reasonOption);

    if (reasonOption === 'Other (Please specify)') {
      setCustomReasonOpen(true);
    } else {
      setCustomReasonOpen(false);
      setReason(''); // Clear the custom reason input
    }
  };

  const handleCustomReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleClickAway = (e) => {
    // Close the custom reason input when clicking outside the form
    if (!e.target.closest('.custom-reason-input')) {
      setCustomReasonOpen(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6">Report Business</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="subtitle2">Select a reason for reporting:</Typography>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Stack spacing={1}>
              {reasons.map((reasonOption) => (
                <label key={reasonOption}>
                  <input
                    type="radio"
                    name="reportReason"
                    value={reasonOption}
                    checked={selectedReason === reasonOption}
                    onChange={() => handleReasonOptionClick(reasonOption)}
                  />
                  {reasonOption}
                </label>
              ))}
            </Stack>
          </ClickAwayListener>
          {customReasonOpen && (
            <TextField
              fullWidth
              label="Specify the reason"
              variant="outlined"
              value={reason}
              onChange={handleCustomReasonChange}
              className="custom-reason-input" // Add a custom class to identify the input
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReport} variant="contained" color="primary">
          Report
        </Button>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportBusiness;
