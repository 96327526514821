
import {userApi} from './user'


export const userCacheDataApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserCacheData: builder.query({
      query: (cacheId) => `/cache/${cacheId}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useLazyGetUserCacheDataQuery, usePrefetch, useGetUserCacheDataQuery   } = userCacheDataApi