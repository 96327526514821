
import {userApi} from './user'


export const userChatListApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserChatList: builder.query({
      query: (userId) => `/chat/userlist/${userId}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetUserChatListQuery, usePrefetch } = userChatListApi