
import {userApi} from './user'

export const userEmailInviteApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    
    emailInvite: builder.mutation({      
      query: ( payload) => ({
        url: `/emailinvites`,
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useEmailInviteMutation } = userEmailInviteApi