const UaClaimContent = `
<h2>User Agreement for Claiming Unclaimed Businesses on Revfer</h2>

<p>This User Agreement ("Agreement") is entered into between you ("User" or "You") and Revfer, a platform that allows users to claim unclaimed businesses and connect with potential consumers. By using Revfer and claiming unclaimed businesses, you agree to comply with the terms and conditions set forth in this Agreement.</p>

<h3>Acceptance of Terms</h3>

<p>By accessing and using the Revfer platform and claiming unclaimed businesses, you acknowledge that you have read, understood, and agree to be bound by this Agreement. If you do not agree with any part of this Agreement, you may not use the Revfer platform to claim businesses.</p>

<h3>Claiming Unclaimed Businesses</h3>

<p>Revfer provides users with the ability to claim businesses that are unclaimed on our platform. By claiming a business, you assert that you have a legitimate interest in managing or representing that business. Revfer may require you to provide proof of your association with the business.</p>

<h3>Responsibility for Information</h3>

<p>When claiming an unclaimed business, you are responsible for ensuring that all information provided is accurate and up-to-date. Misrepresenting your association with a business or providing false information may result in the termination of your access to the platform.</p>

<h3>User Conduct</h3>

<p>You agree to use the Revfer platform and claim businesses in a lawful and ethical manner. You will not engage in any fraudulent, deceptive, or harmful activities on the platform.</p>

<h3>Intellectual Property</h3>

<p>Any content you submit to the platform, including business information, images, and reviews, must not infringe on the intellectual property rights of others. Revfer respects intellectual property rights and expects its users to do the same.</p>

<h3>Privacy</h3>

<p>Revfer values your privacy and collects and uses your personal information in accordance with our Privacy Policy. By using the platform, you consent to the collection and use of your personal information as described in the Privacy Policy.</p>

<h3>Termination</h3>

<p>Revfer reserves the right to terminate or suspend your access to the platform at its sole discretion, without notice, for any reason, including but not limited to a violation of this Agreement.</p>

<h3>Modifications to Agreement</h3>

<p>Revfer may update or modify this Agreement at any time. You are responsible for reviewing this Agreement regularly to ensure compliance with the most current version.</p>

<h3>Disclaimer of Warranties</h3>

<p>Revfer provides the platform "as is" and makes no warranties or representations regarding the accuracy, reliability, or availability of the platform or its content.</p>

<h3>Limitation of Liability</h3>

<p>Revfer shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of the platform.</p>

<h3>Governing Law</h3>

<p>This Agreement shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.</p>

<h3>Contact Information</h3>

<p>If you have any questions or concerns regarding this Agreement or the Revfer platform, please contact us at [Contact Email Address].</p>

<p>By using the Revfer platform to claim unclaimed businesses, you agree to be bound by the terms and conditions of this Agreement. If you do not agree with any part of this Agreement, please refrain from using the platform for claiming businesses. Your use of the platform constitutes acceptance of these terms.</p>
`;

export default UaClaimContent;
