import React, {useEffect} from 'react';
import { Grid, Paper, CircularProgress, Box, Container } from '@mui/material';
import RequestDetail from './RequestDetail';
import ReviewDetails from './ReviewDetails';


import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import UserInfoSection from '../SmartHome/UserInfoSection';



import { useSpFindMutation  } from '../../redux/thunks/sp/spfind';
import SearchComponent from "../../Components/SearchComponent/SearchComponent";
import { useGetUrlRequestQuery  } from '../../redux/thunks/urls/geturlrequest';




const fakeRequest = {
  title: 'Sample Request',
  description: 'This is a sample request description.',
  // Add more request details here
};

const fakeReviews = [

];

const RequestDetailPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { requestId } = useParams();
    // const stateParams = location.state;
    // console.log('State parameters:', stateParams);
    console.log('Request ID from params:', requestId);

    
    const token = useSelector((state) => state.auth.token) 
    const [triggerSearch, { data: searchResults, isLoading: isSearchLoading }] = useSpFindMutation(); 

    const args = {
      requestId: requestId,
      authType: 'token',
      token: token
    }
    const { data: urlRequestData, error: urlRequestError, isLoading: urlRequestLoading } = useGetUrlRequestQuery(args);

    useEffect(() => {
        if (searchResults) {
          navigate(`/search/providers/results`, { state: { searchResults } });
        }
    }, [searchResults, navigate]);

    console.log('urlRequestData:', urlRequestData);

   

    

    const handleSearch = (searchText, tabValue) => {
        // Handle the search with searchText and tabValue
        console.log('Search Text:', searchText);
        console.log('tabValue:', tabValue);
        let auth = {      
            token:token,
            authType: 'token'
        }
        const payload = {
            auth,
            text: searchText
        };
        
        triggerSearch({payload});
    };

    if (isSearchLoading || urlRequestLoading ) {
        // Show a loading spinner if loading
        return (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress color="primary" />
          </Box>
    
        )
    }
  return (
    <Container maxWidth="xl">
        <Paper variant="outlined" sx={{ marginBottom: '0px',  border: 'none', }}>
          
          <SearchComponent onSearch={handleSearch} />
          
        </Paper>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={4} md={2}>
            {/* Left column with bio information (You can add this later) */}
                <Paper >
                {/* Add bio information here */}
                    <UserInfoSection />
                </Paper>
            </Grid>
            <Grid item xs={12} sm={8} md={7}>
                {/* Middle column with request details */}
                <Paper variant="outlined" sx={{ padding: '10px', mb: 2 }}>
                  <RequestDetail request={urlRequestData} />
                </Paper>
                {/* Render all reviews */}
                {urlRequestData.reviews.length > 0 ? (
                    urlRequestData.reviews.map((review, index) => (
                         <Paper variant="outlined" sx={{ border: 'none' }}  key={index}>
                            <ReviewDetails review={review} />
                         </Paper>
                    ))) : (
                        <Paper variant="outlined" sx={{mb: 1}}>
                            No reviews yet
                         </Paper>
                    )}
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                {/* Right column (You can add content here if needed) */}
                <Paper>
                {/* Add content here */}
                </Paper>
            </Grid>

        </Grid>
      
    </Container>
  );
};

export default RequestDetailPage;
