import React, { useState, useEffect, useCallback } from 'react';
import UserPhoto from '../../../Components/UserPhoto/UserPhoto';
import { Chip, Box, LinearProgress, Typography, Tooltip, Stack, Divider } from '@mui/material';
import Rating from '@mui/material/Rating';
import { useNavigate } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useSelector } from 'react-redux';
import 'react-circular-progressbar/dist/styles.css';
import { usePrefetch as useGetSpProfilePrefetch } from '../../../redux/thunks/sp/getspprofile';
import { usePrefetch as useGetSpReviewPrefetch } from '../../../redux/thunks/sp/spreviewget';



const StarRating = ({ rating }) => {
  return (
    <Rating
      name="custom-rating"
      value={rating} // Use the value prop to set the rating value
      precision={0.5}
      readOnly
    />
  );
};

const Provider = ({ result, cacheId }) => {
  const navigate = useNavigate();
  const totalReviews = 111;
  const minimumPercentage = 1;
  const token = useSelector((state) => state.auth.token)
  //console.log('in provider search provider result', result)
  //console.log('in provider search provider result.spId', result.spId)
  const prefetchGetSpProfile = useGetSpProfilePrefetch('getSpProfile')
  const prefetchGetSpReview = useGetSpReviewPrefetch('getSpReview')

  let initials = result.displayName ? `${result.displayName.split(' ')[0][0].toUpperCase()}${result.displayName.split(' ')[1][0].toUpperCase()}` : "RV";
  const businessName = result.businessName.replace(/\b\w/g, (char) => char.toUpperCase());

  const args = {
    spId: result.spId,
    authType: 'token',
    token: token,
  
  }
  const revArgs = {
    spId: result.spId || '123456789',
    authType: 'token',
    token: token,
    businessId: result._id
  }

  const handleNavigation = () => {
    // Define navigation logic for endorsed activity (e.g., go to an endorsement details page)
  };

  const handleNavigation2Profile = (id) => {
    // Define navigation logic for endorsed activity (e.g., go to profile page)
    navigate(`/provider/profile/${id}`, { state: { businessId: result._id, businessName: result.businessName, skill: result, cacheId } });
  };

  // Define calculateProgressValues as a memoized function
  const calculateProgressValues = useCallback(() => {
    let totalReviews = result.count_all;
    let connectedReviews = result.count_token - result.count_hops[0] || 0;
    let notConnectedReviews = result.count_hops[0] || 0;
    let anonymousReviews = result.count_none;    

    // Calculate percentages with a minimum of 1%
    const greenPercentage = Math.max((connectedReviews / totalReviews) * 100, minimumPercentage);
    const yellowPercentage = Math.max(((notConnectedReviews) / totalReviews) * 100, minimumPercentage);
    const redPercentage = Math.max((anonymousReviews / totalReviews) * 100, minimumPercentage);

    return {
      redPercentage,
      greenPercentage,
      yellowPercentage,
      totalReviews,
    };
  }, [result]); // Add results to the dependency array

  const [progressValues, setProgressValues] = useState(calculateProgressValues());

  useEffect(() => { 
          
    const prefetchAll = async () => {    
     
      await Promise.all([
      
        prefetchGetSpProfile(args),
        prefetchGetSpReview(revArgs)
      
      
        
      ]);        
    }    
    prefetchAll();
      
        

    
  }, [result]);

  useEffect(() => {
    const calculatedProgressValues = calculateProgressValues();
    setProgressValues(calculatedProgressValues);
  }, [result, calculateProgressValues]);

  // State variable to manage the rating value
  const [ratingValue, setRatingValue] = useState(result.avgSkillRating_all);

  return (
    <Box sx={{ width: '100%', height: 'auto', bgcolor: 'white', borderRadius: 2, p: 2, my: 0 }}>
      {/* First div (80% height) */}
      <Box sx={{ display: 'flex', height: '80%' }}>
        {/* First column (20% width) */}
        <Box sx={{ flex: '10%', p: 1 }}>
          <UserPhoto photoUrl={result.photoUrl} name={businessName} size={80} />
        </Box>

        {/* Second column (60% width) */}
        <Box sx={{ flex: '40%', textAlign: 'left' }}>
          <Typography variant="h6" fontWeight="bold" mb={1} sx={{cursor: 'pointer'}} onClick={() => handleNavigation2Profile(result.spId)}>
            {businessName} 
          </Typography>
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <UserPhoto photoUrl={result.profileImageUrl} name={result.spDisplayName} size={16} fontSize={9} />
              <Typography variant="body2" fontWeight="bold" ml={1} onClick={() => handleNavigation2Profile(result.spId)}>
                {result.spDisplayName}
              </Typography>

            </Box>
            
            <Typography variant="body2" fontWeight="bold" mb={1} onClick={() => handleNavigation2Profile(result.spId)}>
              {result.skillName}
            </Typography>

          </Stack>
          
          <Box sx={{ display: 'flex', alignItems: 'center', mb: -2 }}>
          <Chip
              label={result.authorizedPersonal ? 'Claimed' : 'Unclaimed'}
              color={result.authorizedPersonal ? 'success' : 'default'}
              sx={{ mr: 1 }}
              size="small"
            />
            {result.authorizedPersonal &&
              <Chip
              label={result.verified ? 'Verified' : 'Unverified'}
              color={result.verified ? 'success' : 'default'}
              sx={{ mr: 1 }}
              size="small"
              />
            
            }
            <Box sx={{ ml: 2, mb: 2 }}>
              <Typography variant="caption" fontWeight="bold" mb={1} >
                {result.region || 'Unknown'}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StarRating rating={ratingValue} />
            <Box sx={{ ml: 2, mb: 1 }}>
              <Typography variant="caption" fontWeight="bold" mb={1} >
                • Total Reviews: {progressValues.totalReviews}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Third column (20% width) */}
        <Box sx={{ flex: '20%', p: 1 }}>
          <Box display={'flex'}>
            <Box>
              <Typography variant="subtitle2" sx={{ mb: 0 }}>
                Reference Stats
              </Typography>
            
              <Box  sx={{ width: 50, height: 50 }}>                
                    <CircularProgressbar 
                      value={progressValues.greenPercentage} 
                      text={`${Math.floor(progressValues.greenPercentage)}%`}
                      styles={buildStyles({
                      
                        textSize: "34px",
                        pathColor: 'blue',
                      })}            
                      
                      />                   
                  
              </Box>  

            </Box>       

             <Box>
              <Typography variant="subtitle2" sx={{ mt: 0 }}>
                Smart Rating
              </Typography>
              <Tooltip title="Refer's Smart Value System - Learn More" arrow>
                <a href="https://www.revfer.com/support" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Box  sx={{ width: 50, height: 50 }}>
                  
                  <CircularProgressbar 
                    value={result.SkillValue_all} 
                    text={`${Math.floor(result.SkillValue_all)}%`}
                    styles={buildStyles({
                    
                      textSize: "34px",
                      pathColor: result.SkillValue_all > 80 ? 'blue' : result.SkillValue_all > 50 ? 'yellow' : 'red',
                    })}            
                    
                    />
                    
                    
                
                </Box>  
                </a>
              </Tooltip>         
          
          </Box>       


          </Box>

         

           
          
            
           
        </Box>
      </Box>

   
    </Box>
  );
};

export default Provider;
