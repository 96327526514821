import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const UrlFilters = ({ results, setFilteredResults }) => {
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedName, setSelectedName] = useState('');


 



  useEffect(() => {
    // Filter results based on selectedCompany and selectedName
    let filtered = [...results];
    console.log('filtered', filtered)

    if (selectedCompany !== 'others' && selectedCompany) {
      filtered = filtered.filter((result) => result.company?.Text === selectedCompany);
    }
    console.log('filtered1', filtered)

    if (selectedName !== 'others' && selectedName) {
      filtered = filtered.filter((result) => result.name?.Text === selectedName);
    }
    console.log('filtered2', filtered)

    setFilteredResults(filtered);
  }, [selectedCompany, selectedName, results, setFilteredResults]);

  // Get top 4 companies with the most appearances
  const topCompanyOptions = results
    .filter((result) => result.company?.Text)
    .reduce((options, result) => {
      const companyText = result.company.Text;
      options[companyText] = (options[companyText] || 0) + 1;
      return options;
    }, {});
  const top4CompanyOptions = Object.keys(topCompanyOptions)
    .sort((a, b) => topCompanyOptions[b] - topCompanyOptions[a])
    .slice(0, 4);

  // Create an "others" option for companies
  const otherCompanies = results
    .filter((result) => result.company?.Text && !top4CompanyOptions.includes(result.company.Text))
    .map((result) => result.company.Text);

  // Get top 4 names with the most appearances
  const topNameOptions = results
    .filter((result) => result.name?.Text)
    .reduce((options, result) => {
      const nameText = result.name.Text;
      options[nameText] = (options[nameText] || 0) + 1;
      return options;
    }, {});
  const top4NameOptions = Object.keys(topNameOptions)
    .sort((a, b) => topNameOptions[b] - topNameOptions[a])
    .slice(0, 4);

  // Create an "others" option for names
  const otherNames = results
    .filter((result) => result.name?.Text && !top4NameOptions.includes(result.name.Text))
    .map((result) => result.name.Text);

  return (
    <Paper variant="outlined" sx={{ padding: 1, borderRadius: 2 }}>
      <Typography variant="h6">Filters</Typography>
      <FormControl component="fieldset">
        <Typography variant="subtitle1">Company</Typography>
        <RadioGroup
          aria-label="company"
          name="company"
          value={selectedCompany}
          onChange={(e) => setSelectedCompany(e.target.value)}
        >
          {top4CompanyOptions.map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio />}
              label={option}
            />
          ))}
          {otherCompanies.length > 0 && (
            <FormControlLabel
              value="others"
              control={<Radio />}
              label="Others"
            />
          )}
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset">
        <Typography variant="subtitle1">Name</Typography>
        <RadioGroup
          aria-label="name"
          name="name"
          value={selectedName}
          onChange={(e) => setSelectedName(e.target.value)}
        >
          {top4NameOptions.map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio />}
              label={option}
            />
          ))}
          {otherNames.length > 0 && (
            <FormControlLabel
              value="others"
              control={<Radio />}
              label="Others"
            />
          )}
        </RadioGroup>
      </FormControl>
    </Paper>
  );
};

export default UrlFilters;
