// providerData.js

export const TextCardData = [
    {
      type: 'SeekBusinessReviews',      
      description: 'Looking to review a business that is not on Revfer yet? You can create one here.',      
      buttonLabel: 'Create Page',
      buttonLink: '/company/setup/solicit', 
    },

    {
      type: 'SeekProductReview',      
      description: 'Found a product you are looking to have reviewed? You can do it here.',      
      buttonLabel: 'Create Page',
      buttonLink: '/thing/create', 
    },
    {
      type: 'StartBusiness',      
      description: 'If you are a business owner or an authorized representative and want to set up a page for your business, you can do it here.',      
      buttonLabel: 'Create Page',
      buttonLink: '/company/setup/new', 
    },
    {
      type: 'BuildNetwork',      
      description: 'Expand your network to find users endorsing or following businesses.',      
      buttonLabel: 'My Network',
      buttonLink: '/mynetwork', 
    },
    
    // Add more provider types as needed
  ];
  