import React from 'react';
import {
  Box,
  Chip,
  Typography,
  Avatar,
  Rating,
  Grid,
} from '@mui/material';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryLabel,
  VictoryLine,
} from 'victory';
import StarIcon from '@mui/icons-material/Star';
import VerifiedIcon from '@mui/icons-material/Verified';
import InsightsIcon from '@mui/icons-material/Insights';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';



const SkillStats = ({ skill }) => {
  if (!skill) {
    return null; // Return null if skill or spId is undefined
  }
  // Hard-coded stats for each category
  console.log('in skill stats, skill', skill);
  


  // Example data for Trust Score Graph
  const trustScoreData = [
    { name: '1', score: 90 },
    { name: '2', score: 81 },
    { name: '3', score: 63 },
    { name: '4', score: 32 },
  ];
  const interactionStats = {
    reviewsShared: 50,
    responseTime: '2 hours',
    feedbackGiven: 75,
  };
  const achievementsBadges = [
    '1000 Reviews',
    'Highly referred',
    'Top 90%'
  ]

  const trustMetricsStats = {
    trustRating: 4.5,
    trustScoreGraph: 'Your score graph data here',
  };

  return (
    <div>
      {/* Category 1: Review Contributions */}
      <Box sx={{ mb: 4, textAlign: 'left' }}>
        <Typography variant="h6">Overall Stats</Typography>
        <Rating name="read-only" value={skill.avgSkillRating_all} readOnly />
        
        <Grid container alignItems="center" mb={1} mt={4}>      
          
          <StarIcon color="primary" sx={{ fontSize: 20, mr:1 }} />
          <Typography variant="body2" fontWeight="bold">
            Reviews: {skill.count_all}
          </Typography>
        </Grid>
        <Grid container alignItems="center" mb={1}>
          <VerifiedIcon color="primary" sx={{ fontSize: 20, mr:1 }} />
          
          <Typography variant="body2" fontWeight="bold">
            Endorsements: {skill.endorsements.length}
          </Typography>
        </Grid>
        <Grid container alignItems="center" mb={1}>
          <InsightsIcon color="primary" sx={{ fontSize: 20, mr:1 }}  />
          <Typography variant="body2" fontWeight="bold">
            Estimated Level: {Math.round(skill.SkillValue_all)}%
          </Typography>
        </Grid>
      </Box>

      {/* Category 2: Network Engagement */}
      <Box sx={{ mb: 4, textAlign: 'left' }}>
        <Typography variant="caption">Connected Reviews</Typography>
        <div style={{ width: '200px', height: '200' }}>
          <VictoryChart
            height={300}
            padding={{ left: 70, right: 60, top: 20, bottom: 30 }}
          >
            <VictoryAxis
              style={{ axis: { stroke: '#100f1c' }, ticks: { stroke: 'none' }, tickLabels: { fontSize: 20, padding: 5 } }}
            />
            <VictoryBar
              data={[
                
                { name: 'Not Connected', count: 35 },
                { name: 'Connected', count: 20 },
              ]}
              barRatio={1}
              x="name"
              y="count"
              style={{
                data: { fill: '#8884d8' },
                labels: { fill: '#100f1c', fontSize: 15 },
              }}
              labels={({ datum }) => `${datum.count}`}
              labelComponent={<VictoryLabel dy={30} />}
            />
          </VictoryChart>
        </div>
      </Box>

      {/* Category 3: Interaction Stats */}
      <Box sx={{ mb: 4, textAlign: 'left' }}>
        <Typography variant="caption">User Interaction</Typography>
        <Grid container alignItems="center" mb={1} mt={3}>
          <VisibilityIcon color="primary" sx={{ fontSize: 20, mr:1 }}  />
          <Typography variant="body2" fontWeight="bold">
            Views: {interactionStats.reviewsShared}
          </Typography>
        </Grid>
        <Grid container alignItems="center" mb={1}>
          <ThumbUpIcon color="primary" sx={{ fontSize: 20, mr:1 }}  />
          <Typography variant="body2" fontWeight="bold">
            Reviews Liked: {interactionStats.reviewsShared}
          </Typography>
        </Grid>
      </Box>

      {/* Category 4: Expertise and Recognition */}
      <Box sx={{ mb: 4, textAlign: 'left' }}>
        <Typography variant="caption">Expertise and Recognition</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {achievementsBadges.map((category, index) => (
            <Chip
              key={index}
              label={category}
              color="primary"
              variant="outlined"
              sx={{ mr: 1, mb: 1 }}
            />
          ))}
        </Box>
      </Box>

      {/* Category 5: Trust Metrics */}
      <Box sx={{ mb: 4, textAlign: 'left' }}>
        <Typography variant="caption">AI Powered Sentiments</Typography>
        <Typography>Trust Rating: {trustMetricsStats.trustRating}</Typography>
        <Typography variant="caption" fontWeight="bold" mt={4}>
          Trust Score and Connection Level
        </Typography>

        {/* Trust Score Graph */}
        <VictoryChart height={300} padding={{ left: 0, right: 20, top: 20, bottom: 30 }} domain={{ x: [0, 7], y: [0, 100] }}>
          {/* X-axis */}
          <VictoryAxis tickLabelComponent={<VictoryLabel style={{ fontSize: 16 }} />} />
          {/* Y-axis with hidden tick labels */}
          <VictoryAxis dependentAxis style={{ tickLabels: { display: 'none' } }} />
          {/* VictoryLine for the data */}
          <VictoryLine
            data={trustScoreData}
            x="name"
            y="score"
            style={{ data: { stroke: '#82ca9d' } }}
            // Annotate Y-axis data on the line graph
            labels={({ datum }) => `${datum.score}`}
            labelComponent={<VictoryLabel dx={-10} dy={-10} verticalAnchor="end" textAnchor="start" style={{ fontSize: 16 }} />}
          />
        </VictoryChart>
      </Box>
    </div>
  );
};

export default SkillStats;
