import React, {useEffect} from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import UserPhoto from '../../Components/UserPhoto/UserPhoto';
import { usePrefetch as useGetSpProfilePrefetch } from '../../redux/thunks/sp/getspprofile';
import { usePrefetch as useGetSpReviewPrefetch } from '../../redux/thunks/sp/spreviewget';
import { usePrefetch as useGetBusinessByIdPrefetch } from '../../redux/thunks/unauth/getbusinessbyid';
import {useGetBusinessByIdQuery} from '../../redux/thunks/unauth/getbusinessbyid'


const EndorsementList = ({ endorsements }) => {
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.token)

  const prefetchGetSpProfile = useGetSpProfilePrefetch('getSpProfile')
  const prefetchGetBusinessById = useGetBusinessByIdPrefetch('getBusinessById')
  const prefetchGetSpReview = useGetSpReviewPrefetch('getSpReview')
  console.log('endorsements', endorsements)
  console.log('token', token)

  
 

 
  useEffect(() => {
    if (endorsements.length > 0) {
      // Loop through currentFriends and prefetch their profiles
      endorsements.forEach((business) => {
        let args = {
          
          authType: 'token',
          token: token,
          title: business.name,
          businessId: business.businessId
        }
        // Call the prefetch function for each user profile
        //prefetchGetSpProfile(args);
        prefetchGetBusinessById(args)
        //prefetchGetSpReview(args);
      });
    }
  }, [endorsements]);


  const handleNavigation = (businessId, name ) => {
    // Define navigation logic for review activity (e.g., go to an endorsement details page)
    //navigate(`/provider/profile/${spId}`, { state: { skillId: skillId, skillName: skillName } });
    navigate(`/business/${businessId}/${name}`);
  };

  return (
    <div>
      <Typography variant="subtitle1" sx={{ fontWeight: '800' }}>Endorsements</Typography>
      {endorsements.map((endorsement) => (
        <Box
          key={endorsement.businessId}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 2,
            borderBottom: '1px solid #e0e0e0',
          }}
        >
          
          <UserPhoto photoUrl={endorsement.photoUrl} initials={'RV'} size={60} />
          <Box sx={{ml:1      }}>
            <Typography variant="subtitle1">{endorsement.name}</Typography>
            <Box sx={{display:"flex", mb:1}}> 
            
            <Typography variant="body2" style={{display: 'inline'}}>{endorsement.name}</Typography>
            </Box>
            
            
            <Typography variant="body2">             
            </Typography>
            <Button 
              size="small" 
              variant="outlined" 
              color="primary" 
              sx={ { borderRadius: 28 }} 
              onClick={() => handleNavigation(endorsement.businessId, endorsement.name)}
            >
              See Details
            </Button>
          </Box>
        </Box>
      ))}
    </div>
  );
};

export default EndorsementList;
