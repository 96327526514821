
import {spApi} from './sp'

export const claimBusinessApi = spApi.injectEndpoints({
  endpoints: (builder) => ({
    
    claimBusiness: builder.mutation({
      
      query: ({payload}) => ({
        url: '/claimbusiness',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useClaimBusinessMutation } = claimBusinessApi