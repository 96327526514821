import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import { Chart } from 'react-google-charts';
import StarIcon from '@mui/icons-material/Star';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PageviewIcon from '@mui/icons-material/Pageview';

const ComparativeAnalysis = () => {
  const comparativeAnalysisData = {
    // First Row: Key Metrics Comparison
    starRatingComparison: {
      yours: 4.2,
      competition: 4.0,
    },
    reviewCountComparison: {
      yours: 450,
      competition: 500,
    },
    sentimentComparison: {
      yours: 'positive',
      competition: 'negative',
    },
    responseRateComparison: {
      yours: 80, // percentage
      competition: 75, // percentage
    },
    pageViewsComparison: {
      yours: 10000,
      competition: 12000,
    },

    // Second Row: Monthly Trends
    monthlyReviewCount: [
      { month: 'Jan-2024', yours: 30, competition: 35 },
      { month: 'Feb-2024', yours: 35, competition: 40 },
      { month: 'Mar-2024', yours: 40, competition: 45 },
      { month: 'Apr-2024', yours: 50, competition: 55 },
      { month: 'May-2024', yours: 55, competition: 60 },
      { month: 'Jun-2024', yours: 60, competition: 65 },
      { month: 'Jul-2024', yours: 80, competition: 75 },
    ],
    monthlySentimentTrend: [
      { month: 'Jan-2024', yours: 1, competition: 0 },
      { month: 'Feb-2024', yours: 1, competition: -1 },
      { month: 'Mar-2024', yours: 0, competition: -1 },
      { month: 'Apr-2024', yours: 1, competition: 0 },
      { month: 'May-2024', yours: 1, competition: 0 },
      { month: 'Jun-2024', yours: 0, competition: -1 },
      { month: 'Jul-2024', yours: 1, competition: 1 },
    ],
    monthlyResponseRate: [
      { month: 'Jan-2024', yours: 75, competition: 70 },
      { month: 'Feb-2024', yours: 78, competition: 73 },
      { month: 'Mar-2024', yours: 80, competition: 75 },
      { month: 'Apr-2024', yours: 82, competition: 76 },
      { month: 'May-2024', yours: 85, competition: 78 },
      { month: 'Jun-2024', yours: 88, competition: 80 },
      { month: 'Jul-2024', yours: 90, competition: 83 },
    ],

    // Third Row: Demographic Comparisons
    ageGroupComparison: [
      { ageGroup: '18-24', yours: 100, competition: 90 },
      { ageGroup: '25-34', yours: 150, competition: 140 },
      { ageGroup: '35-44', yours: 120, competition: 130 },
      { ageGroup: '45-54', yours: 50, competition: 60 },
      { ageGroup: '55+', yours: 30, competition: 35 },
    ],
    genderDistributionComparison: {
      yours: { male: 55, female: 45 }, // percentage
      competition: { male: 50, female: 50 }, // percentage
    },
  };

  // Data preparation for charts
  const reviewCountData = [
    ['Month', 'Your Business', 'Competition'],
    ...comparativeAnalysisData.monthlyReviewCount.map(data => [
      data.month,
      data.yours,
      data.competition,
    ]),
  ];

  const sentimentTrendData = [
    ['Month', 'Your Business Sentiment', 'Competition Sentiment'],
    ...comparativeAnalysisData.monthlySentimentTrend.map(data => [
      data.month,
      data.yours,
      data.competition,
    ]),
  ];

  const responseRateData = [
    ['Month', 'Your Business', 'Competition'],
    ...comparativeAnalysisData.monthlyResponseRate.map(data => [
      data.month,
      data.yours,
      data.competition,
    ]),
  ];

  const ageGroupData = [
    ['Age Group', 'Your Business', 'Competition'],
    ...comparativeAnalysisData.ageGroupComparison.map(data => [
      data.ageGroup,
      data.yours,
      data.competition,
    ]),
  ];

  const genderDistributionData = [
    ['Gender', 'Your Business', 'Competition'],
    ['Male', comparativeAnalysisData.genderDistributionComparison.yours.male, comparativeAnalysisData.genderDistributionComparison.competition.male],
    ['Female', comparativeAnalysisData.genderDistributionComparison.yours.female, comparativeAnalysisData.genderDistributionComparison.competition.female],
  ];

  return (
    <Box>
      {/* First Row: Key Metrics Comparison */}
      <Grid container spacing={2} sx={{ mb: 2 }} alignItems="stretch">
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' }}>
            <CardContent sx={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                <StarIcon sx={{ fontSize: 15, marginRight: 1 }} />
                <Typography variant="caption">Star Rating</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                <Typography variant="body1">Yours: {comparativeAnalysisData.starRatingComparison.yours}</Typography>
                <Typography variant="body1">|</Typography>
                <Typography variant="body1">Competition: {comparativeAnalysisData.starRatingComparison.competition}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' }}>
            <CardContent sx={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                <RateReviewIcon sx={{ fontSize: 15, marginRight: 1 }} />
                <Typography variant="caption">Review Count</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                <Typography variant="body1">Yours: {comparativeAnalysisData.reviewCountComparison.yours}</Typography>
                <Typography variant="body1">|</Typography>
                <Typography variant="body1">Competition: {comparativeAnalysisData.reviewCountComparison.competition}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' }}>
            <CardContent sx={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                <SentimentVerySatisfiedIcon sx={{ fontSize: 15, marginRight: 1 }} />
                <Typography variant="caption">Sentiment</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                <Typography variant="body1">Yours: </Typography>
                <SentimentVerySatisfiedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                <Typography variant="body1">|</Typography>
                <Typography variant="body1">Competition: </Typography>
                <SentimentNeutralIcon sx={{ fontSize: 20, marginRight: 1 }} />
              </Box>
              
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' }}>
            <CardContent sx={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                <AccessTimeIcon sx={{ fontSize: 15, marginRight: 1 }} />
                <Typography variant="caption">Response Rate</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                <Typography variant="body1">Yours: {comparativeAnalysisData.responseRateComparison.yours}%</Typography>
                <Typography variant="body1">|</Typography>
                <Typography variant="body1">Competition: {comparativeAnalysisData.responseRateComparison.competition}%</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' }}>
            <CardContent sx={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                <AccessTimeIcon sx={{ fontSize: 15, marginRight: 1 }} />
                <Typography variant="caption">Response Time - hours</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                <Typography variant="body1">Yours: 10</Typography>
                <Typography variant="body1">|</Typography>
                <Typography variant="body1">Competition: 8</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' }}>
            <CardContent sx={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                <PageviewIcon sx={{ fontSize: 15, marginRight: 1 }} />
                <Typography variant="caption">Page Views</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                <Typography variant="body1">Yours: {comparativeAnalysisData.pageViewsComparison.yours}</Typography>
                <Typography variant="body1">|</Typography>
                <Typography variant="body1">Competition: {comparativeAnalysisData.pageViewsComparison.competition}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Second Row: Monthly Trends */}
      <Grid container spacing={2} sx={{ mb: 2 }} alignItems="stretch">
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="caption">Monthly Review Count</Typography>
              <Chart
                chartType="LineChart"
                data={reviewCountData}
                options={{
                  hAxis: { title: 'Month' },
                  vAxis: { title: 'Review Count' },
                  colors: ['#FF8042', '#00C49F'],
                  legend: { position: 'bottom' },
                }}
                width="100%"
                height="250px"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="caption">Monthly Sentiment Trend</Typography>
              <Chart
                chartType="ColumnChart"
                data={sentimentTrendData}
                options={{
                  hAxis: { title: 'Month' },
                  vAxis: { title: 'Sentiment Score', minValue: -1, maxValue: 1 },
                  colors: ['#00C49F', '#FFBB28'],
                  legend: { position: 'bottom' },
                }}
                width="100%"
                height="250px"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="caption">Monthly Response Rate</Typography>
              <Chart
                chartType="LineChart"
                data={responseRateData}
                options={{
                  hAxis: { title: 'Month' },
                  vAxis: { title: 'Response Rate (%)' },
                  colors: ['#00C49F', '#FFBB28'],
                  legend: { position: 'bottom' },
                }}
                width="100%"
                height="250px"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Third Row: Demographic Comparisons */}
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} sm={6}>
          <Card variant="outlined" sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="caption">Age Group Comparison</Typography>
              <Chart
                chartType="ColumnChart"
                data={ageGroupData}
                options={{
                  hAxis: { title: 'Age Group' },
                  vAxis: { title: 'Review Count' },
                  colors: ['#FF8042', '#00C49F'],
                  legend: { position: 'bottom' },
                }}
                width="100%"
                height="250px"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card variant="outlined" sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="caption">Gender Distribution Comparison</Typography>
              <Chart
                chartType="ColumnChart"
                data={genderDistributionData}
                options={{
                  hAxis: { title: 'Gender' },
                  vAxis: { title: 'Percentage (%)' },
                  colors: ['#00C49F', '#FFBB28'],
                  legend: { position: 'bottom' },
                }}
                width="100%"
                height="250px"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ComparativeAnalysis;
