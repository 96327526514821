
import {spApi} from './sp'

export const spFindRestaurantsApi = spApi.injectEndpoints({
  endpoints: (builder) => ({
    
    spFindRestaurants: builder.mutation({
      
      query: ({payload}) => ({
        url: '/findspbyrestaurants',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useSpFindRestaurantsMutation } = spFindRestaurantsApi