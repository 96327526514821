import React from 'react';
import { Card, CardContent, CardMedia, CardActions, Typography, Button, Box } from '@mui/material';
import { PhotoCardData } from './PhotoCardData'; // Import the data
import { useNavigate } from 'react-router-dom';


const PhotoCard = ({ type, size, sx }) => {

  const navigate = useNavigate();
    // Find the matching provider data based on the 'type' prop
  const cardData = PhotoCardData.find((provider) => provider.type === type);

  const handleButtonClick = () => {
    navigate(cardData.buttonLink);
    
  };
  return (
    <Card variant="outlined"
      sx={{
        maxWidth: size || '100%', // Set the card width based on the 'size' prop or 100% by default
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: 'none',
        ...sx
      }}
    >
        
      <CardMedia
        component="img"
        height="140"
        image={cardData.imageUrl}
        alt={cardData.title}
      />
      <CardContent>
      
        <Typography gutterBottom variant="h5">{cardData.title}</Typography>
        
        <Typography variant="body2" align="left">
          {cardData.description}
        </Typography>
        
      </CardContent>
      <CardActions>
        <Button onClick={handleButtonClick}  size="small">{cardData.buttonLabel}</Button>
        
      </CardActions>
    </Card>
  );
};

export default PhotoCard;
