// MultiPageForm.js
import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, Paper, Box, CircularProgress, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';


import BusinessNamePage from './BusinessNamePage';
import CategoryPage from './CategoryPage';
import SubCategoriesPage from './SubCategoriesPage';
import AddressPage from './AddressPage';
import SelectPhotoPage from '../../Components/FilesUpload/SelectPhotoPage';
import FindMeAtPage from './FindMeAtPage';
import TextCard from "../../Components/ByteSize/TextCards/TextCard";


import { usePrefetch as useGetAllSkillsPrefetch } from '../../redux/thunks/things/thingsget';
//import { usePrefetch as useGetPreSignedUrlPrefetch } from '../../redux/thunks/files/filespresignurl';
import { useThingAddMutation  } from '../../redux/thunks/things/thingadd';

const steps = ['Business Name', 'Category', 'Sub Categories', 'Address', 'Find Me At'];

const BusinessCreate = () => {

  const userId = useSelector((state) => state.auth.userId);  
  const token = useSelector((state) => state.auth.token);  
  const spId = useSelector((state) => state.auth.user.spId);  
  const displayName = useSelector((state) => state.auth.user.displayName);

 

  const prefetchGetAllSkills = useGetAllSkillsPrefetch('getAllThings') 
  //const prefetchGetPreSignedUrl = useGetPreSignedUrlPrefetch('getPreSignedUrl') 
  const [addThing, { isLoading, isError }] = useThingAddMutation();

  

  useEffect(() => {
    console.log('in application screen...start, token', token)
    console.log('in application screen...start, userId', userId)
    console.log('in application  screen...start, spId', spId)
    const prefetchAll = async () => {
      const args = {
        spId: spId,
        authType: 'token',
        token: token
      }

      await Promise.all([
        prefetchGetAllSkills(args),
        //prefetchGetPreSignedUrl()
        
      ]);
    
    }

    prefetchAll();
   
    

  }, []);



  const [skillId, setSkillId] = useState('');
  const [businessId, setBusinessId] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [skillName, setSkillName] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    businessName: displayName,
    isRepresentative: true,
    category: '',
    subCategories: [],
    address: '',
    socialLinks: [],
  });
  const isLastStep = activeStep === steps.length - 1;


  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (values) => {
    if (isLastStep) {
      try {
        // Handle form submission (e.g., send data to the server)
        const auth = {
          token: token,
          authType: 'token',
        };
      
        const payload = {
          auth,
          skillId: values.category.skillId,
          otherSkillIds: values.subCategories.map((obj) => obj.skillId),
          spId,
          data: values
        };
        const response = await addThing(payload);
        console.log('Form data submitted:', values);
        console.log('Form data response:', response);
        setBusinessId(response.data.businessId)
        setSkillId(values.category.skillId)
        setPhotoUrl(values.category.skillIconUrl)
        setSkillName(values.category.name)
        setBusinessName(response.data.businessName)

        // Set the form as submitted
        setIsFormSubmitted(true);
      } catch (error) {
         // Handle submission error
         setSubmissionError(error.message);
      }


    } else {
      setActiveStep((prevStep) => prevStep + 1);
      
    }
    
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BusinessNamePage
            formData={formData}
            setFormData={setFormData}
            
          />
        );
      case 1:
        return (
          <CategoryPage
            formData={formData}
            setFormData={setFormData}
            
          />
        );
      case 2:
        return (
          <SubCategoriesPage
            formData={formData}
            setFormData={setFormData}
            
          />
        );
      case 3:
        return (
          <AddressPage
            formData={formData}
            setFormData={setFormData}
           
          />
        );
      
      case 4:
        return (
          <FindMeAtPage
            formData={formData}
            setFormData={setFormData}
            
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {!isFormSubmitted && (
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
        </Stepper>
      )}
      <Paper variant="outlined" sx={{ m: 3, p: 3, minHeight: '400px', width: '500px', border: 'none' }}>
        {isFormSubmitted ? (
          <SelectPhotoPage skillId={skillId} photoUrl={photoUrl} skillName={skillName} businessId={businessId} businessName={businessName}/>
        ) : (
          <Formik
            initialValues={formData}
            onSubmit={(values) => {
              // Handle form submission (e.g., send data to the server)
              handleSubmit(values);
            }}
          >
            {({values}) => (
              <Form>
                {getStepContent(activeStep)}
                <Box display='flex'>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, mr: 2 }}>
                      Back
                    </Button>
                  )}
                  {isLoading ? (
                    <CircularProgress color="primary" sx={{ mt: 3 }} />
                  ) : (
                    <div>
                      <Button variant="contained" color="primary" type="submit" disabled={!values.isRepresentative} sx={{ mt: 3 }}>
                      {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                    {!values.isRepresentative &&

                    <Box display='flex' sx={{ mt: 3 }}>
                      <TextCard type={'SeekBusinessReviews'} sx={{ mr: 1, boxShadow: 'none', border: '1px solid rgba(0, 0, 0, 0.1)', borderLeft: '2px solid #0000FF' }}/>
                      <TextCard type={'SeekProductReview'} sx={{  boxShadow: 'none', border: '1px solid rgba(0, 0, 0, 0.1)', borderLeft: '2px solid #0000FF'}}/>
                    </Box>

                    
                    
                      
                    }
                    

                    </div>
                    
                  )}
                </Box>
              </Form>
              
            )}
            
          </Formik>
        )}
        {isError && <div>Error: Something went wrong during submission.</div>}
        {submissionError && <div>Error: {submissionError}</div>}
      </Paper>
    </Box>
  );

  
};

export default BusinessCreate;
