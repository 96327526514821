
import {thingsApi} from './things'

export const thingUnFollowApi = thingsApi.injectEndpoints({
  endpoints: (builder) => ({
    
    thingUnFollow: builder.mutation({
      
      query: ({payload}) => ({
        url: '/unfollow',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useThingUnFollowMutation } = thingUnFollowApi