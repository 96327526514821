
import {userApi} from './user'


export const userChatHistoryApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserChatHistory: builder.query({
      query: (userId) => `/chat/history/${userId}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetUserChatHistoryQuery, usePrefetch, useLazyGetUserChatHistoryQuery  } = userChatHistoryApi