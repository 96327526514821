// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { userApi } from './thunks/users/user'
import { spApi } from './thunks/sp/sp'
import { aiApi } from './thunks/ai/ai'
import { thingsApi } from './thunks/things/things'
import { iconsApi } from './thunks/icons/icons'
import { statsApi } from './thunks/stats/stats'
import { filesApi } from './thunks/files/files'
import { urlsApi } from './thunks/urls/urls'
import { unauthApi } from './thunks/unauth/unauth'

import { setupListeners } from '@reduxjs/toolkit/query'

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage';



const persistConfig = {
  key: 'root',
  storage,
}


const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  // Add middleware like redux-thunk if needed
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat([userApi.middleware, 
    spApi.middleware, 
    aiApi.middleware, 
    thingsApi.middleware, 
    iconsApi.middleware, 
    statsApi.middleware, 
    filesApi.middleware, 
    urlsApi.middleware,
    unauthApi.middleware]),
});

setupListeners(store.dispatch)
export const persistor = persistStore(store)
export default store;
