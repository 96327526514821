import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  IconButton,
  Box,
} from '@mui/material';
import { PhotoCamera, VideoLibrary, Create } from '@mui/icons-material';
import CreatePostForm from './CreatePostForm'

function CreatePostOptions() {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box >
      <Card variant="outlined">
        <CardContent sx={{ marginX: 2 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="column" alignItems="center">
              <IconButton
                onClick={() => handleOptionClick('quick')}
                size="small"
                color="primary" // Color the icon blue
              >
                <Create fontSize="small" />
              </IconButton>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                Quick Post
              </Typography>
            </Stack>
            <Stack direction="column" alignItems="center">
              <IconButton
                onClick={() => handleOptionClick('photo')}
                size="small"
                color="primary" // Color the icon blue
              >
                <PhotoCamera fontSize="small" />
              </IconButton>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                Photo
              </Typography>
            </Stack>
            <Stack direction="column" alignItems="center">
              <IconButton
                onClick={() => handleOptionClick('video')}
                size="small"
                color="primary" // Color the icon blue
              >
                <VideoLibrary fontSize="small" />
              </IconButton>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                Video
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Post {selectedOption}</DialogTitle>
        <DialogContent>
          {/* Depending on the selectedOption, render a component for posting */}
          {selectedOption === 'quick' && <CreatePostForm />}
          {selectedOption === 'photo' && <PhotoPostForm />}
          {selectedOption === 'video' && <VideoPostForm />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CreatePostOptions;
