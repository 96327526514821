import { createSlice } from "@reduxjs/toolkit";

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    messages: {}, // To store messages by sender
    unreadCount: {}, // Count of unread messages
  },

  reducers: {
    addChatMessage: (state, action) => {
      console.log("action in addChatMessage=", action)
      console.log('JSON.parse(action.payload.data) in addChatMessage', JSON.parse(action.payload.data))
      const senderId = JSON.parse(action.payload.data).sender;

  
      if (!state.messages[senderId]) {
        state.messages[senderId] = [];
      }
      
      if (!state.unreadCount[senderId]) {
        state.unreadCount[senderId] = 0;
       
      }
      state.messages[senderId].push(action.payload);
      state.unreadCount[senderId] += 1
      
    },
    
    resetUnreadCount: (state, senderId) => {
      console.log('inside chatSlice, resetUnreadCount, senderId.payload', senderId.payload)
      delete state.unreadCount[senderId.payload]
    },
    chatClear: (state) => {
      state.messages = {};
      state.unreadCount = {};     
      
    }
    // ... other reducers if necessary
  },
});

export const { addChatMessage, resetUnreadCount, chatClear } = chatSlice.actions;
export default chatSlice.reducer;
