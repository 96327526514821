// WebSocketHandlers.js (create a new file for this utility)
import {addChatMessage} from '../redux/slices/chatSlice'
import {addNotification} from '../redux/slices/notificationSlice'
export const handleWebSocketMessage = (message, dispatch) => {


  // Dispatch the received WebSocket message to Redux store based on message type
  switch (message.type) {
    case 'NOTIFICATION':
        console.log('socket msg received for notification', message)
      dispatch(addNotification(message));
      break;
    case 'CHAT_MESSAGE':
      console.log('socket msg received for chat', message)
      dispatch(addChatMessage(message));
      break;
    // Add more cases for other message types as needed
    default:
      break;
  }
};
