
import {spApi} from './sp'


export const getSpProfileApi = spApi.injectEndpoints({
  endpoints: (builder) => ({
    getSpProfile: builder.query({
      providesTags: (result, error, {spId}) => [{ type: 'Spprofile', id: spId }],
      query: ({spId, authType, token}) => `/getspprofilebyid/${spId}?authType=${authType}&&token=${token}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetSpProfileQuery, usePrefetch } = getSpProfileApi