import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BusinessIcon from '@mui/icons-material/Business';
import CategoryIcon from '@mui/icons-material/Category';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  searchText: Yup.string().required('Search text is required'),
  location: Yup.string().required('Location is required'),
});

const SearchComponent = ({ onSearch }) => {
  const [tabValue, setTabValue] = useState(0); // State to manage selected tab

  const { control, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      searchText: '',
      location: '',
      fullAddress: '',
      zipcode: '',
      latitude: '',
      longitude: '',
    }
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue); // Update selected tab value
  };

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const addressComponents = results[0].address_components;

      const cityComponent = addressComponents.find((component) => component.types.includes('locality'));
      const stateComponent = addressComponents.find((component) => component.types.includes('administrative_area_level_1'));
      const zipcodeComponent = addressComponents.find((component) => component.types.includes('postal_code'));

      const city = cityComponent ? cityComponent.long_name : '';
      const state = stateComponent ? stateComponent.short_name : '';
      const zipcode = zipcodeComponent ? zipcodeComponent.long_name : '';

      setValue('location', `${city}, ${state}`);
      setValue('zipcode', zipcode);
      setValue('latitude', latLng.lat);
      setValue('longitude', latLng.lng);
      setValue('fullAddress', address);
    } catch (error) {
      console.error('Error', error);
    }
  };

  const onSubmit = (data) => {
    let selectedTab = '';
    switch (tabValue) {
      case 0:
        selectedTab = 'BusinessName';
        break;
      case 1:
        selectedTab = 'Categories';
        break;
      case 2:
        selectedTab = 'Restaurants';
        break;
      case 3:
        selectedTab = 'Doctors';
        break;
    }
    onSearch(data.searchText, selectedTab, data.fullAddress, data.zipcode, data.latitude, data.longitude);
  };

  const tabLabels = [
    'Business Name',
    'Categories',
    'Restaurants',
    'Doctors',
  ];

  return (
    <form style={{ width: '100%' , pb: 1}} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ mb: 1 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          centered
          indicatorColor="primary"
          textColor="primary"
        >
          {tabLabels.map((label, index) => (
            <Tab key={index} label={label} icon={getTabIcon(index)} />
          ))}
        </Tabs>
      </Box>
      <Box sx={{  display: 'flex', alignItems: 'center', justifyContent: 'center', border: 1, mb: 2,  borderRadius: 2, borderColor: '#B6BABA'}}>
        <Box sx={{ flex: 1, marginLeft: 1,  }}>
          <Controller
            name="searchText"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder={`Search by ${tabLabels[tabValue]}`}
                variant="outlined"
                fullWidth
                sx={{
                  backgroundColor: '#FFFFFF',
                  '& .MuiInputBase-input': {
                    padding: '10px',
                    fontSize: '14px',
                    
                  },
                  '& fieldset': {
                    border: 'none',
                  },
                }}
                error={!!errors.searchText}
                helperText={errors.searchText ? errors.searchText.message : ''}
              />
            )}
          />
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 2 }} />
       
        <Box sx={{ flex: 1,  }}>
          <Controller
            name="location"
            control={control}
            render={({ field }) => (
              <PlacesAutocomplete
                value={field.value}
                onChange={(address) => field.onChange(address)}
                onSelect={(address) => handleSelect(address)}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div style={{ position: 'relative' }}>
                    <TextField
                      placeholder="Location"
                      variant="outlined"
                      fullWidth
                      sx={{
                        backgroundColor: '#FFFFFF',
                        '& .MuiInputBase-input': {
                          padding: '10px',
                          fontSize: '14px',
                        },
                        '& fieldset': {
                          border: 'none',
                        },
                      }}
                      {...getInputProps()}
                      error={!!errors.location}
                      helperText={errors.location ? errors.location.message : ''}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        zIndex: 1000,
                        backgroundColor: '#fff',
                        width: '100%',
                      }}
                    >
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, index) => {
                        const style = {
                          backgroundColor: suggestion.active ? '#fafafa' : '#ffffff',
                          cursor: 'pointer',
                          padding: '10px',
                          fontSize: '14px',
                        };
                        return (
                          <div
                            key={index}
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            )}
          />
        </Box>
        <IconButton
          type="submit"
          color="primary"
          size="large"
          sx={{
            height: '42px',
            fontSize: '14px',
            ml: 2
          }}
       
        >
          <SearchIcon />
        </IconButton>
      </Box>
    </form>
  );
};

const getTabIcon = (index) => {
  switch (index) {
    case 0:
      return <BusinessIcon />;
    case 1:
      return <CategoryIcon />;
    case 2:
      return <RestaurantIcon />;   
    case 3:
      return <LocalHospitalIcon />;
  }
};

export default SearchComponent;
