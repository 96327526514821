import React from 'react';
import Avatar from '@mui/material/Avatar';

const UserPhoto = ({ photoUrl, initials, name, size, fontSize, variant }) => {
  const defaultInitials = 'RV';

  //let initials1 = initials ? initials : (name ? `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}` : 'RV')
  const initials1 = initials || (name ? name.split(' ').map(part => part[0].toUpperCase()).join('') : 'RV');
  
  //console.log('in user photo componnet initials1', initials1)
  //console.log('in user photo componnet photoUrl', photoUrl)

  const variantType = variant ? variant : 'circle'



  
  if (photoUrl) {
    return (
      <Avatar alt="User Photo" src={photoUrl} sx={{ width: size, height: size }} variant={variantType} />
    );
  } else if (initials1) {
    return (
      <Avatar alt="User Photo" sx={{ width: size, height: size, bgcolor: 'primary.main', typography: {
        fontSize: fontSize, // Adjust the font size as needed
      },}}>
        {initials1}
      </Avatar>
    );
  } else {
    return (
      <Avatar alt="User Photo" sx={{ width: size, height: size, bgcolor: 'primary.main' }}>
        {defaultInitials}
      </Avatar>
    );
  }
};

export default UserPhoto;