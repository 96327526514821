import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useUrlPostReviewMutation } from '../../redux/thunks/urls/urlpostreview';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Grid,
  Paper,
  Typography,
  Rating,
  TextField,
  Button,
  Container,
} from '@mui/material';

const WriteUrlReview = ({ }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const feedbackProps = location.state;

  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [postReview, { isLoading }] = useUrlPostReviewMutation();
  const token = useSelector((state) => state.auth.token);

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmitFeedback = async () => {
    if (feedback && rating) {
      try {
        let auth = {
          token: token,
          authType: 'token',
        };
        const review = {
          review: feedback,
          rating: 4,
          requestId: feedbackProps.requestId
        }
     

        const payload = {
          auth,
          review,
          
        };

        await postReview({ payload });
        navigate(`/url/request/${feedbackProps.requestId}`);
      } catch (error) {
        alert(`Failed to post review: ${error.message}`);
      }
    } else {
      alert('Please fill all fields!');
    }
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {/* First Column (70%) */}
        <Grid item xs={8}>
          <Paper variant="outlined" style={{ marginBottom: '20px', padding: '20px' }}>
            <Typography variant="h6">Write Review for {feedbackProps.skillName}</Typography>
          </Paper>
          <Box>
            <Typography variant="h6">Star Rating</Typography>
            <Rating
              name="rating"
              value={rating}
              onChange={handleRatingChange}
              precision={0.5}
            />
          </Box>
          <Box>
            <Typography variant="h6">Feedback</Typography>
            <TextField
              fullWidth
              multiline
              rows={5}
              variant="outlined"
              value={feedback}
              onChange={handleFeedbackChange}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitFeedback}
              style={{ marginTop: '10px' }}
            >
              Submit
            </Button>
          </Box>
        </Grid>

        {/* Second Column (30%) */}
        <Grid item xs={4}>
          <Paper variant="outlined" style={{ marginBottom: '20px', padding: '20px' }}>
            {/* Content for the second column */}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WriteUrlReview;
