
import {spApi} from './sp'


export const getSpReviewApi = spApi.injectEndpoints({
  endpoints: (builder) => ({
    getSpReview: builder.query({
      providesTags: ['spreviews'],
      query: ({spId, authType, token, businessId}) => `/getspreviews/${spId}?authType=${authType}&&token=${token}&&businessId=${businessId}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetSpReviewQuery, usePrefetch } = getSpReviewApi