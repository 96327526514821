import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { spApi} from '../../../redux/thunks/sp/sp'
import { usePostCreateMutation} from '../../../redux/thunks/sp/postcreate'




function PostCreate({ isOpen, onClose, businessDetails}) {
  const dispatch = useDispatch();  
  const navigate = useNavigate();
  const [postText, setPostText] = useState('');
  const [attachedPhotos, setAttachedPhotos] = useState([]);
  const userLabel = 'Yourself';

  const token = useSelector((state) => state.auth.token);

  const [textCreatePost, { isLoading }] = usePostCreateMutation();

  const handleAttachPhoto = () => {
    // Implement the logic to attach photos here
  };

  const handleSubmit = async () => {
    // Implement the logic to submit the post here

    console.log('values while submitting: postText ', postText);

    const auth = {
      token: token,
      authType: 'token',
    };

    const post = {
      type: 'text',
      text: postText,
    }

    const payload = {
      auth,
      spId: businessDetails.spId,      
      businessId: businessDetails._id,
      text: postText,
      post
      
    };
    await textCreatePost({ payload });
    navigate(`/provider/profile/${businessDetails.spId}`, { state: { businessId: businessDetails._id, businessName: businessDetails.name, skill: 'default' } });
    onClose();

   
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create a Post</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2">Posting as: {userLabel}</Typography>
        <TextField
          multiline
          rows={4}
          fullWidth
          placeholder="Write your post here..."
          value={postText}
          onChange={(e) => setPostText(e.target.value)}
        />
        <Box mt={2}>
          <IconButton onClick={handleAttachPhoto}>
            <AttachFile />
          </IconButton>
          <Typography variant="caption">Attach Photo</Typography>
        </Box>
        {/* Display attached photos here */}
        {/* You can render attached photos as thumbnails or a list */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Post
        </Button>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PostCreate;
