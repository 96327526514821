
import {userApi} from './user'

export const userAddTrustedUserApi = userApi.injectEndpoints({
  endpoints: (builder) => ({    
    AddTrustedUser: builder.mutation({

      invalidatesTags: (result, error, {userId}) => ([
        { type: 'User', id: userId },
        'OutgoingReq',
      ]),      
      query: ({userId, payload}) => ({
        url: `/trusteduser/${userId}`,
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useAddTrustedUserMutation } = userAddTrustedUserApi

