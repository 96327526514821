import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

export default function Hero() {
  return (
    <Box
      sx={{
        // bgcolor: 'rgba(0, 85, 255, 0.05)',
        position: 'relative',
        pb: { xs: 15, md: 30 },
        ':before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'url(/assets/start/hero.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
          zIndex: 0,
        },
      }}>
      <Container>
        <Box
          sx={{
            position: 'relative',
            zIndex: 99,
          }}>
          <Grid
            container
            spacing={5}
            py={{ xs: 6, md: 15 }}
            alignItems='center'>
            <Grid
              item
              xs={12}
              md={6}>
              <Typography
                variant='h2'
                fontSize={{ xs: 24, md: 48 }}
                textAlign={{ xs: 'center', md: 'left' }}
                fontWeight={600}>
                Your Digital word of mouth platform
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <Typography
                variant='body1'
                textAlign={{ xs: 'center', md: 'left' }}
                fontSize={{ xs: 16, md: 18 }}>
                In a world filled with countless reviews, finding trustworthy opinions can be challenging. At Revfer, we believe in the power of connections. Our platform connects you with reviews from people you know and trust, making it easier to find the best businesses around.


              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                height: { xs: 250, md: 500 },
                position: 'absolute',
                top: '50%',
                width: '100%',
                objectFit: 'cover',
                borderRadius: 1,
              }}
              component={'img'}
              src='/assets/images/groupthumbsup.jpg'
              alt='hero img'
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
