import React from 'react';
import { Container, Paper, Stack, Typography } from '@mui/material';
import CardItem from './CardItem'; 
import { useSelector } from 'react-redux';


const UsersYouMayKnow = () => {
  const similarProfiles = useSelector((state) => state.auth.user.similarProfiles)
    if (!similarProfiles || similarProfiles.userDetails.length === 0) {
        return null; // Return nothing if the array is empty
      }

      return (
        <Paper variant='outlined'
          sx={{
            overflowX: 'auto', // Enable horizontal scrolling
            my: 2
          }}
        >
          <div
            style={{
              width: '100%', // Set a fixed width for the container
              overflowX: 'auto', // Enable horizontal scrolling for the container
              scrollbarWidth: 'none', // Hide the scrollbar in Firefox
              msOverflowStyle: 'none', // Hide the scrollbar in Internet Explorer and Edge
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: '800', pl: 2 }}>
                You may know these users
            </Typography>
            <Stack
              direction="row" // Display cards horizontally
              alignItems="center"
              justifyContent="flex-start" // Align cards to the left
              spacing={2} // Add spacing between cards
              sx={{
                p: 2, // Add padding to the Stack container
              }}
            >
              {similarProfiles.userDetails.map((item, index) => (
                <CardItem key={index} item={item} />
              ))}
            </Stack>
          </div>
        </Paper>
      );
};

export default UsersYouMayKnow;
