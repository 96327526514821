
import {filesApi} from './files'


export const filespresignurlusravatarjpg = filesApi.injectEndpoints({
  endpoints: (builder) => ({
    getPreSignedUrlUsrAvatarJpg: builder.query({
      query: () => `/s3/user/avatar/presignedurl`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetPreSignedUrlUsrAvatarJpgQuery, usePrefetch } = filespresignurlusravatarjpg