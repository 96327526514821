
import {userApi} from './user'

export const userProfileApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      
      query: (userId) => `/profile/${userId}`,
      providesTags: (result, error, userId) => [{ type: 'User', id: userId }, { type: 'Profile', id: userId }],
    }),
    updateUserProfile: builder.mutation({
     
      query: ({userId, payload}) => ({
        url: `profile/${userId}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { userId }) => [{ type: 'User', id: userId }, { type: 'Profile', id: userId }],
    }),

  }),
  overrideExisting: false,
})

export const { useGetUserProfileQuery, useUpdateUserProfileMutation, usePrefetch} = userProfileApi