// providerData.js

export const PhotoCardData = [
    {
      type: 'StartBusiness',
      title: 'New Business ?',
      description: 'Engage with community to gain insights, gather authentic reviews, and truly understand your customers!',
      imageUrl: '/assets/images/Business.jpg',
      buttonLabel: 'Create Business Page',
      buttonLink: '/company/setup/new', 
    },

    {
      type: 'NeedReview',
      title: 'Seek Reviews ?',
      description: 'Found a product you are curious about? Post it on Revfer and let your trusted network and the community share their experiences. Get informed before you buy.',
      imageUrl: '/assets/images/SeekReview.jpg',
      buttonLabel: 'Create Page',
      buttonLink: '/url/create', 
    },

    {
      type: 'AddFriends',
      title: '',
      description: 'Get more insights, discover products and services, build your network.',
      imageUrl: '/assets/images/RevferBaloon.jpg',
      buttonLabel: 'Add Friends',
      buttonLink: '/thing/create', 
    },
    
    // Add more provider types as needed
  ];
  