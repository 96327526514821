import React, { useState, useEffect } from 'react';
import { Typography, Box, Radio, Checkbox, Paper, Divider, Stack } from '@mui/material';
import * as Utils from '../../../Functions/Utilities'


const GeneralFilters = ({ results, setFilteredResults }) => {
  const [selectedDistance, setSelectedDistance] = useState('');
  const [selectedStars, setSelectedStars] = useState([]);
  const [location, setLocation] = useState(null);

  console.log('in general filter results', results)

  useEffect(() => {
    // Check if Geolocation is available in the user's browser
    if ("geolocation" in navigator) {
      // Get the user's current position
      navigator.geolocation.getCurrentPosition(function(position) {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });
      }, function(error) {
        // Handle error, if any
        console.error("Error getting user's location:", error);
      });
    } else {
      // Geolocation is not available in the user's browser
      console.error("Geolocation is not available in this browser.");
    }
  }, []); // Empty dependency array to run this effect only once

  useEffect(() => { 
    console.log('set filtered results in generic filter as entered')
    setFilteredResults(results);
    
  }, []);

  const handleDistanceChange = (event) => {
    const distance = event.target.value;
    setSelectedDistance(distance);
    filterResults(distance, selectedStars);
  };

  const handleStarRatingChange = (event) => {
    const star = parseFloat(event.target.value);
    const updatedStars = selectedStars.includes(star)
      ? selectedStars.filter((selectedStar) => selectedStar !== star)
      : [...selectedStars, star];
    setSelectedStars(updatedStars);
    filterResults(selectedDistance, updatedStars);
  };

  const filterResults = (distance, stars) => {
    let filteredResults = results;
    console.log('in generic filter, selectedStars', selectedStars)
    console.log('in generic filter, stars', stars)
    console.log('in generic filter, results', results)


    if (distance) {
      // Implement distance filtering based on lat-long
      console.log('user selected distance: ', distance)



      
      filteredResults = filteredResults.filter((result) => {

        const userLat = location ? location.latitude : 42.262001
        const userLon =  location ? location.longitude : -71.466001
        const resultLat = result.geocodeResults.location.coordinates[1]
        const resultLon = result.geocodeResults.location.coordinates[0]
        const resultDistance = Utils.calculateDistance(userLat, userLon, resultLat, resultLon);
        console.log('distance in miles: ', resultDistance)
        return resultDistance <= distance;
       
      });
    }

    if (stars.length > 0) {
      // Filter by star rating
      filteredResults = filteredResults.filter((result) => {
        const decimalRating = result.avgSkillRating_all; // Assuming avgSkillRating_all is a decimal
        console.log('in generic filter, decimalRating', decimalRating)
        return stars.includes(Math.floor(decimalRating));
      });
    }
    console.log('in generic filter, filteredResults', filteredResults)

    setFilteredResults(filteredResults);
  };

  return (
    <Box>
       <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0 }}>
        Filters
      </Typography>
     
      <Paper variant="outlined" sx={{ p: 0, mb: 1, border: 'none'}}>
     
        <Typography variant="caption" sx={{ fontWeight: 'bold', mb: 2 }}>
          Distance:
        </Typography>
        <Stack sx={{ ml: 2 }}>
          <label>
            <Radio
              name="distance"
              value=""
              checked={selectedDistance === ''}
              onChange={handleDistanceChange}
            />
            <span style={{ fontSize: '12px' }}>Select</span>
          </label>
          <label>
            <Radio
              name="distance"
              value="1"
              checked={selectedDistance === '1'}
              onChange={handleDistanceChange}
            />
            <span style={{ fontSize: '12px' }}>1 mile</span>
          </label>
          <label>
            <Radio
              name="distance"
              value="5"
              checked={selectedDistance === '5'}
              onChange={handleDistanceChange}
            />
            <span style={{ fontSize: '12px' }}>5 miles</span>
          </label>
          <label>
            <Radio
              name="distance"
              value="10"
              checked={selectedDistance === '10'}
              onChange={handleDistanceChange}
            />
            <span style={{ fontSize: '12px' }}>10 miles</span>
          </label>
          <label>
            <Radio
              name="distance"
              value="50"
              checked={selectedDistance === '50'}
              onChange={handleDistanceChange}
            />
            <span style={{ fontSize: '12px' }}>50 miles</span>
          </label>
        </Stack>
      </Paper>
      <Divider/>
      <Paper variant="outlined" sx={{ p: 0, mb: 1, border: 'none' }}>
        <Typography variant="caption" sx={{ fontWeight: 'bold', mb: 2 }}>
          Star Rating:
        </Typography>
        <Stack sx={{ ml: 2 }}>
          {[1, 2, 3, 4, 5].map((rating) => (
            <label key={rating}>
              <Checkbox
                value={rating}
                checked={selectedStars.includes(rating)}
                onChange={handleStarRatingChange}
                sx={{ fontSize: '12px' }}
              />
              <span style={{ fontSize: '12px' }}>{rating} Star</span>
            </label>
          ))}
        </Stack>
      </Paper>
    </Box>
  );
};

export default GeneralFilters;
