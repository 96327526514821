const awsmobile = {
    "aws_project_region": "us-east-1",    
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_GvPPjHvS4",
    "aws_user_pools_web_client_id": "4d1ma99bockequke2cj06h30i",
    "oauth": {
        "domain": "goli.auth.us-east-1.amazoncognito.com",
        "scope": ["email", "openid", "profile"],
        "redirectSignIn": "http://localhost:3000/home",
        "redirectSignOut": "http://localhost:3000/auth/signout",
        "responseType": "code"
    }
};

export default awsmobile;
