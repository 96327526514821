import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Dialog,
  DialogContent,
  Box,
  Typography,
  Divider,
  CircularProgress,
} from '@mui/material';
import { Chrono } from 'react-chrono';

import { useGetUserTrustchainQuery } from '../../redux/thunks/users/usertrustchainsget'; // Import your trust chain query
import UserPhoto from '../../Components/UserPhoto/UserPhoto';
import LinkIcon from '@mui/icons-material/Link';

import { useSelector } from 'react-redux';


// Sample data (you can fetch data from the server)


const mapDataToArray = (data) => {
    return data.map((item, index) => ({
      title: `${index}`,
      cardTitle: item.displayName,
      cardDetailedText: `Trust Rating: ${item.trustRating}`,
    }));
}

function renderImageBlocks(data) {
    return (
      
        data.map((item, index) => (
          <UserPhoto key={index} photoUrl={item.photo}  initials={'PK'} size={50} />
        ))
     
    );
  }

  

const TrustChainDisplay = ({ userId, displayName, spinner }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [timelineItems, setTimelineItems] = useState([]);
  const [photoItems, setPhotoItems] = useState([]);

  const thisDisplayName = useSelector((state) => state.auth.user.displayName)
  const thisTrustRating = useSelector((state) => state.auth.user.trustRating)
  const thisPhoto = useSelector((state) => state.auth.user.profileImageUrl)




  const { data = [], isLoading = false, isError = false,} = useGetUserTrustchainQuery(userId);

  useEffect(() => {
    console.log('in trust chain modal, data', data)
    if (!isLoading && !isError) {
      if(data.length) {
        const items = mapDataToArray(data); // Implement your mapping function
        setTimelineItems(items);
        const imageBlocks = renderImageBlocks(data);
        setPhotoItems(imageBlocks)

      } else {
        const items = mapDataToArray([{
          displayName: thisDisplayName, 
          trustRating: thisTrustRating,
          photo: thisPhoto
        }]);
        setTimelineItems(items);
        const imageBlocks = renderImageBlocks(data);
        setPhotoItems(imageBlocks)

      }
      

      
    }
  }, [data, isLoading, isError]);


  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  if (isLoading ) {

    if(spinner) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress color="primary" />
        </Box>
  
      )

    } else {
      return (<></>)
    }

    
    
  }

  

  return (
    <>
      <IconButton onClick={handleOpenDialog} color="primary">
        {/* You can use any Material-UI icon here */}
        <LinkIcon/>
      </IconButton> 
      
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <DialogContent>
          <Box sx={{ p: 2 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Trust Chain between {thisDisplayName} and {displayName}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Chrono items={timelineItems} 
              borderLessCards={false}
              cardWidth={150}
              cardHeight={100}
              mode="VERTICAL" 
            >
                <div className="chrono-icons">{photoItems}</div>
            
                
                

            </Chrono>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TrustChainDisplay;
