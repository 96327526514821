// RouteGuard.js
import * as React from 'react';
import {Route, Navigate } from 'react-router-dom';
import {useSelector} from 'react-redux';
import { useAuthenticator, Authenticator } from '@aws-amplify/ui-react';



const RouteGuard = ({ children,  redirectTo, }) => {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  console.log('authstatus in route guard', authStatus)
  //const isAuthenticated = Authenticator.useAuthState().isSignedIn;
  //const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  //console.log('in route gurad, isLoggedin', isLoggedIn)
  console.log('in route gurad, children', children)
  //console.log('in route gurad, isAuthenticated', isAuthenticated)
  return authStatus === 'authenticated' ? children : <Navigate to={redirectTo} />;
};

export default RouteGuard;

