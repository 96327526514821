
import {urlsApi} from './urls'

export const urlRequestSearchApi = urlsApi.injectEndpoints({
  endpoints: (builder) => ({
    
    urlRequestSearch: builder.mutation({
      
      query: ({payload}) => ({
        url: '/review/search',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useUrlRequestSearchMutation } = urlRequestSearchApi