import React from 'react';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';

export default function FeaturesCard({ ...props }) {
  const { item } = props;
  return (
    <Card
      sx={{
        boxShadow: 'none',
        border: 'none',
        borderRadius: 1,
      }}>
      <CardContent>
        <Stack
          direction='row'
          alignItems='start'
          spacing={3}>
          <Box
            sx={{
              height: 48,
              width: 48,
              minWidth: 48,
              borderRadius: 1,
              bgcolor: 'rgba(0, 85, 255, 0.12)',
              color: 'primary.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {item.icon}
          </Box>
          <Stack>
            <Typography
              variant='subtitle1'
              fontSize={15}
              fontWeight={600}>
              {item.name}
            </Typography>
            <Typography
              variant='body1'
              color='text.secondary'
              fontSize={15}
              fontWeight={400}>
              {item.detail}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
