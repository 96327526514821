import React from 'react';
import Provider from './ProviderTypes/Provider';
import Restaurant from './ProviderTypes/Restaurant';
import Box from '@mui/material/Box';

const ResultsSection = ({ data, isMyProfile, spId }) => {
  //console.log('in result section, data', data);

  if (data === 'All') {
    return null;
  }

  const newData = { ...data, type: 'providers' };
  //console.log('in result section, newData', newData);
  let componentToRender;

  switch (newData.type) {
    case 'providers':
      componentToRender = <Provider result={newData} isMyProfile={isMyProfile} spId={spId}/>;
      break;
    case 'restaurants':
      componentToRender = <Restaurant result={newData} />;
      break;
    // Add more cases for other conditions here
    default:
      componentToRender = null; // Default case
  }

  return <Box sx={{ border: 0 }}>{componentToRender}</Box>;
};

export default ResultsSection;
