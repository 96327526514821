
import {spApi} from '../sp/sp'

// todo: adding things or skills to profile is going throgh /sp. it should go through /skills api 

export const thingAddApi = spApi.injectEndpoints({
  endpoints: (builder) => ({
    
    thingAdd: builder.mutation({
      invalidatesTags: (result, error, {spId}) => ([
        { type: 'Spprofile', id: spId },
       
      ]),
      
      query: ({...payload}) => ({
        url: '/addskillstospprofile',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useThingAddMutation } = thingAddApi