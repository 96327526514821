import React from 'react';
import { Box } from '@mui/material';
import GeneralFilters from './FilterTypes/GeneralFilters';
import UrlFilters from './FilterTypes/UrlFilters';

const FiltersSection = ({ results, setFilteredResults, filter}) => {
  console.log('in filter section, results', results)
  

  return (
    <Box>
    
          <Box mb={1}>
            {filter === 'generic' && <GeneralFilters results={results} setFilteredResults={setFilteredResults} />}            
            {filter === 'url' && <UrlFilters results={results} setFilteredResults={setFilteredResults} />}
            
           
            {/* Add more conditions for other types if needed */}
          </Box>
        
    </Box>
  );
};

export default FiltersSection;


/*
If coming from business name search: select generic filter
If coming from category search: select: generic filter
If coming from restauramt search: select: restaurant filter: todo ..





*/