import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'; 

import { List, Typography, ListItem, ListItemIcon, Divider, ListItemText } from '@mui/material'; // Import Material-UI components

const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    const truncated = text.slice(0, maxLength);
    return truncated.slice(0, truncated.lastIndexOf(' ')) + '...';
  };

  const headingTopSpacing = 100
  const headingBottomSpacing = 10


const Lists = ({ heading, names, iconName, rowSpacing, iconSpacing, isBusiness }) => {
  const IconComponent = Icons[iconName];

  console.log('isBusiness', isBusiness)

  const navigate = useNavigate(); // Initialize useNavigate

  const handleBusinessClick = (businessId, businessName, isBusiness) => {
    console.log('isBusiness inside andler', isBusiness)

    
    if(isBusiness) {
      navigate(`/business/${businessId}/${businessName.replace(/[\s,\.]+/g, '-').toLowerCase()}`);
    } else {
      console.log('placeholder for category search')
    }
    
    
  };

  return (
    <div>
        <Typography variant="caption" style={{ marginTop: '1px' }}>
        {heading}
       </Typography>

        <List>
        {names.map((name, index) => (
            <ListItem
            key={index}
            sx={{ mb: rowSpacing, pr: 0  }} // margin-bottom for row spacing
            >
            <ListItemIcon
                sx={{ mr: iconSpacing, ml: -2,  '& svg': { fontSize: 20 }  }} // margin-right for icon spacing
            >
                {IconComponent ? <IconComponent /> : null}
            </ListItemIcon>
            <ListItemText 
              primary={truncateText(name.businessName, 28)} 
              onClick={() => handleBusinessClick(name.businessId, name.businessName, isBusiness)} 
              sx={{ cursor: 'pointer' }}
            
            />
            </ListItem>
        ))}
        </List>


    </div>
    
  );
};

Lists.propTypes = {
  names: PropTypes.arrayOf(PropTypes.array).isRequired,
  iconName: PropTypes.string.isRequired,
  rowSpacing: PropTypes.number, // Spacing between each row in pixels
  iconSpacing: PropTypes.number, // Spacing between icon and text in pixels
};

Lists.defaultProps = {
  rowSpacing: .2, // Default row spacing (8px)
  iconSpacing: .2, // Default icon spacing (8px)
};

export default Lists;
