
import {spApi} from './sp'

export const reportBusinessApi = spApi.injectEndpoints({
  endpoints: (builder) => ({
    
    reportBusiness: builder.mutation({
      
      query: ({payload}) => ({
        url: '/reportbusiness',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useReportBusinessMutation } = reportBusinessApi