import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import StarIcon from '@mui/icons-material/Star';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BuildIcon from '@mui/icons-material/Build';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PublicIcon from '@mui/icons-material/Public';

const actionableInsightsData = [
  {
    area: 'Marketing',
    icon: <LocalOfferIcon sx={{ fontSize: 30, marginRight: 2 }} />,
    suggestion: 'Leverage social media to promote seasonal dishes and limited-time offers. Consider a collaboration with local influencers to boost brand visibility.',
  },
  {
    area: 'Customer Experience',
    icon: <EmojiPeopleIcon sx={{ fontSize: 30, marginRight: 2 }} />,
    suggestion: 'Enhance the dining experience by offering personalized greetings and remembering regular customers’ preferences. Implement feedback systems to act on customer suggestions promptly.',
  },
  {
    area: 'Product Development',
    icon: <RestaurantMenuIcon sx={{ fontSize: 30, marginRight: 2 }} />,
    suggestion: 'Experiment with new menu items based on current food trends and customer feedback. Introduce a “dish of the month” to keep the menu dynamic.',
  },
  {
    area: 'Competitive Advantage',
    icon: <StarIcon sx={{ fontSize: 30, marginRight: 2 }} />,
    suggestion: 'Differentiate by offering a unique dining experience, such as themed nights or live cooking demonstrations. Highlight the use of locally sourced ingredients.',
  },
  {
    area: 'Customer Retention',
    icon: <LoyaltyIcon sx={{ fontSize: 30, marginRight: 2 }} />,
    suggestion: 'Implement a loyalty program that rewards repeat customers with discounts or exclusive offers. Consider a referral program to encourage word-of-mouth promotion.',
  },
  {
    area: 'Sales Optimization',
    icon: <TrendingUpIcon sx={{ fontSize: 30, marginRight: 2 }} />,
    suggestion: 'Optimize upselling techniques by training staff to suggest pairings and upsell premium items. Use digital menus to highlight high-margin dishes.',
  },
  {
    area: 'Operational Efficiency',
    icon: <BuildIcon sx={{ fontSize: 30, marginRight: 2 }} />,
    suggestion: 'Streamline kitchen operations by adopting inventory management software to reduce waste. Implement staff scheduling tools to optimize labor costs.',
  },
  {
    area: 'Financial Management',
    icon: <AccountBalanceIcon sx={{ fontSize: 30, marginRight: 2 }} />,
    suggestion: 'Regularly review financial reports to identify cost-saving opportunities. Negotiate better rates with suppliers and explore bulk purchasing options.',
  },
  {
    area: 'Market Expansion',
    icon: <PublicIcon sx={{ fontSize: 30, marginRight: 2 }} />,
    suggestion: 'Explore opportunities to expand into new markets by offering catering services or opening a new location. Consider franchising if the brand is well-established.',
  },
];

const ActionableInsights = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        {actionableInsightsData.map((insight, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Card variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  {insight.icon}
                  <Typography variant="caption">{insight.area}</Typography>
                </Box>
                <Typography variant="body2">{insight.suggestion}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ActionableInsights;
