import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Avatar,
  Rating,
  Grid,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { MdSend } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useSpCommentPostMutation } from '../../redux/thunks/sp/spcommentpost';
import { useGenerateTextMutation } from '../../redux/thunks/ai/generatetext';

const ReviewCommentsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const review = location.state;

  console.log('review: ', review);

  const [comments, setComments] = useState(review.comments || []);
  const [newComment, setNewComment] = useState('');
  const [keywords, setKeywords] = useState(['', '', '', '']);
  const [commentType, setCommentType] = useState('');
  const [commentTone, setCommentTone] = useState('');
  const [postComment, { isLoading: isCommentsLoading }] = useSpCommentPostMutation();
  const [generateText, { isLoading: isgenerateTextLoading }] = useGenerateTextMutation();

  const token = useSelector((state) => state.auth.token);
  const thisUserPhoto = useSelector((state) => state.auth.user.profileImageUrl);
  const thisUserdisplayName = useSelector((state) => state.auth.user.displayName);

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  // Handle adding a new comment
  const handleAddComment = async () => {
    if (newComment.trim() === '') {
      return;
    }

    let auth = {
      token: token,
      authType: 'token'
    };

    let latestComment = {
      comment: newComment,
      reviewId: review._id
    };

    const payload = {
      auth,
      review: latestComment,
      spId: review.spId
    };

    await postComment({ payload });
    const updatedComments = [...comments, { displayName: thisUserdisplayName, comment: newComment, created: new Date(), photo: thisUserPhoto }];
    setComments(updatedComments);

    setNewComment('');
  };

  // Handle generating a comment
  const handleGenerateComment = async () => {
    let auth = {
      token: token,
      authType: 'token'
    };
    const payload = {
      auth,
      businessId:review.businessId,
      keywords: keywords.filter(kw => kw !== ''),
      type: commentType,
      tone: commentTone,
      generationType: 'comment',
      review: review.review,
      comments: review.comments

    };

    console.log('API Payload:', payload);

    // Call the API with the payload (assuming you have a function to do this)
    const generatedComment = await generateText({payload});

    console.log('generatedComment', generatedComment)

    setNewComment(generatedComment.data.text);
  };

 

  return (
    <Box maxWidth={1280} mx="auto" p={2}>
      <Typography variant="h6">Add business name here</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          <Paper variant="outlined" sx={{ padding: '10px', mb: 2 }}>
            <Box display="flex" alignItems="center" mb={2}>
              <Avatar src={review.userDetails.profileImageUrl} alt={review.userDetails.displayName} sx={{ width: 60, height: 60, mr: 2 }} />
              <Typography variant="h6">{review.userDetails.displayName}</Typography>
            </Box>
            <Box mb={2}>
              <Rating value={review.rating} precision={0.5} readOnly />
            </Box>
            <Typography variant="body1" mb={2}>
              {review.review}
            </Typography>
          </Paper>

          <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
            Comments ({comments.length})
          </Typography>

          {comments.map((comment, index) => {
            const commentDate = moment(comment.created);
            const currentDate = moment();
            const timeDifference = currentDate.diff(commentDate, 'days');
            const formattedDate = timeDifference === 1 ? '1 day ago' : `${timeDifference} days ago`;

            return (
              <Box
                key={index}
                sx={{
                  borderTop: '1px solid #ccc',
                  padding: '10px',
                  mb: 2,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body1" sx={{ flex: 1, marginRight: '10px' }}>{comment.comment}</Typography>
                <Link
                  to={`/profile/${comment.userId}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <Typography variant="caption" color="textSecondary">
                    {comment.displayName} - {formattedDate}
                  </Typography>
                </Link>
              </Box>
            );
          })}
        </Grid>

        <Grid item xs={12} sm={5}>
          <Paper variant="outlined" sx={{ padding: '10px', mb: 2 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>Generate a Comment</Typography>
            {keywords.map((keyword, index) => (
              <TextField
                key={index}
                label={`Keyword ${index + 1}`}
                fullWidth
                variant="outlined"
                value={keyword}
                onChange={(e) => {
                  const newKeywords = [...keywords];
                  newKeywords[index] = e.target.value;
                  setKeywords(newKeywords);
                }}
                sx={{ mb: 1 }}
              />
            ))}
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel>Type</InputLabel>
              <Select
                value={commentType}
                onChange={(e) => setCommentType(e.target.value)}
                label="Type"
              >
                <MenuItem value="question">Question</MenuItem>
                <MenuItem value="praise">Praise</MenuItem>
                <MenuItem value="critique">Critique</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel>Tone</InputLabel>
              <Select
                value={commentTone}
                onChange={(e) => setCommentTone(e.target.value)}
                label="Tone"
              >
                <MenuItem value="friendly">Friendly</MenuItem>
                <MenuItem value="professional">Professional</MenuItem>
                <MenuItem value="casual">Casual</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerateComment}
              sx={{ mb: 2 }}
              disabled={isgenerateTextLoading}
            >
              Generate Comment
            </Button>
            {isgenerateTextLoading && <CircularProgress size={24} sx={{ marginLeft: 2 }} />}
            <Typography variant="h6" sx={{ mb: 1 }}>Add a Comment</Typography>
            <TextField
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              endIcon={<MdSend />}
              onClick={handleAddComment}
              sx={{ mt: 2 }}
              disabled={isCommentsLoading}
            >
              Add Comment
            </Button>
            {isCommentsLoading && <CircularProgress size={24} sx={{ marginLeft: 2 }} />}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewCommentsPage;
