
import {userApi} from './user'


export const userIncomingReqApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    providesTags:['IncomingReq'],
    getUserIncomingReq: builder.query({
      providesTags:['IncomingReq'],
      query: (userId) => `/pendingin/${userId}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetUserIncomingReqQuery, usePrefetch } = userIncomingReqApi