import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  ClickAwayListener,
  Stack, // Add Stack component
} from '@mui/material';
import { useClaimBusinessMutation } from '../../redux/thunks/sp/claimbusiness';
import UaClaimContent from './UaClaimContent'; 


const ClaimBusiness = ({ isOpen, onClose, businessId }) => {
  const [userName, setUserName] = useState('');
  const [date, setDate] = useState('');
  const [uaAgreement, setUaAgreement] = useState(false);
  const [postClaim, { isLoading }] = useClaimBusinessMutation();

  const token = useSelector((state) => state.auth.token);

  const handleClaim = async () => {
    if (!uaAgreement) {
      // Check if the UA agreement checkbox is selected
      alert('Please accept the User Agreement before claiming.');
      return;
    }

    // Implement the logic to submit the claim
    // Include the user's name and date in the payload
    const auth = {
      token: token,
      authType: 'token',
    };
    const payload = {
      auth,
      userName,
      date,
      businessId,
    };

    await postClaim({ payload });
    onClose();
  };

  const handleCancel = () => {
    // Close the dialog when the "Cancel" button is clicked
    onClose();
  };

  const handleClickAway = (e) => {
    // Close the custom input fields when clicking outside the form
    if (!e.target.closest('.custom-input')) {
      setUserName('');
      setDate('');
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6">Claim Business</Typography>
      </DialogTitle>
      <DialogContent>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Stack spacing={2}>
            <Typography variant="body1">
              Please agree to the User Agreement before claiming this business:
            </Typography>
            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: UaClaimContent }}>
         
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={uaAgreement}
                  onChange={() => setUaAgreement(!uaAgreement)}
                  name="uaAgreement"
                />
              }
              label="I agree to the User Agreement"
            />
            <TextField
              fullWidth
              label="Your Name"
              variant="outlined"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className="custom-input"
            />
            <TextField
              fullWidth
              label="Date"
              variant="outlined"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="custom-input"
            />
          </Stack>
        </ClickAwayListener>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClaim} variant="contained" color="primary">
          Claim
        </Button>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClaimBusiness;
