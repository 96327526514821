import React from 'react';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Text } from '@visx/text';

const BarChart = ({ data, width = 300, height = 200, margin = { top: 20, right: 30, bottom: 40, left: 40 } }) => {
  // Bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // Scales
  const xScale = scaleBand({
    range: [0, xMax],
    round: true,
    domain: data.map((d, i) => `Level ${i + 1}`),
    padding: 0.2,
  });

  const yScale = scaleLinear({
    range: [yMax, 0],
    round: true,
    domain: [0, Math.max(...data)],
  });

  return (
    <svg width={width} height={height}>
      <Group top={margin.top} left={margin.left}>
        {data.map((d, i) => {
          const barWidth = xScale.bandwidth();
          const barHeight = yMax - (yScale(d) ?? 0);
          const barX = xScale(`Level ${i + 1}`);
          const barY = yMax - barHeight;
          return (
            <Group key={`bar-${i}`}>
              <Bar
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill="#8884d8"
              />
              <Text
                x={barX + barWidth / 2}
                y={barY - 4}
                fontSize={12}
                textAnchor="middle"
              >
                {d}
              </Text>
            </Group>
          );
        })}
        <AxisBottom top={yMax} scale={xScale} />
        <AxisLeft scale={yScale} />
      </Group>
    </svg>
  );
};

export default BarChart;
