
import {userApi} from './user'

//Get list of things this user follows
export const userFollowingApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    providesTags:['Follow'],
    getUserFollowing: builder.query({
      providesTags:['Follow'],
      query: () => `/followings`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetUserFollowingQuery, usePrefetch } = userFollowingApi