import { Box, Chip, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const data = [
  {
    id: 1,
    name: 'Listed Businesses',
    value: 1,
    symbol: 'M'
  },
  {
    id: 2,
    name: 'Business Categories',
    value: 651,
  },
  {
    id: 3,
    name: 'Connections Made',
    value: 10000,
    symbol: '+'
  },
  {
    id: 4,
    name: 'User Satisfaction',
    value: 98,
    symbol: '%',
  },
];

export default function About() {
  return (
    <Box my={8}>
      <Box height={{ xs: 120, md: 300 }} />
      <Container>
        <Stack
          mb={5}
          spacing={1.5}
          alignItems='center'
          justifyContent='center'>
          <Box>
            <Chip
              label='About'
              sx={{
                color: '#17A2B8',
                bgcolor: 'rgba(23, 162, 184, 0.12)',
                fontSize: 13,
                fontWeight: 600,
                px: 1,
              }}
            />
          </Box>
          <Typography
            variant='h3'
            fontWeight={600}
            textAlign={{ xs: 'center', md: 'left' }}
            fontSize={{ xs: 22, md: 28 }}>
            Revolutionizing Reviews
          </Typography>
          <Typography
            variant='body2'
            fontSize={15}
            fontWeight={400}
            textAlign={{ xs: 'center', md: 'left' }}
            color='text.secondary'>
            Trustworthy Insights from Your Network.
          </Typography>
        </Stack>
        <Grid
          pb={{ xs: 0, md: 10 }}
          container
          justifyContent='center'
          alignItems='center'
          spacing={2}>
          {data?.map((item, index) => (
            <Grid
              key={Math.random()}
              item
              xs={6}
              sm={6}
              md={3}>
              <Stack
                spacing={1}
                alignItems='center'
                justifyContent='center'>
                <VisibilitySensor
                  partialVisibility
                  offset={{ bottom: 20 }}>
                  {({ isVisible }) => (
                    <Typography
                      variant='h2'
                      fontWeight={600}
                      fontSize={{ xs: 24, md: 48 }}>
                      {isVisible ? <CountUp end={item.value} /> : item.value}
                      {item.symbol}
                    </Typography>
                  )}
                </VisibilitySensor>

                <Typography
                  variant='body2'
                  fontSize={15}
                  fontWeight={400}>
                  {item.name}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
