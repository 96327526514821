import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
console.log('REACT_APP_SEEKER_KEY', process.env.REACT_APP_SEEKER_KEY)
import { fetchAuthSession } from "aws-amplify/auth";


//const url = 'http://localhost:8080/https://devapi.revfer.com/testdev/v1/skills'
//const url = 'https://devapi.revfer.com/testdev/v1/skills'

const url = process.env.REACT_APP_PROXY_PREFIX + 'https://devapi.revfer.com/v2/v2/url'
console.log('url', url)

export const urlsApi = createApi({
  reducerPath: 'urlsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: async (headers, { getState }) => {
        //const token = getState().auth.token
        //console.log('token', token)

        const session = await fetchAuthSession(); 
        const token = session.tokens.idToken.toString();
        console.log('my token in url  base api', token)
    
        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set('x-api-key', process.env.REACT_APP_SEEKER_KEY)
          headers.set("Content-Type", "appliation/json")

          headers.set('Authorization', `${token}`)
          headers.set("Content-Type", "application/json")
        }
        console.log('urlsApi headers', headers)
    
        return headers
      },
  }),
  tagTypes: ['Urls'],
  endpoints: () => ({}),
})
