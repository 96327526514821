import React from 'react';
import { Grid, Card, CardContent, Typography, Box, Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import RatingIcon from '@mui/icons-material/Star';
import SentimentIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import ReviewIcon from '@mui/icons-material/RateReview';
import PageViewIcon from '@mui/icons-material/Visibility';
import BarChart from './BarChart';
import TopReviewers from './TopReviewers';
import CircularProgressChart from './CircularProgressChart';


const KPIs = () => {
  // Static data for demonstration purposes
  const staticData = {
    rating: 4.5,
    sentiment: 'Positive',
    reviewCount: 1250,
    pageViews: 5230,
    trustRank: 20,
    hopsRatio: 8,
    globalRank: '1000',
    reviewDistribution: [5, 10, 10, 5, 5], // Example distribution of ratings
    hopLevelReviews: [300, 500, 450], // Reviews by hop level
    impactfulReviewers: ['Reviewer 1', 'Reviewer 2', 'Reviewer 3'],
    impactfulReviews: ['Review 1', 'Review 2', 'Review 3'],
    topSuggestions: ['Suggestion 1', 'Suggestion 2', 'Suggestion 3'],
    impactfulUsers: [
      { id: 1, firstName: 'John', lastName: 'Doe', avatarUrl: 'https://i.pravatar.cc/150?img=1' },
      { id: 2, firstName: 'Jane', lastName: 'Smith', avatarUrl: 'https://i.pravatar.cc/150?img=2' },
      { id: 3, firstName: 'Alex', lastName: 'Johnson', avatarUrl: 'https://i.pravatar.cc/150?img=3' },
    ],
    impactfulReviews: [
      { id: 1, text: 'Great service and friendly staff. Highly recommend!', reviewer: 'John Doe', createdAt: '2024-07-20' },
      { id: 2, text: 'Had a wonderful experience. Will definitely return!', reviewer: 'Jane Smith', createdAt: '2024-07-19' },
      { id: 3, text: 'Quality could be improved, but overall good value.', reviewer: 'Alex Johnson', createdAt: '2024-07-18' },
    ],
    hopsPercentage: [

    ]
  };

  return (
    <Box>
      {/* First Row */}
      <Grid container spacing={2} sx={{ mb: 2 }} alignItems="stretch">
        <Grid item xs={12} sm={3}>
          <Card variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <RatingIcon sx={{ fontSize: 40, color: 'yellow' }} />
                <Box ml={2}>
                  <Typography variant="caption">Rating</Typography>
                  <Typography variant="h6">{staticData.rating}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card  variant="outlined" sx={{ height: '100%', padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <SentimentIcon sx={{ fontSize: 40, color: 'green' }} />
                <Box ml={2}>
                  <Typography variant="caption">Sentiment</Typography>
                  <Typography variant="h6">{staticData.sentiment}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <ReviewIcon sx={{ fontSize: 40, color: 'blue' }} />
                <Box ml={2}>
                  <Typography variant="caption">Review Count</Typography>
                  <Typography variant="h6">{staticData.reviewCount}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card  variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <PageViewIcon sx={{ fontSize: 40, color: 'purple' }} />
                <Box ml={2}>
                  <Typography variant="caption">Page Views</Typography>
                  <Typography variant="h6">{staticData.pageViews}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Second Row */}
      <Grid container spacing={2} sx={{ mb: 2 }} alignItems="stretch">
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
            <CardContent>
            <Typography variant="caption" sx={{ textAlign: 'center', display: 'block', my: 2 }} >
                Top % in Your Industry 
              </Typography>
              <Box display="flex" justifyContent="center">
                <CircularProgressChart percentage={staticData.trustRank} size={180} />
                
              </Box>
            
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
          <CardContent>
            <Typography variant="caption" sx={{ textAlign: 'center', display: 'block', my: 2 }} >
                Estimated % Sales Through Connections 
              </Typography>
              <Box display="flex" justifyContent="center">
                <CircularProgressChart percentage={staticData.hopsRatio} size={180} />
                
              </Box>
            
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
            <CardContent>
              <Typography variant="caption" sx={{ textAlign: 'center', display: 'block', my: 2 }}>Review Count by Hop </Typography>
              <BarChart data={staticData.hopLevelReviews} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Third Row */}
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} direction="column">
            {staticData.impactfulUsers.map((user) => (
              <Grid item key={user.id}>
                <Card variant="outlined" sx={{ height: '100%', padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <Avatar src={user.avatarUrl} sx={{ mr: 2 }} />
                      <Typography variant="h6">
                        {user.firstName} {user.lastName}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} direction="column">
            {staticData.impactfulReviews.map((review) => (
              <Grid item key={review.id}>
                <Card variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
                  <CardContent>
                    <Typography variant="body1" gutterBottom>
                      {review.text}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" sx={{ display: 'block', textAlign: 'right' }}>
                      {review.reviewer} - {review.createdAt}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KPIs;
