
import {spApi} from './sp'

export const postCreateApi = spApi.injectEndpoints({
  endpoints: (builder) => ({
    
    postCreate: builder.mutation({
      
      query: ({payload}) => ({
        url: '/post',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { usePostCreateMutation } = postCreateApi