import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react'
import { fetchAuthSession, getCurrentUser } from '@aws-amplify/auth';

function SignInSignUpPage() {
    const { route, user, signOut } = useAuthenticator(context => [context.route, context.user]);  

    console.log('route', route)
    console.log('user', user)
    console.log('signOut', signOut)
    const printAccessTokenAndIdToken = async () => {
      try {
        const session = await fetchAuthSession();   // Fetch the authentication session
        console.log('Access Token:', session.tokens.accessToken.toString());
        console.log('ID Token:', session.tokens.idToken.toString());
        console.log('session:', session);
      }
      catch (e) { console.log(e); }
    };

    async function currentAuthenticatedUser() {
      try {
        const userDetails = await getCurrentUser();
        console.log('userDetails', userDetails);
      
      } catch (err) {
        console.log(err);
      }
    }
    
    return (
    <div>
      {/* Render the sign-in/sign-up modal */}
      <h1>Hey, {user.username}</h1>
      <button onClick={signOut}>Sign out</button>
      <button onClick={printAccessTokenAndIdToken}>Print Tokens</button>
     
    </div>
  );
}

export default SignInSignUpPage;
