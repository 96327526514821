
import {spApi} from './sp'

export const spFindApi = spApi.injectEndpoints({
  endpoints: (builder) => ({
    
    spFind: builder.mutation({
      
      query: ({payload}) => ({
        url: '/findspbyskills',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useSpFindMutation } = spFindApi