
import {userApi} from './user'

//todo: invalidate tag for both the users here 

export const userNotificationReadApi = userApi.injectEndpoints({
  endpoints: (builder) => ({   
    
    userNotificationRead: builder.mutation({
     
      query: (notificationId) => ({
        url: `/notifications/${notificationId}/read`,
        method: 'POST'
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useUserNotificationReadMutation } = userNotificationReadApi

