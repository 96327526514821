import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, Button } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import UserPhoto from '../../Components/UserPhoto/UserPhoto';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PhotoCard from "../../Components/ByteSize/PhotoCards/PhotoCard";


const tmoDate = new Date();

const MyFirstDegrees = () => {
  const navigate = useNavigate();
  const firstDegrees = useSelector((state) => state.auth.user.firstDegreeFriends);
  const [sortBy, setSortBy] = useState('firstName'); // Default sorting by first name
  const [currentPage, setCurrentPage] = useState(1);
  const friendsPerPage = 7; // Number of friends to show per page
  const [myFriends, SetMyFriends] = useState(firstDegrees);

  // Define sorting options
  const sortingOptions = [
    { value: 'firstName', label: 'First Name' },
    { value: 'lastName', label: 'Last Name' },
    { value: 'connectionDate', label: 'Connection Date' },
  ];
//added to see if we can rightaway update friend list after accepting the request ...todo check
  useEffect(() => {
    if (firstDegrees) {
      SetMyFriends(firstDegrees)
    }
  }, [firstDegrees]);

  // Function to handle sorting change
  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Sort friends based on the selected sorting option
  const sortedFriends = [...myFriends].sort((a, b) => {
    if (sortBy === 'firstName') {
      const firstNameA = a.displayName.split(' ')[0];
      const firstNameB = b.displayName.split(' ')[0];
      return firstNameA.localeCompare(firstNameB);
    } else if (sortBy === 'lastName') {
      const lastNameA = a.displayName.split(' ')[1];
      const lastNameB = b.displayName.split(' ')[1];
      return lastNameA.localeCompare(lastNameB);
    } else if (sortBy === 'connectionDate') {
      return a.connectionDate - b.connectionDate;
    }
    return 0;
  });

  // Calculate the indexes for pagination
  const startIndex = (currentPage - 1) * friendsPerPage;
  const endIndex = startIndex + friendsPerPage;
  const paginatedFriends = sortedFriends.slice(startIndex, endIndex);

  const handleNavigation2Profile = (id) => {
    // Define navigation logic for endorsed activity (e.g., go to profile page)
    navigate(`/profile/${id}`);
  };

  return (
    <Box>
      <Box sx={{ mb: 2, }}>
        <Typography variant="subtitle2" sx={{ flexGrow: 1, mb: 2 }}>
          My First Degrees
        </Typography>

        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="subtitle2" sx={{ mt: 1, mr: 2 }}>
            Sort by:
          </Typography>
            <select
              id="sort-by"
              value={sortBy}
              onChange={handleSortChange}
              style={{ padding: '5px', borderRadius: '4px',  fontSize: '14px'}}
            >
              {sortingOptions.map((option) => (
                <option key={option.value} value={option.value} style={{ fontSize: '14px',  WebkitAppearance: 'none', MozAppearance: 'none', appearance: 'none' }}>
                  {option.label}
                </option>
              ))}
            </select>

        </Box>
        
        
        
      </Box>
      {paginatedFriends.map((friend) => (
        <Grid item xs={12} key={friend.userId}>
          <Grid item xs={12} key={friend.userId}>
            <Box sx={{border: 'none', borderRadius: 2, mb: 1}}>
            <Paper variant="outlined" sx={{ padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {/* First Column: Friend's Picture */}
              <UserPhoto photoUrl={friend.profileImageUrl} name={friend.displayName} size={50} />

            {/* Second Column: Name, Trust Rating, and Connected Date */}
            <Box sx={{  }}>
              <Typography variant="subtitle2" gutterBottom style={{ cursor: 'pointer' }} onClick={() => handleNavigation2Profile(friend.userId)}>
                {friend.displayName}
              </Typography>
              
              <Typography variant="body2">
                Connected {moment(friend.creationTime).fromNow(true)} ago
              </Typography>
              <Button variant="outlined" color="primary" size="small" sx={{ marginBottom: '8px', mr: 2 }}>
                Message
              </Button>
              <Button variant="outlined" color="error" size="small" sx={{ marginBottom: '8px' }}>
                Remove
              </Button>
            </Box>

            {/* Third Column: Message and Remove Buttons */}
            <Box sx={{justifyContent: 'center', ml:2}}>
             
             
            </Box>
          </Paper>

            </Box>
            <Box>
                
            </Box>
          
        </Grid>
        </Grid>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {Array.from({ length: Math.ceil(sortedFriends.length / friendsPerPage) }, (_, index) => (
          <Button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            variant={currentPage === index + 1 ? 'contained' : 'outlined'}
            sx={{
              mx: 1,
              borderRadius: '50%', // Make the pagination button circular
              minWidth: '32px', // Set a minimum width to maintain circular shape
            }}
          >
            {index + 1}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default MyFirstDegrees;
