import React from 'react';
// mui
import { Box, Container, Grid, Stack, Typography, Button } from '@mui/material';
// icons
import { IoIosArrowRoundForward } from 'react-icons/io';

const data = [
  {
    id: 1,
    name: 'Restaurants',
    img: '/assets/business/menu',
  },
  {
    id: 2,
    name: 'Health and Wellness',
    img: '/assets/business/doctor',
  },
  {
    id: 3,
    name: 'Service Providers',
    img: '/assets/business/plumber',
  },
  {
    id: 4,
    name: 'Travel and Hospitality',
    img: '/assets/business/rail',
  },
  {
    id: 5,
    name: 'Home Improvement',
    img: '/assets/business/home',
  },
  {
    id: 6,
    name: 'Technology Services',
    img: '/assets/business/coding',
  },
  {
    id: 7,
    name: 'Childcare Services',
    img: '/assets/business/swing',
  },
  {
    id: 8,
    name: 'Legal Services',
    img: '/assets/business/court',
  },
];

export default function About() {
  return (
    <Box my={8}>
      <Container>
        <Stack
          mb={5}
          spacing={1.5}
          alignItems='center'
          justifyContent='center'>
          <Typography
            variant='h3'
            fontWeight={600}
            textAlign={{ xs: 'center', md: 'left' }}
            fontSize={{ xs: 22, md: 28 }}>
            Top Categories on Revfer
          </Typography>
          <Typography
            variant='body2'
            fontSize={15}
            fontWeight={400}
            textAlign={{ xs: 'center', md: 'left' }}
            color='text.secondary'>
            Discover the Range of Businesses{' '}
            <Typography
              component='span'
              fontWeight={500}
              color='text.primary'>
              Thriving
            </Typography>{' '}
            with Trusted Reviews
          </Typography>
        </Stack>
        <Grid
          container
          alignItems='center'
          spacing={{ xs: 2, lg: 5 }}>
          {data?.map((item, index) => (
            <Grid
              key={Math.random()}
              item
              xs={6}
              sm={6}
              md={3}>
              <Stack
                spacing={3}
                direction='row'
                alignItems='center'>
                <Box
                  component='img'
                  src={`${item.img}.png`}
                  alt={item.name}
                  height={{ xs: 32, md: 48 }}
                  width={{ xs: 32, md: 48 }}
                />
                <Typography
                  variant='body2'
                  fontSize={{ xs: 14, md: 17 }}
                  fontWeight={400}>
                  {item.name}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
       
      </Container>
    </Box>
  );
}
