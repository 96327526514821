import React from 'react';
// mui
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  Typography,
  Box,
} from '@mui/material';

export default function TestimonialCard({ ...props }) {
  const { item } = props;
  return (
    <Card
      sx={{
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        boxShadow: 'none',
        mx: 1,
      }}>
      <CardContent
        sx={{
          p: { xs: 2, md: 6 },
        }}>
        <Typography
          gutterBottom
          variant='body2'
          fontSize={15}
          fontWeight={400}>
          {item.detail}
        </Typography>
        <Stack
          mt={2}
          direction={'row'}
          alignItems='center'
          justifyContent='space-between'
          spacing={2}>
          <Stack
            direction={'row'}
            alignItems='center'
            spacing={2}>
            <Avatar sx={{ height: 36, width: 36 }} />
            <Stack>
              <Typography
                variant='subtitle1'
                fontSize={13}
                fontWeight={600}>
                {item.userName}
              </Typography>
              <Typography
                variant='subtitle1'
                color='text.secondary'
                fontSize={13}
                fontWeight={400}>
                {item.userJob}
              </Typography>
            </Stack>
          </Stack>
          <Box
            component='img'
            src={`${item.img}.png`}
            alt='amazon'
            sx={{ height: 32, objectFit: 'contain' }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
