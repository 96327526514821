import React from 'react';
import { useSelector } from 'react-redux';
import { Paper, Typography, Avatar, Grid, Divider, Box } from '@mui/material'; // Import Material-UI components
import { generateColorFromInitials } from '../../Functions/Utilities'; // Import your utility function
import UserPhoto from '../../Components/UserPhoto/UserPhoto';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import Lists from './Lists/Lists'

const iconName = 'Notes'; // The name of the MUI icon
const heading ='Most Reviewed'


const MostReviewedBusinesses = ({businesses}) => {

  console.log('Most reviewed businesses: ', businesses)


  // Check if similarProfiles exists and is not empty
  if (!businesses ) {
    return null; // Return null if similarProfiles is empty or does not exist
  }

  const names = businesses.map(business => ({
    businessId: business.businessId ? business.businessId : business._id,
    businessName: business.businessName,    
  }));

  console.log('names in Most reviewed businesses', names)


  return (
    <Paper variant="outlined" sx={{ padding: 1, border: 'none' }}>
      <Lists heading={heading} names={names} iconName={iconName} rowSpacing={-2} iconSpacing={-3} isBusiness={true} />
       

    </Paper>
  );
};

export default MostReviewedBusinesses;
