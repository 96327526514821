import React from 'react';
import Provider from './ProviderTypes/Provider';
import Restaurant from './ProviderTypes/Restaurant';
import Box from '@mui/material/Box';

const ResultsSection = ({ data, isMyProfile }) => {
  console.log('in result section, data', data); 
 
  let componentToRender;

  switch (data.type) {
    case 'provider':
      componentToRender = <Provider result={data} isMyProfile={isMyProfile}/>;
      break;
    case 'restaurants':
      componentToRender = <Provider result={data} isMyProfile={isMyProfile}/>;
      break;
    // Add more cases for other conditions here
    default:
      componentToRender = null; // Default case
  }

  return <Box sx={{ border: 0 }}>{componentToRender}</Box>;
};

export default ResultsSection;
