import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
    unreadNotifications: 0,
  },
  reducers: {
    addNotification: (state, action) => {
      console.log("action in addNotification=", action)
      state.notifications.push(action.payload);
      state.unreadNotifications += 1;
    },
    resetUnreadNotifications: (state) => {
      state.unreadNotifications = 0;
    },
    notificationsClear: (state) => {
      state.notifications = [];
      state.unreadCount = 0;     
      
    }
    // ... other reducers if necessary
  },
});

export const { addNotification, resetUnreadNotifications, notificationsClear } = notificationSlice.actions;
export default notificationSlice.reducer;
