import React from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import {
  Share as ShareIcon,
  Business as BusinessIcon,
  PersonAdd as PersonAddIcon,
  Report as ReportIcon,
  EditNote as ClaimIcon,
  PostAdd as PostAddIcon,
  AddAPhoto as AddAPhotoIcon

} from '@mui/icons-material';


const MoreMenu = ({  verified, 
  authorizedPersonal, 
  anchorEl, 
  open, 
  onClose, 
  onEditClick, 
  onBusineePhotoEditClick,
  onPostClick, 
  onShareLocationClick, 
  onReportClick,
  onVerifyClick,
  onClaimClick,
  isMyProfile,
  

}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >

      {isMyProfile && 
        <MenuItem onClick={onEditClick}>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Edit" />
        </MenuItem>     
      }

      {isMyProfile && 
        <MenuItem onClick={onBusineePhotoEditClick}>
        <ListItemIcon>
          <AddAPhotoIcon />
        </ListItemIcon>
        <ListItemText primary="Change Photo" />
        </MenuItem>     
      }

     

      {isMyProfile && 
        <MenuItem onClick={onPostClick}>
        <ListItemIcon>
          <PostAddIcon />
        </ListItemIcon>
        <ListItemText primary="Post" />
        </MenuItem>
      }

      {isMyProfile && 
        <MenuItem onClick={onShareLocationClick}>
        <ListItemIcon>
          <ShareIcon />
        </ListItemIcon>
        <ListItemText primary="Share location" />
        </MenuItem>           
      
      }     

      {isMyProfile && !verified &&
        <MenuItem onClick={onVerifyClick}>
        <ListItemIcon>
          <ShareIcon />
        </ListItemIcon>
        <ListItemText primary="Verify your business" />
        </MenuItem>
        
      }
        

      {!isMyProfile && authorizedPersonal &&
        <MenuItem onClick={onClaimClick}>
        <ListItemIcon>
          <ShareIcon />
        </ListItemIcon>
        <ListItemText primary="Dispute this business" />
        </MenuItem>
      
      }

      {!isMyProfile && !authorizedPersonal &&
        <MenuItem onClick={onClaimClick}>
        <ListItemIcon>
          <ClaimIcon />
        </ListItemIcon>
        <ListItemText primary="Claim this business" />
        </MenuItem>
      
      }    

      {!isMyProfile && 
        <Divider />
      
      }
      
      
      {!isMyProfile &&
      
      
      <MenuItem onClick={onReportClick}>
      <ListItemIcon>
        <ReportIcon />
      </ListItemIcon>
      <ListItemText primary="Report this business" />
      </MenuItem>      
      }
     
      
      
   
     
     
      
    </Menu>
  );
};

export default MoreMenu;


