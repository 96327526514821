import React from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import {
  Reply as ReplyIcon,
  Business as BusinessIcon,
  PersonAdd as PersonAddIcon,
  EditNote as EditNoteIcon,
  EditOutlined as EditOutlinedIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  FlagOutlined as FlagOutlinedIcon

} from '@mui/icons-material';


const MoreMenu = ({ isThisMe, 
  anchorEl, 
  open, 
  onClose, 
  onShareClick, 
  onSeeBusinessClick, 
  onFollowClick, 
  onReportClick, 
  onEditClick,
  onAvatarClick
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {isThisMe &&
      <MenuItem onClick={onShareClick}>
      <ListItemIcon>
        <ReplyIcon />
      </ListItemIcon>
      <ListItemText primary="Share your profile" />
      </MenuItem>      
      }
       {isThisMe &&
        <MenuItem onClick={onReportClick}>
        <ListItemIcon>
          <EditNoteIcon />
        </ListItemIcon>
        <ListItemText primary="Create a post" />
        </MenuItem>
      
      }
     
      
      
      <MenuItem onClick={onSeeBusinessClick}>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="See business pages" />
      </MenuItem>

      {!isThisMe &&
     <MenuItem onClick={onFollowClick}>
     <ListItemIcon>
       <PersonAddIcon />
     </ListItemIcon>
     <ListItemText primary="Follow" />
     </MenuItem> 
      }

      
      <Divider />
      {!isThisMe &&
        <MenuItem onClick={onReportClick}>
        <ListItemIcon>
          <FlagOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Report this profile" />
        </MenuItem>
      
      }
       {isThisMe &&
      <MenuItem onClick={onEditClick}>
      <ListItemIcon>
        <EditOutlinedIcon />
      </ListItemIcon>
      <ListItemText primary="Edit your profile" />
      </MenuItem>      
      }
      {isThisMe &&
      <MenuItem onClick={onAvatarClick}>
      <ListItemIcon>
        <AdminPanelSettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Upload avatar" />
      </MenuItem>      
      }
     
    </Menu>
  );
};

export default MoreMenu;
