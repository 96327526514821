import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSpReviewPostMutation } from '../../redux/thunks/sp/spreviewpost';
import { useGenerateTextMutation } from '../../redux/thunks/ai/generatetext';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Rating,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  CircularProgress,
  Avatar
} from '@mui/material';

const WriteReview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const feedbackProps = location.state;
  console.log('in write review feedbackProps: ', feedbackProps);

  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [keywords, setKeywords] = useState(['', '', '', '']);
  const [commentType, setCommentType] = useState('');
  const [commentTone, setCommentTone] = useState('');
  const [postReview, { isLoading: isReviewLoading }] = useSpReviewPostMutation();
  const [generateText, { isLoading: isgenerateTextLoading }] = useGenerateTextMutation();

  const token = useSelector((state) => state.auth.token);

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmitFeedback = async () => {
    if (feedback && rating) {
      try {
        let auth = {
          token: token,
          authType: 'token',
        };
        let review = {
          review: feedback,
          rating: rating,
          skillId: feedbackProps.skillId,
          businessId: feedbackProps.businessId
        };

        const payload = {
          auth,
          review,
          spId: feedbackProps.spId,
        };

        await postReview({ payload });
        navigate(`/provider/profile/${feedbackProps.spId}`, { state: { businessId: feedbackProps.businessId, businessName: feedbackProps.businessName, skill: feedbackProps.skill } });
      } catch (error) {
        alert(`Failed to post review: ${error.message}`);
      }
    } else {
      alert('Please fill all fields!');
    }
  };

  const handleGenerateReview = async () => {
    let auth = {
      token: token,
      authType: 'token',
    };
    const payload = {
      auth,
      businessId: feedbackProps.businessId,
      keywords: keywords.filter(kw => kw !== ''),
      type: commentType,
      tone: commentTone,
      generationType: 'review',
    };

    console.log('API Payload:', payload);

    const generatedReview = await generateText({payload});

    console.log('generatedReview', generatedReview)
    setFeedback(generatedReview.data.text);
  };



  return (
    <Box maxWidth={1280} mx="auto" p={2}>
      <Grid container spacing={3}>
        {/* First Column (70%) */}
        <Grid item xs={12} sm={8}>
          <Paper variant="outlined" style={{ marginBottom: '20px', padding: '20px' }}>
          <Box display="flex" alignItems="center" mb={2}>
          <Avatar src={feedbackProps.skillPhoto} alt={feedbackProps.skill.businessName} sx={{ width: 60, height: 60, mr: 2 }} />
              
           
              <Typography variant="h6">Write a Review for {feedbackProps.businessName}</Typography>

          </Box>
          
           
          </Paper>
          <Box>
            <Typography variant="h6">Star Rating</Typography>
            <Rating
              name="rating"
              value={rating}
              onChange={handleRatingChange}
              precision={0.5}
            />
          </Box>
          <Box>
            <Typography variant="h6">Feedback</Typography>
            <TextField
              fullWidth
              multiline
              rows={5}
              variant="outlined"
              value={feedback}
              onChange={handleFeedbackChange}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitFeedback}
              style={{ marginTop: '10px' }}
              disabled={isReviewLoading}
              
            >
              Submit
            </Button>
            {isReviewLoading && <CircularProgress size={24} sx={{ marginLeft: 2 }} />}

          </Box>
        </Grid>

        {/* Second Column (30%) */}
        <Grid item xs={12} sm={4}>
          <Paper variant="outlined" style={{ marginBottom: '20px', padding: '20px' }}>
            <Typography variant="h6" style={{ marginBottom: '20px' }}>Generate a Review</Typography>
            {keywords.map((keyword, index) => (
              <TextField
                key={index}
                label={`Keyword ${index + 1}`}
                fullWidth
                variant="outlined"
                value={keyword}
                onChange={(e) => {
                  const newKeywords = [...keywords];
                  newKeywords[index] = e.target.value;
                  setKeywords(newKeywords);
                }}
                style={{ marginBottom: '10px' }}
              />
            ))}
            <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px' }}>
              <InputLabel>Type</InputLabel>
              <Select
                value={commentType}
                onChange={(e) => setCommentType(e.target.value)}
                label="Type"
              >
                <MenuItem value="question">Question</MenuItem>
                <MenuItem value="praise">Praise</MenuItem>
                <MenuItem value="critique">Critique</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px' }}>
              <InputLabel>Tone</InputLabel>
              <Select
                value={commentTone}
                onChange={(e) => setCommentTone(e.target.value)}
                label="Tone"
              >
                <MenuItem value="friendly">Friendly</MenuItem>
                <MenuItem value="professional">Professional</MenuItem>
                <MenuItem value="casual">Casual</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerateReview}
              style={{ marginBottom: '20px' }}
              disabled={isgenerateTextLoading}
            >
              Generate Review
            </Button>
            {isgenerateTextLoading && <CircularProgress size={24} sx={{ marginLeft: 2 }} />}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WriteReview;
