import React from 'react';
import { Card, CardContent, CardMedia, CardHeader, Typography, Button, Box } from '@mui/material';
import { TextCardData } from './TextCardData'; // Import the data
import { useNavigate } from 'react-router-dom';


const TextCard = ({ type, size, sx }) => {

  const navigate = useNavigate();
    // Find the matching provider data based on the 'type' prop
  const cardData = TextCardData.find((provider) => provider.type === type);

  const handleButtonClick = () => {
    navigate(cardData.buttonLink);
    
  };
  return (
    <Card variant='outlined'
      sx={{
        maxWidth: size || '100%', // Set the card width based on the 'size' prop or 100% by default
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border:'none',
        ...sx
      }}
    >
      <CardContent>
        <Typography variant="body2">{cardData.description}</Typography>
        <Button variant="outlined" color="primary" onClick={handleButtonClick}>
          {cardData.buttonLabel}
        </Button>
      </CardContent>
    </Card>
  );
};

export default TextCard;
