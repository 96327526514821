import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Avatar,
  Rating,
  Grid,
  IconButton,
  Badge, // Import Badge
} from '@mui/material';
import { MdThumbUp, MdThumbDown, MdEdit, MdDelete, MdComment } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


import { useSpReviewLikeMutation } from '../../redux/thunks/sp/spreviewlike';



const ReviewDetails = ({ review, onCommentsClick }) => {
  
  const navigate = useNavigate();
  const comments = review.comments || [];

  const token = useSelector((state) => state.auth.token) 


  const reviewLiked =  []
  const reviewDisliked = []

  
   // Click handler to navigate to the comments page
  const handleCommentsClick = () => {
    const msg = {
      text: 'Please sign up or sign in to see the comments'
    };
    navigate('/auth/signin', { state: msg });
  };

  const handleLikeAction = async (actionType) => {
    console.log('handle like action')
    const msg = {
      text: 'Please sign up or sign in for this action'
    };
    navigate('/auth/signin', { state: msg });
  };

  const handleThumbsUpPress = () => {
    console.log('handle ')
    const msg = {
      text: 'Please sign up or sign in for this action'
    };
    navigate('/auth/signin', { state: msg });
  };

  const handleThumbsDownPress = () => {
    console.log('handle ')
    const msg = {
      text: 'Please sign up or sign in for this action'
    };
    navigate('/auth/signin', { state: msg });
  };



  return (
    <Paper variant="outlined" sx={{ padding: '10px', mb: 2 }}>
      {/* First Row */}
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar src={'pk'} alt={review.review} sx={{ width: 60, height: 60, mr: 2 }} />
        <Typography variant="h6">User</Typography>
        {/* Add more information components here */}
      </Box>

      {/* Star Rating */}
      <Box mb={2}>
        <Rating value={review.rating} precision={0.5} readOnly />
      </Box>

      {/* Second Row */}
      <Typography variant="body1" mb={2}>
        {review.review}
      </Typography>

      {/* Third Row: Icons */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <IconButton color={ 'primary'} aria-label="Thumb Up" onClick={handleThumbsUpPress}>
            <MdThumbUp />
          </IconButton>
          <IconButton color={  'error' } aria-label="Thumb Down" onClick={handleThumbsDownPress} >
            <MdThumbDown />
          </IconButton>
          {/* Comments icon with badge */}
          <IconButton color="primary" aria-label="Comments" onClick={handleCommentsClick}>
            <Badge badgeContent={comments.length} color="error">
              <MdComment />
            </Badge>
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton color="primary" aria-label="Edit">
            <MdEdit />
          </IconButton>
          <IconButton color="secondary" aria-label="Delete">
            <MdDelete />
          </IconButton>
        </Grid>
      </Grid>

      
    </Paper>
  );
};

export default ReviewDetails;
