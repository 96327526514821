import React, {useEffect, useState} from 'react';
import UserPhoto from '../../Components/UserPhoto/UserPhoto';
import { Avatar, Typography, Button, Box, Grid, IconButton } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import ChatIcon from '@mui/icons-material/Chat';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TrustChainDisplay from '../../Components/TrustChain/TrustChainDisplay';
import { useSelector } from 'react-redux';

import { useGetUserIncomingReqQuery } from '../../redux/thunks/users/userincomingrequests';
import { useGetUserOutgoingReqQuery } from '../../redux/thunks/users/useroutgoingrequests';
import { usePrefetch } from '../../redux/thunks/users/userprofile';
import MoreMenu from '../../Components/Menus/MoreMenu';
import EditProfileDialog from './EditProfileDialog'; 
import AvatarUploadDialog from './AvatarUploadDialog'; 




import { useNavigate } from 'react-router-dom';




const UserFacePlate = ({ user }) => {

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAvatarDialogOpen, setIsAvatarDialogOpen] = useState(false);


  const [isPending, setIsPending] = useState(false);
  const thisUserId = useSelector((state) => state.auth.userId)

  const firstDegreeFriends = useSelector((state) => state.auth.user.firstDegreeFriends) 
  const isConnected =  firstDegreeFriends.length > 0 &&  firstDegreeFriends.some((friend) => friend.userId.toString() === user._id.toString());
  const isMe = thisUserId===user._id;

  const prefetchUserProfiles = usePrefetch('getUserProfile')
  const { data: incoming, isLoading: incomingLoading, isSuccess: incomingSuccess  } = useGetUserIncomingReqQuery(thisUserId);
  const { data: outgoing, isLoading: outgoingLoading, isSuccess: outgoingSuccess  } = useGetUserOutgoingReqQuery(thisUserId);

  useEffect(() => {
    if (incomingSuccess && outgoingSuccess) {
      // Combine two arrays and create a new array with only unique values.
      const incomingIds = incoming.pendingInTrustedUsers.map(item => item.trustedUserId);
      const outgoingIds = outgoing.pendingOutTrustedUsers.map(item => item.trustedUserId);
      const isPendingIn =  incoming.pendingInTrustedUsers.length > 0 &&  incoming.pendingInTrustedUsers.some((friend) => friend.trustedUserId.toString() === user._id.toString());
      const isPendingOut =  outgoing.pendingOutTrustedUsers.length > 0 &&  outgoing.pendingOutTrustedUsers.some((friend) => friend.trustedUserId.toString() === user._id.toString());
      console.log('outgoing', outgoing)
      console.log('isPendingOut', isPendingOut)
      console.log('isPendingIn', isPendingIn)
      setIsPending(isPendingIn || isPendingOut)
      // Create a new array with only unique ids.
      const profileIds = [...new Set([...incomingIds, ...outgoingIds])];
      //profileIds.forEach(id => prefetch(id));
    }
  }, [incoming, outgoing]);

  const handleNavigation2Chat = (id) => {
    // Define navigation logic for endorsed activity (e.g., go to profile page)
    navigate(`/chat`, { state: { otherUser:{otherUserId: user._id, displayName: user.displayName, photoUrl: user.profileImageUrl, lastMessage: '', msgTime: new Date()} } });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleShareClick = () => {
    // Handle share action
    handleClose();
  };

  const handleSeeBusinessClick = (id) => {
    // Handle see business pages action
    navigate(`/provider/profile/${user.spId}`, { state: { businessId: 'default', businessName: 'default' } });
    handleClose();
  };

  const handleFollowClick = () => {
    // Handle follow action
    handleClose();
  };

  const handleReportClick = () => {
    // Handle report action
    handleClose();
  };
  const handleEditClick = () => {
    // Handle edit action
    setIsDialogOpen(true);
    handleClose();
  };

  const handleAvatarClick = () => {
    // Handle edit action
    setIsAvatarDialogOpen(true);
    handleClose();
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCloseAvatarDialog = () => {
    setIsAvatarDialogOpen(false);
  };

  
 

  return (
    <Box border={0} borderRadius="borderRadius" p={2} textAlign="center">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={1} >
        <UserPhoto photoUrl={user.profileImageUrl} name={user.displayName} size={50} />
      </Box>
      <Typography variant="h6" fontWeight="bold">
        {user.displayName}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <Typography variant="body2" color="textSecondary" mr={1}>
          Trust Rating: {user.trustRating}
        </Typography>
        {!isMe &&
        <TrustChainDisplay userId={user._id} displayName={user.displayName} spinner={false}/>
        }
        
      </Box>
      <Box mt={4}>
        <Grid container justifyContent="center" spacing={2}>
          {!isMe &&
          <Grid item>
          <Avatar variant="contained" sx={{ backgroundColor: '#0f75f2', color: 'white', cursor:'pointer' }} onClick={() => handleNavigation2Chat(user._id)}>
            <ChatIcon sx={{ fontSize: 16 }} />
          </Avatar>
          </Grid>
          
          }
          

          {
          !isConnected && !isMe && !isPending &&

          <Grid item>
            <Avatar variant="contained" style={{ backgroundColor: '#4CAF50', color: 'white' }}>
              <PersonAddIcon sx={{ fontSize: 16 }} />

            </Avatar>
          </Grid>
          }

          <Grid item>
            <Avatar variant="contained" sx={{ backgroundColor: '#607D8B', color: 'white', cursor: 'pointer' }} onClick={handleClick}>
              <MoreHorizIcon sx={{ fontSize: 16 }} />              
            </Avatar>
            <MoreMenu
                isThisMe={isMe}
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                onShareClick={handleShareClick}
                onSeeBusinessClick={handleSeeBusinessClick}
                onFollowClick={handleFollowClick}
                onReportClick={handleReportClick}
                onEditClick={handleEditClick}
                onAvatarClick={handleAvatarClick}
              />
              <EditProfileDialog open={isDialogOpen} onClose={handleCloseDialog} />
              <AvatarUploadDialog open={isAvatarDialogOpen} onClose={handleCloseAvatarDialog} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UserFacePlate;
