
import {urlsApi} from './urls'


export const getUrlRequestApi = urlsApi.injectEndpoints({
  endpoints: (builder) => ({
    getUrlRequest: builder.query({
      providesTags: (result, error, {requestId}) => [{ type: 'Urls', id: requestId }],
      query: ({requestId, authType, token}) => `/request/${requestId}?authType=${authType}&&token=${token}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetUrlRequestQuery, usePrefetch } = getUrlRequestApi