import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react'
import { fetchAuthSession } from '@aws-amplify/auth'; // Removed getCurrentUser as it's not used
import { useGetUserByEmailQuery } from '../../redux/thunks/users/getuserbyemail';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const UserDashboard = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [user, setUser] = useState(null); // Initialize user state with null

  useEffect(() => {
    const printAccessTokenAndIdToken = async () => {
      try {
        const session = await fetchAuthSession();
        console.log('ID Token:', session.tokens.idToken.toString());
        console.log('session:', session);
        setEmail(session.tokens.idToken.payload.email);
      } catch (e) {
        console.log(e);
      }
    }

    printAccessTokenAndIdToken();
  }, []);

  useEffect(() => {
    if (email) {
      console.log('email', email)
      navigate(`/home2`, { state: { email } });
    }
  }, [email]); // Add email as a dependency to useEffect

  return (
    <Box sx={{ width: '100%', textAlign: 'center', py: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <img
          src="/assets/revfer/skyblue.svg"
          alt="Revfer Logo"
          style={{ width: '100px', height: '50px', marginBottom: '20px' }}
        />
        <Typography variant="h6" gutterBottom>
        </Typography>
        <LinearProgress sx={{ width: '20%', borderRadius: '5px', marginTop: '20px' }} />
      </Box>
    </Box>
  );
};

export default UserDashboard;
