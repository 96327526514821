
import {userApi} from './user'


export const userNotificationsApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserNotifications: builder.query({
      query: () => '/notifications',
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetUserNotificationsQuery, usePrefetch } = userNotificationsApi