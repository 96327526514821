import React, { useState } from 'react';
import { Typography, Button, Box } from '@mui/material';
import FileUpload from './FileUpload';
import { useGetPreSignedUrlQuery } from '../../redux/thunks/files/filespresignurl'; // Import your RTK query
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FullscreenLoader from './FullscreenLoader'; 

import { usePrefetch as useGetSpProfilePrefetch } from '../../redux/thunks/sp/getspprofile';

const SelectPhotoPage = ({ skillId, photoUrl, skillName, businessId, businessName }) => {
  const navigate = useNavigate();
  const thisSpId = useSelector((state) => state.auth.user.spId)
  const token = useSelector((state) => state.auth.token)
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  console.log('skillId in selectedPhoto: ', skillId);
  console.log('photoUrl in selectedPhoto: ', photoUrl);
  console.log('skillName in selectedPhoto: ', skillName);
  console.log('businessId in selectedPhoto: ', businessId);
  console.log('businessName in selectedPhoto: ', businessName);
  const loaderMessage = 'Building your new shiny page';
  //const { data: presignedUrlData, isLoading: isPresignedUrlLoading } = useGetPreSignedUrlQuery(skillId);

  //todo:in place of skillId, we will send businessId, server code need to change 
  const { data: presignedUrlData, isLoading: isPresignedUrlLoading } = useGetPreSignedUrlQuery(businessId);

  console.log('presignedUrlData: ', presignedUrlData);


  const prefetchGetSpProfile = useGetSpProfilePrefetch('getSpProfile')

  const args = {
    spId: thisSpId,
    authType: 'token',
    token: token
  }

  const handleFileChange = (file) => {
    setSelectedImage(file);
  };

  const handleUpload = async () => {
    if (!selectedImage) return; // No image selected

    if (presignedUrlData) {
      const presignedUrl = presignedUrlData.url; // Extract the presigned URL
      const contentType = selectedImage.type; // e.g., 'image/jpeg', 'image/png', etc.

   

      // Set the Content-Type header in the fetch request
      const headers = {
        'Content-Type': contentType,
      };

      try {
        // Use the Fetch API to send a PUT request to the presigned URL
        await fetch(presignedUrl, {
          method: 'PUT',
          body: selectedImage,
          headers
        });

        // Image successfully uploaded
        console.log('Image uploaded successfully!!!');

        // Show fullscreen loader after successful upload
        setIsLoading(true);

        // Simulate a delay for x seconds
        const loaderTimeout = 5000; // Time in milliseconds
        const prefetchTimeout = 2000; // Time in milliseconds
        

        setTimeout(async () => {
          // Prefetch using RTK toolkit query during the second delay
          console.log('Image uploaded successfully!!!111');
          
          prefetchGetSpProfile(args)

          // Wait for the second delay (prefetchTimeout)
          setTimeout(() => {
            // Navigate after the second delay

            console.log('Image uploaded successfully!!!222');
            
            navigate(`/provider/profile/${thisSpId}`, { state: { businessId: businessId, businessName: businessName, skill: 'default' } });
          }, prefetchTimeout);
        }, loaderTimeout);


        
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  return (
    <div>
      {!isLoading &&
      <div>
        
      
      <Typography variant="h6" gutterBottom>
        Select a Photo
      </Typography>
      <FileUpload onFileChange={handleFileChange} />
      {selectedImage && (
        <div>
          <Typography variant="subtitle1">Selected Image:</Typography>
          <img src={URL.createObjectURL(selectedImage)} alt="Selected" style={imageStyles} />
        </div>
      )}
      <Box display="flex" alignItems="center" sx={{mt: 2}}>
            <Button
              variant="contained"
              color="primary"
              disabled={isPresignedUrlLoading}              
              onClick={handleUpload}
              sx={{mr:2}}
              
            >
              Upload Photo
            </Button>
            {/* New button for direct navigation */}
            <Button
              variant="outlined"
              color="primary"             
              
              onClick={() => {
                navigate(`/provider/profile/${thisSpId}`, { state: { businessId: businessId, businessName: businessName, skill: 'default' } });
              }}
            >
              Upload Later
            </Button>
      </Box>
      <Typography variant="caption" gutterBottom>
        or use the one we have selected
      </Typography>
      <div>
         
          <img src={photoUrl} alt="Selected" style={imageStyles} />
        </div>

      
      </div>
      
      }
      
      <div>
        {isLoading && <FullscreenLoader message={loaderMessage} />}

      </div>

    </div>
    

    
  );
};

const imageStyles = {
  maxWidth: '100%',
  maxHeight: '200px',
  marginTop: '16px',
};

export default SelectPhotoPage;
