
import {userApi} from './user'


export const userTrustchainApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserTrustchain: builder.query({      
      query: (userId) => `/trusteduser/chain/${userId}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetUserTrustchainQuery, usePrefetch } = userTrustchainApi