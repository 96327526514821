import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const ProvidersSort = ({ results, setFilteredResults, currentPage, handlePageChange  }) => {
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedName, setSelectedName] = useState('');

  const [sortOption, setSortOption] = useState('starRating'); 
  console.log('in provider sort results', results)


  const handleSortChange = (event) => {
    const selectedSortOption = event.target.value;
    setSortOption(selectedSortOption);
    // Sort the results based on the selected option
    let sortedResults = [...results];
    if (selectedSortOption === 'starRating') {
      sortedResults = sortedResults.sort((a, b) => b.starRating - a.starRating);
    } else if (selectedSortOption === 'smartRating') {
      sortedResults = sortedResults.sort((a, b) => b.smartRating - a.smartRating);
    }
    setFilteredResults(sortedResults);
    // Update the filtered results based on the current page and new sorting
    handlePageChange(currentPage);
  };


 



  
  

  return (
    <FormControl variant="outlined" sx={{ minWidth: 120 }}>
        <InputLabel htmlFor="sort-options">Sort By</InputLabel>
        <Select
        value={sortOption}
        onChange={handleSortChange}
        label="Sort By"
        inputProps={{
            id: 'sort-options',
        }}
        >
        <MenuItem value="starRating">Star Rating</MenuItem>
        <MenuItem value="smartRating">Smart Rating</MenuItem>
        </Select>
    </FormControl>
  );
};

export default ProvidersSort;
