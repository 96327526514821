import React from 'react';
import { Box } from '@mui/material';
import ProvidersSort from './SortTypes/ProvidersSort';
import UrlFilters from './SortTypes/UrlSort';

const SortSection = ({ results, setFilteredResults, currentPage, handlePageChange, type}) => {
  console.log('in sort section, type', type)
  
  

  return (
    <Box>
    
          <Box mb={1}>
            {type === 'provider' && <ProvidersSort results={results} setFilteredResults={setFilteredResults} currentPage={currentPage} handlePageChange={handlePageChange} />}
            {type === 'restaurants' && <ProvidersSort results={results} setFilteredResults={setFilteredResults} currentPage={currentPage} handlePageChange={handlePageChange}/>}
            {type === 'url' && <UrlFilters results={results} setFilteredResults={setFilteredResults} currentPage={currentPage} handlePageChange={handlePageChange}/>}
            {/* Add more conditions for other types if needed */}
          </Box>
        
    </Box>
  );
};

export default SortSection;