
import {urlsApi} from './urls'

export const urlPostCommentApi = urlsApi.injectEndpoints({
  endpoints: (builder) => ({
    
    urlPostComment: builder.mutation({
      invalidatesTags: (result, error, {payload}) => ([
        { type: 'Urls', id: payload.requestId }
      ]),
      query: ({payload}) => ({
        url: '/request/comments',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useUrlPostCommentMutation } = urlPostCommentApi