// authActions.js
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

//const backendURL = 'https://devapi.revfer.com/signup'

const backendURL = process.env.REACT_APP_PROXY_PREFIX + 'https://devapi.revfer.com/signup'

export const registerUser = createAsyncThunk(
  'auth/register',
  async ({ username, password, firstName, lastName }, { rejectWithValue }) => {
    try {
      console.log('inside api action username', username)
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post( `${backendURL}/user/signup`, { username, password, firstName, lastName },  config )
      console.log('signup response.data', response.data)
      return response.data
      
    } catch (error) {
      console.log('auth action error', error )
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        console.log('auth action error', error )
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const signinUser = createAsyncThunk(
  'auth/signin',
  async ({ username: email, password }, { rejectWithValue }) => {
    try {
      console.log('inside api action username', email)
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post( `${backendURL}/user/signin`, { email, password },  config )
      console.log('signin response.data', response.data)
      return response.data
      
    } catch (error) {
      console.log('auth action error', error )
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        console.log('auth action error', error )
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
