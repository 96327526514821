import React from 'react';
import {
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
  Box, // Import Box component
} from '@mui/material';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useUrlCreateMutation } from '../../redux/thunks/urls/urlcreate'; // Import your RTK mutation function
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const validationSchema = Yup.object().shape({
  url: Yup.string().url('Invalid URL').required('URL is required'),
  keywords: Yup.string().required('Keywords are required'),
});

const RequestForm = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  const [createRequest, { data: response, isLoading }] = useUrlCreateMutation();

  const initialValues = {
    url: '',
    keywords: '',
  };

  const handleSubmit = async (values) => {
    console.log('values in url request creation', values)

    try {
      const auth = {
        token: token,
        authType: 'token',
      };
      const payload = {
        auth,
        url: values.url,
        text: values.keywords
      };
      // Call your RTK mutation to create the request
      const response = await createRequest({payload});
      console.log('response in url request creation', response)

      // Handle success, e.g., show a success message, then navigate to the request detail page
      navigate(`/url/request/${response.data.data._id}`);
      
    } catch (error) {
      // Handle error, e.g., display an error message
      console.error('Error creating request:', error);
    }
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      {isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
          <Typography variant="subtitle1">
            We are building the request...
          </Typography>
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form
            style={{
              width: '100%',
              marginTop: '16px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h5" style={{ marginBottom: '16px' }}>
              Create a New Request
            </Typography>
            <Box
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.1)', // Border style
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Shadow style
                padding: '16px', // Padding inside the box
              }}
            >
              <Field
                name="url"
                label="URL"
                fullWidth
                variant="outlined"
                as={TextField}
                placeholder="Enter URL"
                sx={{ marginBottom: 2 }}
              />
              <Field
                name="keywords"
                label="Keywords"
                fullWidth
                variant="outlined"
                as={TextField}
                placeholder="Enter Keywords"
                sx={{ marginBottom: 2 }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                Submit
              </Button>
            </Box>
          </Form>
        </Formik>
      )}
    </Container>
  );
};

export default RequestForm;
