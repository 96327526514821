import React from 'react';
import * as Utils from '../../../Functions/Utilities';
import UserPhoto from '../../../Components/UserPhoto/UserPhoto';

const Restaurant = ({ activity }) => {

  let initials = activity.otherDisplayName ? `${activity.otherDisplayName.split(' ')[0][0].toUpperCase()}${activity.otherDisplayName.split(' ')[1][0].toUpperCase()}` : "RV";
  const truncatedSummary = activity.summary ? Utils.truncateText(activity.summary, 2) : ''

  const handleNavigation = () => {
    // Define navigation logic for endorsed activity (e.g., go to an endorsement details page)
  };

  
  
  return (
    <div className="border p-4 rounded mb-4" onClick={handleNavigation} style={{ cursor: 'pointer' }} >
      <div className="flex items-center mb-2">
        <div className="mr-2 text-sm">
          <strong>{activity.userDisplayName}</strong> endorsed {activity.otherDisplayName} for {activity.skillName} 
          
        </div>        
      </div>
      <hr className="border-t border-gray-300 mb-2" />
      <div className="flex items-start mb-2">
        <UserPhoto photoUrl={activity.otherPhoto} initials={initials} size={14} />
        <div className="ml-2 flex-grow">
          <h2 className="text-lg font-bold  my-2 text-left">{activity.otherDisplayName}</h2>          
          <p className=" text-left">{truncatedSummary}</p>
        </div>
      </div>
    </div>
  );
};

export default Restaurant;