
import {userApi} from './user'

//todo: invalidate tag for both the users here 

export const userAddTrustedUserResApi = userApi.injectEndpoints({
  endpoints: (builder) => ({   
    
    AddTrustedUserRes: builder.mutation({
      invalidatesTags: (result, error, {userId}) => ([
        { type: 'User', id: userId },
        'IncomingReq',
      ]),
      query: ({ userId, ...payload}) => ({
        url: '/trusteduser/response',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useAddTrustedUserResMutation } = userAddTrustedUserResApi

