import React from 'react';
// mui
import {
  Box,
  Container,
  Grid,
  Typography,
  Stack,
  Button,
  Chip,
} from '@mui/material';
// icons
import { BiMailSend } from 'react-icons/bi';

import { AiOutlineLineChart } from 'react-icons/ai';
import { MdSettings } from 'react-icons/md';

export default function IntegrateSDK() {
  return (
    <Box my={{ xs: 8, md: 20 }}>
      <Container>
        <Stack
          mb={5}
          spacing={1.5}
          alignItems='center'
          justifyContent='center'>
          <Box>
            <Chip
              label='How it works'
              sx={{
                color: 'primary.main',
                bgcolor: 'rgba(0, 85, 255, 0.12)',
                fontSize: 13,
                fontWeight: 600,
                px: 1,
              }}
            />
          </Box>
          <Typography
            variant='h3'
            fontWeight={600}
            textAlign={{ xs: 'center', md: 'left' }}
            fontSize={28}>
            Harness the Power of Trusted Networks
          </Typography>
          <Typography
            variant='body2'
            fontSize={15}
            fontWeight={400}
            textAlign={{ xs: 'center', md: 'left' }}
            color='text.secondary'>
            Build Authentic Connections and{' '}
            <Typography
              variant='body2'
              component='span'
              fontWeight={600}
              color='primary.main'>
              Drive
            </Typography>{' '}
            Business Growth
          </Typography>
        </Stack>
        <Grid
          container
          alignItems='center'
          spacing={{ xs: 4, md: 5 }}>
          {data.map((item, index) => (
            <Grid
              key={index}
              item
              xs={12}
              md={4}>
              <Stack
                alignItems={{ xs: 'center', md: 'start' }}
                spacing={1}>
                <Box
                  sx={{
                    height: 48,
                    width: 48,
                    minWidth: 48,
                    borderRadius: 1,
                    color: item.color,
                    bgcolor: item.bgcolor,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  {item.icon}
                </Box>
                <Typography
                  variant='subtitle'
                  fontWeight={500}
                  pt={1}
                  fontSize={18}>
                  {item.name}
                </Typography>
                <Typography
                  variant='body1'
                  fontSize={15}
                  color='text.secondary'>
                  {item.detail}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

const data = [
  {
    icon: <BiMailSend size={24} />,
    color: '#0055FF',
    bgcolor: 'rgba(0, 85, 255, 0.12)',
    name: 'Create Your Profile',
    detail:
      'Set up a detailed business profile showcasing your products, services, and unique offerings.',
  },
  {
    icon: <AiOutlineLineChart size={24} />,
    name: 'Engage with Customers',
    color: '#FF784B',
    bgcolor: 'rgba(255, 120, 75, 0.12)',
    detail:
      'Respond to reviews, answer questions, and build strong relationships with your customers.',
  },
  {
    icon: <MdSettings size={24} />,
    name: 'Gain Valuable Insights',
    color: '#17A2B8',
    bgcolor: 'rgba(23, 162, 184, 0.12)',
    detail:
      'Access comprehensive data and analytics to understand customer preferences, improve your services, and drive business growth.',
  },
];
