import React from 'react';
import { useSelector } from 'react-redux';
import { Paper, Typography, Avatar, Grid, Divider, Box } from '@mui/material'; // Import Material-UI components
import { generateColorFromInitials } from '../../Functions/Utilities'; // Import your utility function
import UserPhoto from '../../Components/UserPhoto/UserPhoto';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';


const UserInfoSection = () => {
  const navigate = useNavigate();

  // Retrieve user data from the Redux store
  const user = useSelector((state) => state.auth.user);
  const userId = useSelector((state) => state.auth.userId);
  
  const handleNavigation2Profile = (id) => {
    // Define navigation logic for endorsed activity (e.g., go to profile page)
    navigate(`/profile/${id}`);
  };  

  const handleSeePremiumClick = () => {
    // Add your logic here to handle endorsements (including removal)
    navigate(`/pricing`);
  };

  return (
    <Paper variant="outlined" sx={{ padding: 1, border: 'none'}}>
      <Grid container direction="column" alignItems="center">
        {/* User Avatar */}
        <UserPhoto photoUrl={user.profileImageUrl}  name={user.displayName} size={90} />
        <Typography variant="subtitle1" fontWeight="bold" mt={2} sx={{cursor: 'pointer' }} onClick={() => handleNavigation2Profile(userId)}>
          {user.displayName}
          
        </Typography>
        <Divider sx={{ width: '100%', my: 1 }} />
        <Grid container direction="column" justifyContent='flex-start' >
        <Typography variant="caption" color="textSecondary" mt={1}>
          Network Size: {130}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Trust Rating: {user.trustRating}
        </Typography>

        </Grid>
       

        {/* Horizontal Line */}
        <Divider sx={{ width: '100%', my: 1 }} />
        {user.accountDetails.type === 'free'  &&

          <Box sx={{display:'flex', cursor:'pointer'}} onClick={handleSeePremiumClick}>
            <DiamondOutlinedIcon sx={{ fontSize:15, color: 'gold.main'}} />
        
            <Typography variant="subtitle2" color="textSecondary" fontSize={10}>
            Try Revfer premium
            </Typography>
          </Box>
         }
         {user.accountDetails.type !== 'free'  &&

            <Box sx={{display:'flex', cursor:'pointer'}} onClick={handleSeePremiumClick}>
              <DiamondOutlinedIcon sx={{ fontSize:15, color: 'gold.main'}} />

              <Typography variant="subtitle2" color="textSecondary" fontSize={10}>
              See your premium features
              </Typography>
            </Box>  
          }

        {/* Network Stats */}
        <Typography variant="h6" fontWeight="bold">
          {/* Place holder */}
          
        </Typography>
        <Typography variant="body2" color="textSecondary" mt={1}>
          {/* Place holder */}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {/* Place holder */}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {/* Place holder */}
        </Typography>
      </Grid>
    </Paper>
  );
};

export default UserInfoSection;
