import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
} from '@mui/material';
import ReviewDetails from './ReviewDetails';
import { useGetSpReviewQuery } from '../../redux/thunks/sp/spreviewget';
import { useSelector } from 'react-redux';



const ShowReviews = ({ skill }) => {

  console.log('skill in auauthenticated reviews', skill)
  
  //const token = useSelector((state) => state.auth.token) 
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState('date');
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    if (skill.reviews && skill.reviews.length > 0) {
      setFilteredResults(skill.reviews);
    }
  }, [skill.reviews]);
  console.log('in show reviews, skill.reviews', skill.reviews)
  const reviewsPerPage = 10;

  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = filteredResults.slice(indexOfFirstReview, indexOfLastReview);






  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSortingChange = (event) => {
    const newSortBy = event.target.value;
    setSortBy(newSortBy);

    const sortedResults = [...reviews];
    if (newSortBy === 'date') {
      sortedResults.sort((a, b) => b.created - a.created);
    } else if (newSortBy === 'ratings') {
      sortedResults.sort((a, b) => b.rating - a.rating);
    }

    setFilteredResults(sortedResults);
  };

  console.log('in show reviews, currentReviews', currentReviews)
  console.log('in show reviews, filteredResults', filteredResults)


  return (
    <Box
      sx={{
        mb: 1,
        
        
      }}
    >
      <Box 
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
          border: '1px solid rgba(0, 0, 0, 0.1)', mb:1, mt:4, borderRadius: '20px'  
        }}
      >
        <Typography>
          Showing Reviews {Math.min(currentPage * reviewsPerPage, skill.reviews.length)} of {skill.reviews.length}
        </Typography>
        <div>
        <Typography> Sort by: </Typography>
          <Select
            value={sortBy}
            onChange={handleSortingChange}
            sx={{ ml: 2 }}
          >
            <MenuItem value="date">Posting Date</MenuItem>
            <MenuItem value="ratings">Ratings</MenuItem>
          </Select>
        </div>
      </Box>
      {currentReviews.map((review) => (
        <ReviewDetails key={review._id} review={review} />
      ))}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 4,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          sx={{ mr: 2 }}
        >
          Previous
        </Button>
        {Array.from({ length: Math.ceil(skill.reviews.length / reviewsPerPage) }).map(
          (_, index) => (
            <Button
              key={index}
              variant="contained"
              color={currentPage === index + 1 ? 'primary' : 'secondary'}
              onClick={() => handlePageChange(index + 1)}
              disabled={currentPage === index + 1}
              sx={{ mr: 2 }}
            >
              {index + 1}
            </Button>
          )
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === Math.ceil(skill.reviews.length / reviewsPerPage)}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default ShowReviews;
