// src/redux/reducers.js
import { combineReducers } from 'redux';
import userReducer from './slices/userSlice';
import AuthReducer from './slices/auth';
import notificationSlice from './slices/notificationSlice';
import chatSlice from './slices/chatSlice';
import { userApi } from './thunks/users/user'
import { spApi } from './thunks/sp/sp'
import { aiApi } from './thunks/ai/ai'
import { thingsApi } from './thunks/things/things'
import { iconsApi } from './thunks/icons/icons'
import { statsApi } from './thunks/stats/stats'
import { filesApi } from './thunks/files/files'
import { urlsApi } from './thunks/urls/urls'
import { unauthApi } from './thunks/unauth/unauth'




const rootReducer = combineReducers({
  user: userReducer,
  auth: AuthReducer,
  chat: chatSlice,
  notifications: notificationSlice,
  [userApi.reducerPath]: userApi.reducer,
  [spApi.reducerPath]: spApi.reducer,
  [aiApi.reducerPath]: aiApi.reducer,
  [thingsApi.reducerPath]: thingsApi.reducer,
  [iconsApi.reducerPath]: iconsApi.reducer,
  [statsApi.reducerPath]: statsApi.reducer,
  [filesApi.reducerPath]: filesApi.reducer,
  [urlsApi.reducerPath]: urlsApi.reducer,
  [unauthApi.reducerPath]: unauthApi.reducer,

  // Add other reducers here
});

export default rootReducer;
