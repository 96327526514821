import React, {useState, useEffect} from 'react';
import { Container, Grid, Box, Paper, Typography } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';




import PhotoCard from "../../Components/ByteSize/PhotoCards/PhotoCard";
import Footer from "../../Components/Footer/Footer";
import SearchBox from './SearchBox';
import UserCard from './UserCard';
import EmailInvite from "../../Components/EmailInvite/EmailInvite";









const UserSearchResults = () => {

  const location = useLocation();
  
  const searchResults = location.state?.searchResults || [];
  const query = location.state?.query || [];
  const token = useSelector((state) => state.auth.token);
  const pageSize = 10;

  const [currentPage, setCurrentPage] = useState(1);   


  return (
    <Container maxWidth="lg">
      {/* First Full-Width Row */}
      <Box  mb={2}>
        <Grid container>
          <Grid item xs={12}>
            {/* Content for the first full-width row */}
            {/* You can add your content here */}
            <SearchBox />
          </Grid>
        </Grid>
      </Box>

      {/* Second Full-Width Row */}
      <Box border="1px solid #e0e0e0" mb={2}>
        <Grid container>
          <Grid item xs={12}>
            {/* Content for the second full-width row */}
            {/* You can add your content here */}
     
          </Grid>
        </Grid>
      </Box>

      {/* Three Columns Row */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Paper variant="outlined" sx={{ mt:2, p: 2 }}>
            {/* Content for the first column */}
            <Typography variant="subtitle2" gutterBottom style={{ cursor: 'pointer' }} >
                Leran more about Revfer
            </Typography>
            <Link
              to={'https://www.revfer.com'}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                Help Center
              </Typography>
            </Link>            
          </Paper>

          <Paper variant="outlined" sx={{ mt:2, p: 2 }}>
            {/* Content for the first column */}
            <Typography variant="subtitle2" gutterBottom style={{ cursor: 'pointer' }} >
                Send Email invitation
            </Typography>
            <EmailInvite/>
               
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
          {searchResults && searchResults.length > 0 ? (
            searchResults.map((user) => (
            <Box key={user._id} sx={{ marginBottom: 1 }}>
              <UserCard user={user} />
            </Box>
             ))
             ) : (
             <Typography variant="body1">No user found</Typography>
         )}
        </Paper>
         

         

        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper variant="outlined" sx={{ mt: 2,border: 'none'}}>
            {/* Content for the third column */}
            <PhotoCard type={'NeedReview'} />
          </Paper>

          <Paper variant="outlined" sx={{ padding: '10px', border: 'none'  }}>
            <Footer/>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserSearchResults;
