import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import UserPhoto from '../../../Components/UserPhoto/UserPhoto';
import { Card, CardContent, Typography, Divider, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { usePrefetch as useGetUserProfilePrefetch } from '../../../redux/thunks/users/userprofile';
import { usePrefetch as useGetSpProfilePrefetch } from '../../../redux/thunks/sp/getspprofile';
import { usePrefetch as useGetSpReviewPrefetch } from '../../../redux/thunks/sp/spreviewget';

import FavoriteIcon from '@mui/icons-material/Favorite';


const EndorsedActivity = ({ activity }) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token)
  const prefetchGetUserProfile = useGetUserProfilePrefetch('getUserProfile')   
  const prefetchGetSpProfile = useGetSpProfilePrefetch('getSpProfile') 
  const prefetchGetSpReview = useGetSpReviewPrefetch('getSpReview')

  const args = {
    spId: activity.spId,
    authType: 'token',
    token: token
  }
  useEffect(() => { 
          
    const prefetchAll = async () => {    
     
      await Promise.all([
      
        prefetchGetUserProfile(activity.userId), 
        prefetchGetSpProfile(args),
        //prefetchGetSpReview(args)
      
        
      ]);        
    }    
    prefetchAll();
      
        

    
  }, [activity]);

  const handleNavigation = (spId, skillId, skillName ) => {
    // Define navigation logic for review activity (e.g., go to an endorsement details page)
    navigate(`/provider/profile/${spId}`, { state: { businessId: 'default', businessName: 'default' } });
  };
  const handleNavigation2Profile = (id) => {
    // Define navigation logic for endorsed activity (e.g., go to profile page)
    navigate(`/profile/${id}`);
  };
  console.log('activity in endosements', activity)

  return (
    <Card variant="outlined" sx={{ mb:1, border: 'none', borderRadius: 2 }}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2} sx={{cursor: 'pointer'}} onClick={() => handleNavigation2Profile(activity.userId)}>
          <UserPhoto photoUrl={activity.userPhoto} name={activity.userDisplayName} size={16} />  
          <Typography variant="caption" sx={{ml: 1}}>
            <strong>{activity.userDisplayName}</strong> endorsed {activity.businessName} 
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" alignItems="center" mt={2}>
          
          <Box display="flex" ml={2} flexGrow={1} sx={{cursor: 'pointer'}} onClick={() => handleNavigation(activity.spId, activity.skillId, activity.skillName )}>
          <UserPhoto photoUrl={activity.skillIconUrl} name={activity.businessName} size={32} variant={'square'}/>  
            <Typography variant="h6" component="div" sx={{ml: 1}}> 
              {activity.businessName}
                <Typography  component="div" > 
                {activity.skillName}              
                </Typography>                
            </Typography>
            
            <Typography variant="h6" component="div">
              {activity.otherDisplayName}
            </Typography>
            
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default EndorsedActivity;
