import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { fetchAuthSession } from "aws-amplify/auth";



const url = process.env.REACT_APP_PROXY_PREFIX + 'https://devapi.revfer.com/unauth'
console.log('url', url)

export const unauthApi = createApi({
  reducerPath: 'unauthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: async (headers, { getState }) => {
        //const token = getState().auth.token
        //console.log('token', token)
        //const session = await fetchAuthSession(); 
        //const token = session.tokens.idToken.toString();
        //console.log('my token in sp base api', token)
        headers.set("Content-Type", "application/json")
    
        // If we have a token set in state, let's assume that we should be passing it.
        /*
        if (token) {

          headers.set('Authorization', `${token}`)
          headers.set("Content-Type", "application/json")
        } */
        //console.log('headers', headers)
    
        return headers
      },
  }),
  //tagTypes: ['sp', 'spreviews', 'Spprofile'],
  endpoints: () => ({}),
})
