import React from 'react';
// mui
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Button,
} from '@mui/material';
// icons
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default function PricingCard({ plan }) {
  return (
    <Card
      sx={{
        borderRadius: '10px',
        boxShadow: 'none',
        bgcolor: plan.name === 'Basic' ? 'common.white' : '#F6F8FA',
        position: 'relative',
        overflow: 'unset',
        boxShadow:
          plan.name === 'Basic'
            ? 'rgba(145, 158, 171, 0.25) 0px 6px 16px'
            : 'none',
        ':before': {
          content: "''",
          position: 'absolute',
          background: 'url(tag.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          height: { xs: 67, md: 97 },
          width: { xs: 62, md: 92 },
          top: -13,
          right: 0,
          transform: 'translateX(-50%)',
        },
      }}>
      <CardContent
        sx={{
          p: { xs: 2, md: '56px !important' },
        }}>
        <Typography
          fontSize={{ xs: 18, md: plan.name === 'Lite' ? 34 : 24 }}
          fontWeight={800}
          variant='h6'
          color='common.white'
          sx={{
            position: 'absolute',
            height: { xs: 67, md: 97 },
            width: { xs: 62, md: 92 },
            top: { xs: 10, md: plan.name === 'Lite' ? 5 : 10 },
            right: {
              xs: plan.name === 'Lite' ? -5 : 0,
              md: plan.name === 'Lite' ? 0 : -5,
            },
            transform: 'translateX(-30%)',
          }}>
          {plan.price}
        </Typography>
        <Stack spacing={0.5}>
          <Typography
            variant='h4'
            fontWeight={900}
            fontSize={30}>
            {plan.name}
          </Typography>
          <Typography
            variant='body2'
            fontSize={14}
            color='text.secondary'>
            {plan.description}
          </Typography>
          <List
            sx={{
              px: 0,
              '& .MuiListItem-root': {
                px: 0,
              },
              '& .MuiTypography-root': {
                fontSize: 16,
                color: 'text.secondary',
              },
            }}>
            {plan.list.map((v) => (
              <ListItem key={Math.random()}>
                <ListItemIcon
                  sx={{
                    minWidth: 24,
                    svg: {
                      fontSize: 8,
                    },
                  }}>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText>{v}</ListItemText>
              </ListItem>
            ))}
          </List>
          <Stack
            sx={{
              pb: 4,
            }}>
            <Typography
              variant='body1'
              fontSize={14}
              color='primary'>
              $0.50/subscriber
            </Typography>
            <Typography
              variant='body2'
              fontSize={14}
              color='text.secondary'>
              (per subscriber per month)
            </Typography>
          </Stack>
          <Button
            sx={{
              borderRadius: '10px',
              border: (theme) => `1px solid ${theme.palette.divider}`,
              textTransform: 'math-auto',
              height: 48,
              fontWeight: 300,
              boxShadow: 'none',
            }}
            size='large'
            variant={plan.name === 'Basic' ? 'contained' : 'outlined'}
            fullWidth
            color={plan.name === 'Basic' ? 'primary' : 'inherit'}>
            Start free trial
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}