
import {userApi} from './user'

//Get list of things this user follows
export const userEndorsementsApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    providesTags:['Endorsements'],
    getUserEndorsements: builder.query({
      providesTags:['Endorsements'],
      query: () => `/endorsements`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetUserEndorsementsQuery, usePrefetch } = userEndorsementsApi