import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const UrlSort = ({ results, setFilteredResults, currentPage, handlePageChange  }) => {
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedName, setSelectedName] = useState('');

  const [sortOption, setSortOption] = useState('newest'); 


  const handleSortChange = (event) => {
    const selectedSortOption = event.target.value;
    setSortOption(selectedSortOption);
  
    // Sort the results based on the selected option
    let sortedResults = [...results];
    if (selectedSortOption === 'newest') {
      sortedResults = sortedResults.sort((a, b) => {
        const dateA = typeof a.creationTime === 'string' ? new Date(a.creationTime) : a.creationTime;
        const dateB = typeof b.creationTime === 'string' ? new Date(b.creationTime) : b.creationTime;
        return dateB - dateA;
      });
    } else if (selectedSortOption === 'oldest') {
      sortedResults = sortedResults.sort((a, b) => {
        const dateA = typeof a.creationTime === 'string' ? new Date(a.creationTime) : a.creationTime;
        const dateB = typeof b.creationTime === 'string' ? new Date(b.creationTime) : b.creationTime;
        return dateA - dateB;
      });
    }
    
    setFilteredResults(sortedResults);
    handlePageChange(currentPage);
  }; 
  

  return (
    <FormControl variant="outlined" sx={{ minWidth: 120 }}>
      <InputLabel htmlFor="sort-options">Sort By</InputLabel>
      <Select
        value={sortOption}
        onChange={handleSortChange}
        label="Sort By"
        inputProps={{
          id: 'sort-options',
        }}
      >
        <MenuItem value="newest">Newest</MenuItem>
        <MenuItem value="oldest">Oldest</MenuItem>
      </Select>
    </FormControl>
  );
};

export default UrlSort;
