import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    gray: {
      main:'#cdcdcd',
    },
    gold: {
      main: '#FFD700'
    }
  },
  text: {
    secondary: 'rgba(138, 144, 162, 1)',
  },
  typography: {
    fontFamily: '"Be Vietnam Pro", sans-serif',
  },
});

export default theme;
