// MultiPageForm.js
import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, Paper, Box, CircularProgress, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import FullscreenLoader from './FullscreenLoader'; 
import { usePrefetch as useGetSpProfilePrefetch } from '../../redux/thunks/sp/getspprofile';
import { Link, useNavigate } from 'react-router-dom';


import BusinessNamePage from './BusinessNamePage';
import CategoryPage from './CategoryPage';
import AddressPage from './AddressPage';

import TextCard from "../../Components/ByteSize/TextCards/TextCard";


import { usePrefetch as useGetAllSkillsPrefetch } from '../../redux/thunks/things/thingsget';
import { useThingAddMutation  } from '../../redux/thunks/things/thingadd';

const steps = ['Business Name', 'Category', 'Address'];

const BusinessSolicit = () => {
  const navigate = useNavigate();

  const userId = useSelector((state) => state.auth.userId);  
  const token = useSelector((state) => state.auth.token);  
  const spId = useSelector((state) => state.auth.user.spId);  
  const displayName = useSelector((state) => state.auth.user.displayName);
  const loaderMessage = 'Please wait, while we setup the page';

  const [isLoading, setIsLoading] = useState(false);

 

  const prefetchGetAllSkills = useGetAllSkillsPrefetch('getAllThings') 
  //const prefetchGetPreSignedUrl = useGetPreSignedUrlPrefetch('getPreSignedUrl') 
  const [addThing, { isLoading: skillAddLoading, isError }] = useThingAddMutation();

  const prefetchGetSpProfile = useGetSpProfilePrefetch('getSpProfile')

  const args = {
    spId: spId,
    authType: 'token',
    token: token
  }

  useEffect(() => {
    
    const prefetchAll = async () => {
      const args = {
        spId: spId,
        authType: 'token',
        token: token
      }

      await Promise.all([
        prefetchGetAllSkills(args),
        //prefetchGetPreSignedUrl()
        
      ]);
    
    }

    prefetchAll();
   
    

  }, []);



 
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    businessName: '',
    isRepresentative: false,
    category: '',
    address: '',
   
  });
  const isLastStep = activeStep === steps.length - 1;


  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (values) => {
    if (isLastStep) {
      try {
        // Handle form submission (e.g., send data to the server)
        const auth = {
          token: token,
          authType: 'token',
        };
      
        const payload = {
          auth,
          skillId: values.category.skillId,
          spId,
          data: values
        };
        await addThing(payload);
        console.log('Form data submitted:', values);
        setIsLoading(true);

        // Simulate a delay for x seconds
        const loaderTimeout = 5000; // Time in milliseconds
        const prefetchTimeout = 2000; // Time in milliseconds

        setTimeout(async () => {
          // Prefetch using RTK toolkit query during the second delay
          
          prefetchGetSpProfile(args)

          // Wait for the second delay (prefetchTimeout)
          setTimeout(() => {
            // Navigate after the second delay
            navigate(`/provider/profile/${spId}`, { state: { skillId: values.category.skillId, skillName: values.category.name } });
          }, prefetchTimeout);
        }, loaderTimeout);

    

        // Set the form as submitted
        setIsFormSubmitted(true);
      } catch (error) {
         // Handle submission error
         setSubmissionError(error.message);
      }


    } else {
      setActiveStep((prevStep) => prevStep + 1);
      
    }
    
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BusinessNamePage
            formData={formData}
            setFormData={setFormData}
            
          />
        );
      case 1:
        return (
          <CategoryPage
            formData={formData}
            setFormData={setFormData}
            
          />
        );
      case 2:
        return (
          <AddressPage
            formData={formData}
            setFormData={setFormData}
           
          />
        );
     
      default:
        return null;
    }
  };
  return (
    <div>
      {(!isLoading && !skillAddLoading) &&
        <Box display="flex" flexDirection="column" alignItems="center">
        <Stepper activeStep={activeStep} alternativeLabel>
             {steps.map((label) => (
             <Step key={label}>
               <StepLabel>{label}</StepLabel>
             </Step>
           ))}
           </Stepper>
         <Paper variant="outlined" sx={{ m: 3, p: 3, minHeight: '400px', width: '500px', border: 'none' }}>
         <Formik
               initialValues={formData}
               onSubmit={(values) => {
                 // Handle form submission (e.g., send data to the server)
                 handleSubmit(values);
               }}
             >
               {({values}) => (
                 <Form>
                   {getStepContent(activeStep)}
                   <Box display='flex'>
                     {activeStep !== 0 && (
                       <Button onClick={handleBack} sx={{ mt: 3, mr: 2 }}>
                         Back
                       </Button>
                     )}
                     {isLoading ? (
                       <CircularProgress color="primary" sx={{ mt: 3 }} />
                     ) : (
                       <div>
                         <Button variant="contained" color="primary" type="submit" disabled={values.isRepresentative} sx={{ mt: 3 }}>
                         {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                       </Button>
                       {values.isRepresentative &&
   
                       <Box display='flex' sx={{ mt: 3 }}>
                         <TextCard type={'StartBusiness'} sx={{ mr: 1, boxShadow: 'none', border: '1px solid rgba(0, 0, 0, 0.1)', borderLeft: '2px solid #0000FF' }}/>
                         <TextCard type={'SeekProductReview'} sx={{  boxShadow: 'none', border: '1px solid rgba(0, 0, 0, 0.1)', borderLeft: '2px solid #0000FF'}}/>
                       </Box>
   
                       
                       
                         
                       }
                       
   
                       </div>
                       
                     )}
                   </Box>
                 </Form>
                 
               )}
               
             </Formik>
           {isError && <div>Error: Something went wrong during submission.</div>}
           {submissionError && <div>Error: {submissionError}</div>}
         </Paper>
        </Box>

      }
      <div>
        {(isLoading || skillAddLoading) && <FullscreenLoader message={loaderMessage} />}

      </div>
      

    </div>
    
  );

  
};

export default BusinessSolicit;
