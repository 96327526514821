import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Avatar,
  Rating,
  Grid,
  IconButton,
  TextField,
  Button,
  Divider,
} from '@mui/material';
import { MdThumbUp, MdThumbDown, MdComment, MdSend } from 'react-icons/md';
import {Link, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';


import { useUrlPostCommentMutation } from '../../redux/thunks/urls/urlpostcomment';




const ReviewCommentsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const review = location.state;
  

  // Assuming 'comments' is an array of comment objects in the review
  const [comments, setComments] = useState(review.comments || []);
  const [newComment, setNewComment] = useState('');
  const [postComment, { isLoading }] = useUrlPostCommentMutation();
  const token = useSelector((state) => state.auth.token) 
  const thisUserPhoto = useSelector((state) => state.auth.user.profileImageUrl) 
  const thisUserdisplayName = useSelector((state) => state.auth.user.displayName) 


  // Handle adding a new comment
  const handleAddComment = async () => {
    if (newComment.trim() === '') {
      // Don't add empty comments
      return;
    }

    let auth = {
        token: token,
        authType: 'token'
      }
      let latestComment = {
        comment: newComment,
        reviewId: review.reviewId
      }
      

      const payload = {
        auth,
        review:latestComment,
        requestId: review.requestId
      };

      await postComment({ payload });
      const updatedComments = [...comments, { displayName: thisUserdisplayName, comment: newComment, created: new Date(), photo: thisUserPhoto }];
      setComments(updatedComments);
      navigate(`/url/request/${review.requestId}`);
     
      // Clear the new comment input
      setNewComment('');
  };



  return (
    <Box sx={{ p: 2 }}>
      <Paper variant="outlined" sx={{ padding: '10px', mb: 2 }}>
        {/* First Row */}
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar src={review.photo} alt={review.displayName} sx={{ width: 60, height: 60, mr: 2 }} />
          <Typography variant="h6">{review.displayName}</Typography>
          {/* Add more information components here */}
        </Box>

        {/* Star Rating */}
        <Box mb={2}>
          <Rating value={review.rating} precision={0.5} readOnly />
        </Box>

        {/* Second Row */}
        <Typography variant="body1" mb={2}>
          {review.review}
        </Typography>
      </Paper>

      {/* Comments Section */}
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
        Comments ({comments.length})
      </Typography>

      {/* Render comments */}
      {/* Render comments */}
        {comments.map((comment, index) => {
        // Calculate time difference using Moment.js
        const commentDate = moment(comment.created);
        const currentDate = moment();
        const timeDifference = currentDate.diff(commentDate, 'days');

        // Format the date as "x days ago"
        const formattedDate = timeDifference === 1 ? '1 day ago' : `${timeDifference} days ago`;

        return (
            <Box
            key={index}
            sx={{
                borderTop: '1px solid #ccc',
                
                padding: '10px',
                mb: 2,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
            >
            <Typography variant="body1" sx={{ flex: 1, marginRight: '10px' }}>{comment.comment}</Typography>
            {/* Add commenter name and formatted date */}
            {/* Make the commenter's name clickable */}
      <Link
        to={`/profile/${comment.userId}`}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <Typography variant="caption" color="textSecondary">
          {comment.displayName}- {formattedDate}
        </Typography>
      </Link>
  
            </Box>
        );
        })}

      {/* Add Comment Section */}
      <Paper variant="outlined" sx={{ padding: '10px', mb: 2, border: 'none' }}>
        <Typography variant="h6" sx={{ mb: 1 }}>Add a Comment</Typography>
        <TextField
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          endIcon={<MdSend />}
          onClick={handleAddComment}
          sx={{ mt: 2 }}
        >
          Add Comment
        </Button>
      </Paper>
    </Box>
  );
};

export default ReviewCommentsPage;
