import React from 'react';
import Slider from 'react-slick';
import { Box, Container, IconButton, Typography } from '@mui/material';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { IoIosArrowRoundBack } from 'react-icons/io';

import TestimonialCard from '../cards/testimonialCard';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <IconButton
      className={className}
      onClick={onClick}
      color='primary'
      size='large'
      sx={{
        position: 'absolute',
        right: 0,
        top: '-50%',
        transform: 'translateY(50%)',
        color: '#0055FF !important',
        p: 0 + '!important',
        height: 40,
        width: 40,
        ':before': {
          display: 'none',
        },
      }}>
      <IoIosArrowRoundForward size={40} />
    </IconButton>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <IconButton
      className={className}
      onClick={onClick}
      color='primary'
      size='large'
      sx={{
        position: 'absolute',
        top: '-50%',
        transform: 'translateY(50%)',
        right: 50,
        left: 'unset',
        color: '#0055FF !important',
        p: 0 + '!important',
        height: 40,
        width: 40,
        ':before': {
          display: 'none',
        },
      }}>
      <IoIosArrowRoundBack size={40} />
    </IconButton>
  );
}

export default function Testimonial() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box my={10}>
      <Container>
        <Typography
          variant='h3'
          fontWeight={600}
          fontSize={{ xs: 22, md: 28 }}
          width={{ xs: 200, md: '100%' }}
          noWrap>
          See what our members are saying
        </Typography>
        <Box py={5}>
          <Slider {...settings}>
            {data?.map((item) => (
              <React.Fragment key={Math.random()}>
                <TestimonialCard item={item} />
              </React.Fragment>
            ))}
          </Slider>
        </Box>
      </Container>
    </Box>
  );
}

const data = [
  {
    id: 1,
    detail:
      'Revfer has changed the way I choose businesses. Knowing that the reviews come from my network makes all the difference.',
    img: '/assets/start/amazon',
    userName: 'Jhon Stark',
    userJob: 'Engineering Director',
  },
  {
    id: 2,
    detail:
      'Finally, a platform where I can trust the reviews. Thanks to Revfer, I found the best local cafe recommended by a friend.',
    img: '/assets/start/google',
    userName: 'Cersei Lannister',
    userJob: 'Senior Project Manager',
  },
  {
    id: 3,
    detail:
      'I love how easy it is to connect with reviewers and get detailed feedback. Revfer truly takes the guesswork out of finding quality businesses.',
    img: '/assets/start/amazon',
    userName: 'Jhon Stark',
    userJob: 'Engineering Director',
  },
  {
    id: 4,
    detail:
      'Revfer has become my go-to platform for finding reliable business recommendations. Knowing that the reviews are from people I trust makes all the difference.',
    img: '/assets/start/google',
    userName: 'Cersei Lannister',
    userJob: 'Senior Project Manager',
  },
];
