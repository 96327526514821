
import {unauthApi} from './unauth'


export const getBusinessByIdApi = unauthApi.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessById: builder.query({
      
      query: ({businessId, authType, token, title}) => `/v2/sp/getbusinessbyid/${businessId}/${title}?authType=${authType}&&token=${token}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetBusinessByIdQuery, usePrefetch } = getBusinessByIdApi