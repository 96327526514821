
import {filesApi} from './files'


export const filespresignurl = filesApi.injectEndpoints({
  endpoints: (builder) => ({
    getPreSignedUrl: builder.query({
      query: (skillId) => `s3/user/images?skillId=${skillId}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetPreSignedUrlQuery, usePrefetch } = filespresignurl