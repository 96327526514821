import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import { Chart } from 'react-google-charts';
import StarIcon from '@mui/icons-material/Star';
import RateReviewIcon from '@mui/icons-material/RateReview';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ReplyIcon from '@mui/icons-material/Reply';
import ReviewsIcon from '@mui/icons-material/Reviews';
import PeopleIcon from '@mui/icons-material/People';
import WcIcon from '@mui/icons-material/Wc';

const ReviewAnalysis = () => {
  const reviewAnalysisData = {
    rating: 4.2,
    reviewCount: 450,
    responseTime: '24 hrs',
    responseRate: '80%',
    reviewCountMonthly: [
      { month: 'Jan-2024', reviewCount: 30, avgStarRating: 4.0 },
      { month: 'Feb-2024', reviewCount: 35, avgStarRating: 4.1 },
      { month: 'Mar-2024', reviewCount: 40, avgStarRating: 3.8 },
      { month: 'Apr-2024', reviewCount: 50, avgStarRating: 4.2 },
      { month: 'May-2024', reviewCount: 55, avgStarRating: 4.5 },
      { month: 'Jun-2024', reviewCount: 60, avgStarRating: 4.3 },
      { month: 'Jul-2024', reviewCount: 80, avgStarRating: 4.4 },
    ],
    reviewDistributionByRating: [
      { starRating: 1, reviewCount: 20 },
      { starRating: 2, reviewCount: 30 },
      { starRating: 3, reviewCount: 50 },
      { starRating: 4, reviewCount: 120 },
      { starRating: 5, reviewCount: 230 },
    ],
    reviewCountByHops: [
      { hop: 1, reviewCount: 100 },
      { hop: 2, reviewCount: 80 },
      { hop: 3, reviewCount: 60 },
      { hop: 4, reviewCount: 50 },
      { hop: 5, reviewCount: 40 },
      { hop: 6, reviewCount: 30 },
      { hop: 7, reviewCount: 20 },
    ],
    likesDislikesReplies: {
      likes: 200,
      dislikes: 50,
      replies: 150,
      uniqueReviews: 300,
    },
    genderDistribution: {
      male: 55,
      female: 45,
    },
    ageGroupDistribution: [
      { ageGroup: '18-24', reviewCount: 100 },
      { ageGroup: '25-34', reviewCount: 150 },
      { ageGroup: '35-44', reviewCount: 120 },
      { ageGroup: '45-54', reviewCount: 50 },
      { ageGroup: '55+', reviewCount: 30 },
    ],
  };

  const monthlyReviewData = [
    ['Month', 'Review Count', 'Average Star Rating'],
    ...reviewAnalysisData.reviewCountMonthly.map((data) => [
      data.month,
      data.reviewCount,
      data.avgStarRating,
    ]),
  ];

  const ratingDistributionData = [
    ['Star Rating', 'Review Count'],
    ...reviewAnalysisData.reviewDistributionByRating.map((data) => [
      `${data.starRating} Stars`,
      data.reviewCount,
    ]),
  ];

  const hopsDistributionData = [
    ['Hops', 'Review Count'],
    ...reviewAnalysisData.reviewCountByHops.map((data) => [
      `Hop ${data.hop}`,
      data.reviewCount,
    ]),
  ];

  const genderDistributionData = [
    ['Gender', 'Percentage'],
    ['Male', reviewAnalysisData.genderDistribution.male],
    ['Female', reviewAnalysisData.genderDistribution.female],
  ];

  const ageGroupDistributionData = [
    ['Age Group', 'Review Count'],
    ...reviewAnalysisData.ageGroupDistribution.map((data) => [
      data.ageGroup,
      data.reviewCount,
    ]),
  ];

  return (
    <Box>
      {/* First Row */}
      <Grid container spacing={2} sx={{ mb: 2 }} alignItems="stretch">
        <Grid item xs={12} sm={3}>
          <Card variant="outlined" sx={{ height: '100%', padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
              <StarIcon sx={{ fontSize: 40, marginRight: 2 }} />
              <Box>
                <Typography variant="caption">Rating</Typography>
                <Typography variant="h6">{reviewAnalysisData.rating}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card variant="outlined" sx={{ height: '100%', padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
              <RateReviewIcon sx={{ fontSize: 40, marginRight: 2 }} />
              <Box>
                <Typography variant="caption">Review Count</Typography>
                <Typography variant="h6">{reviewAnalysisData.reviewCount}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card variant="outlined" sx={{ height: '100%', padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
              <AccessTimeIcon sx={{ fontSize: 40, marginRight: 2 }} />
              <Box>
                <Typography variant="caption">Response Time</Typography>
                <Typography variant="h6">{reviewAnalysisData.responseTime}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card variant="outlined" sx={{ height: '100%', padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
              <PeopleIcon sx={{ fontSize: 40, marginRight: 2 }} />
              <Box>
                <Typography variant="caption">Response Rate</Typography>
                <Typography variant="h6">{reviewAnalysisData.responseRate}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Second Row */}
      <Grid container spacing={2} sx={{ mb: 2 }} alignItems="stretch">
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%', padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
          <CardContent>
              <Typography variant="caption" sx={{ textAlign: 'center', display: 'block', my: 1 }}>Monthly Review Count and Star Rating</Typography>
              <Chart
                chartType="ComboChart"
                data={monthlyReviewData}
                options={{
                  hAxis: { title: 'Month' },
                  vAxes: {
                    0: { title: 'Review Count' },
                    1: { title: 'Average Star Rating', minValue: 0, maxValue: 5 },
                  },
                  seriesType: 'line',
                  series: {
                    1: {
                      type: 'bars',
                      targetAxisIndex: 1,
                    },
                  },
                  colors: ['#FF8042', '#00C49F'],
                }}
                width="100%"
                height="250px"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%', padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="caption" sx={{ textAlign: 'center', display: 'block', my: 1 }}>Review Distribution by Star Rating</Typography>
              <Chart
                chartType="ColumnChart"
                data={ratingDistributionData}
                options={{
                  hAxis: { title: 'Star Rating' },
                  vAxis: { title: 'Review Count' },
                  colors: ['#00C49F'],
                  legend: 'none',
                }}
                width="100%"
                height="250px"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%', padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="caption" sx={{ textAlign: 'center', display: 'block', my: 1 }}>Review Count by Number of Hops</Typography>
              <Chart
                chartType="ColumnChart"
                data={hopsDistributionData}
                options={{
                  hAxis: { title: 'Hops' },
                  vAxis: { title: 'Review Count' },
                  colors: ['#FF8042'],
                  legend: 'none',
                }}
                width="100%"
                height="250px"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Third Row */}
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ padding: '0px', border: 'none', mb:1, borderRadius: 2, bgcolor: '#F8F9F9'  }}>
            <CardContent>
              
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Card variant="outlined" sx={{ padding: '0px', border: 'none', borderRadius: 2, mt: -2, ml: -2  }}>
                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                      <ThumbUpIcon sx={{ fontSize: 30, marginRight: 2 }} />
                      <Box>
                        <Typography variant="caption">Likes</Typography>
                        <Typography variant="h6">{reviewAnalysisData.likesDislikesReplies.likes}</Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card variant="outlined" sx={{ padding: '0px', border: 'none', borderRadius: 2, mt: -2, mr: -2 , ml: -1 }}>
                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                      <ThumbDownIcon sx={{ fontSize: 30, marginRight: 2 }} />
                      <Box>
                        <Typography variant="caption">Dislikes</Typography>
                        <Typography variant="h6">{reviewAnalysisData.likesDislikesReplies.dislikes}</Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card variant="outlined" sx={{ padding: '0px', border: 'none', borderRadius: 2, ml: -2, mt: -1 }}>
                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                      <ReplyIcon sx={{ fontSize: 30, marginRight: 2 }} />
                      <Box>
                        <Typography variant="caption">Replies</Typography>
                        <Typography variant="h6">{reviewAnalysisData.likesDislikesReplies.replies}</Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card variant="outlined" sx={{ padding: '0px', border: 'none', borderRadius: 2, mr: -2, ml: -1, mt: -1 }}>
                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                      <ReviewsIcon sx={{ fontSize: 30, marginRight: 2 }} />
                      <Box>
                        <Typography variant="caption">Reviews</Typography>
                        <Typography variant="h6">{reviewAnalysisData.likesDislikesReplies.uniqueReviews}</Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%', padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="caption" sx={{ textAlign: 'center', display: 'block', my: 1 }}>Gender Distribution</Typography>
              <Chart
                chartType="PieChart"
                data={genderDistributionData}
                options={{
                  pieHole: 0.4,
                  slices: [
                    { color: '#00C49F' },
                    { color: '#FFBB28' },
                  ],
                  legend: 'none',
                }}
                width="100%"
                height="250px"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%', padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="caption" sx={{ textAlign: 'center', display: 'block', my: 1 }}>Age Group Distribution</Typography>
              <Chart
                chartType="ColumnChart"
                data={ageGroupDistributionData}
                options={{
                  hAxis: { title: 'Age Group' },
                  vAxis: { title: 'Review Count' },
                  colors: ['#FFBB28'],
                  legend: 'none',
                }}
                width="100%"
                height="250px"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewAnalysis;
