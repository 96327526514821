
import {userApi} from './user'


export const getUserByEmailApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserByEmail: builder.query({
      query: ( email) => `/email?email=${email}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetUserByEmailQuery, usePrefetch } = getUserByEmailApi