import React, { useEffect, useState } from 'react';
import { Grid, Avatar, Typography, Box, CircularProgress, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { useGetUserChatListQuery } from '../../redux/thunks/users/userchatlist';
import * as Utils from '../../Functions/Utilities';
import UserPhoto from '../../Components/UserPhoto/UserPhoto';
import { usePrefetch as useGetUserChatHistoryPrefetch } from '../../redux/thunks/users/userchathistory';

const ChatUserList = ({onUserClick, otherUser}) => {
  const userId = useSelector((state) => state.auth.userId);
  const prefetchGetUserChatHistory = useGetUserChatHistoryPrefetch('getUserChatHistory')

  const { data: userChatList, isLoading: chatListLoading } = useGetUserChatListQuery(userId, {
    pollingInterval: 200000,
  });

  // Initialize chatListWithInitials with an empty array
  const [chatListWithInitials, setChatListWithInitials] = useState([]);
  const [selectedUser, setSelectedUser] = useState(otherUser?.otherUserId || '');

  console.log('in chat list chatListWithInitials', chatListWithInitials )


  useEffect(() => {


    
    // Calculate initials for each user when userChatList changes
    if (userChatList) {
      const updatedList = userChatList.map((user) => ({
        ...user,        
          formattedMsgTime: moment(user.msgTime).format('MMM-YYYY'),         
          
      }));
      if (otherUser) {
        const otherUserExists = updatedList.some((user) => user.otherUserId === otherUser?.otherUserId);
        if(!otherUserExists) {
          updatedList.unshift({
            ...otherUser,         
            formattedMsgTime: moment().format('MMM-YYYY'),
          });

        }

        
      }
      setChatListWithInitials(updatedList);
      // Prefetch chat history for users with the last message within the last 3 months
      updatedList.forEach((user) => {
        const lastMessageDate = moment(user.msgTime);
        const threeMonthsAgo = moment().subtract(3, 'months');
        if (lastMessageDate.isAfter(threeMonthsAgo)) {
          prefetchGetUserChatHistory(user.otherUserId);
        }
      });

      
      

     
    }
  }, [userChatList, otherUser]);

  const handleUserClick = (user) => {     

    const selectedUserId = user.otherUserId;
    setSelectedUser(selectedUserId);   
    
    
    // Notify the parent component (Chat) about the user click
    onUserClick(user);
  };

  if (chatListLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Box sx={{ maxHeight: 'calc(100vh - 120px)',
       overflowY: 'auto',
       
       msOverflowStyle: 'none', 
       scrollbarWidth: 'thin', // For Firefox
        scrollbarColor: 'transparent transparent', // For Firefox
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'transparent',
        },
    
    }}
    >
        <Grid container spacing={2}>
      {chatListWithInitials.map((user, index) => (
        <React.Fragment key={user.otherUserId}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', 
              alignItems: 'center',
              
              pl:1,
              borderLeft: selectedUser === user.otherUserId 
                  ? '5px solid blue' 
                  : 'none',
            }}
            onClick={() => handleUserClick(user)}
            >
            <UserPhoto photoUrl={user.photoUrl}  name={user.displayName} size={55} />
              <Box sx={{ ml: 1, flexGrow: 1 }}>
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6">{user.displayName} </Typography>
                    <Typography variant="caption">{user.formattedMsgTime}</Typography>
                </Box>
                
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    variant="body2"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '100%',
                      marginBottom: '4px',
                    }}
                  >
                    {user.lastMessage
                      .split(' ')
                      .slice(0, 5)
                      .join(' ')}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider sx={{ mt: 2, mb: 2, bgcolor: 'divider' }} />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>


    </Box>
    
  );
};

export default ChatUserList;
