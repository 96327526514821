
import {userApi} from './user'


export const userOutgoingReqApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    
    
    getUserOutgoingReq: builder.query({
      providesTags:['OutgoingReq'],
      query: (userId) => `/pendingout/${userId}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetUserOutgoingReqQuery, usePrefetch } = userOutgoingReqApi

