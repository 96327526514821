
import {userApi} from './user'

export const userFindApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    
    findUser: builder.mutation({      
      query: ({ payload}) => ({
        url: `/find`,
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useFindUserMutation } = userFindApi