import React, { useState } from "react";
import { Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { GoogleMap, Marker, InfoWindow, LoadScript } from '@react-google-maps/api';

const mapContainerStyle = {
  width: '500px',
  height: '500px',
};

const GoogleMapComponent = ({ businesses, onClose }) => {
  console.log('in GoogleMapComponent, businesses', businesses)
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };



  const defaultCenter = {
    lat: 42.262112,
    lng: -71.44335,
  };



  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Restaurant Locations</DialogTitle>
      <DialogContent>
        <Box style={{ width: '500px', height: '500px' }}>
          
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={12}
              center={defaultCenter}
            >
              {businesses.map((business) => (
                <Marker
                  key={business._id}
                  position={{
                    lat: business.geocodeResults.location.coordinates[1], // Update with the latitude from your restaurant data
                    lng: business.geocodeResults.location.coordinates[0], // Update with the longitude from your restaurant data
                  }}
                  onClick={() => handleActiveMarker(business._id)}
                >
                  {activeMarker === business._id ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                     <div>{business.name}</div>
                   </InfoWindow>
                   ) : null}
                  
                
                  
                </Marker>
              ))}
            </GoogleMap>
         
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GoogleMapComponent;
