import React from 'react';
import ClockLoader from 'react-spinners/ClockLoader';
import { Typography } from '@mui/material';

const FullscreenLoader = ({ message }) => {
  const loaderStyles = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
   
  };

  return (
    <div style={loaderStyles}>
      <ClockLoader size={160} color={'#1a76e8'} loading={true} />
      <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', marginLeft: '-50px', mt: 3 }}>
        {message}
      </Typography>
    </div>
  );
};

export default FullscreenLoader;
